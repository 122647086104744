import { Injectable, NgZone } from '@angular/core';
import {  MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    private messageService: MessageService)
   {}

  default(message: string, life= 2000) {
    this.messageService.add({ severity: 'info', summary: message, life });
  }

  info(message: string, life= 2000) {
    this.messageService.add({ severity: 'info', summary: message, life });
    // this.show(message, {
    //   duration: 2000,
    //   panelClass: 'info-notification-overlay'
    // });
  }

    success(message: string, life= 2000) {
    this.messageService.add({ severity: 'success', summary: message, life });
  }

  warn(message: string, life= 3000) {
    this.messageService.add({ severity: 'warning', summary: message, life });
  }

  error(message: string, life= 5000) {
    this.messageService.add({ severity: 'error', summary: message, life });
  }

  // private show(message: string, configuration: MatSnackBarConfig) {
  //   // Need to open snackBar from Angular zone to prevent issues with its position per
  //   // https://stackoverflow.com/questions/50101912/snackbar-position-wrong-when-use-errorhandler-in-angular-5-and-material
  //   this.zone.run(() => this.snackBar.open(message, null, configuration));
  // }
}

