import {
  SPORT_BET_LOAD_BETS, SPORT_BET_LOAD_BETS_FAIL, SPORT_BET_LOAD_BETS_SUCCESS,
  SPORT_BET_SET_SELECTED_BET, SPORT_BET_SET_SELECTED_BET_EDIT
} from '../action-types';
import { SportBetActions } from '../actions';
import { INITIAL_SPORT_BET_STATE, SportBetState } from '../state/sport-bets.state.interface';

export function sportBetReducer(
  state = INITIAL_SPORT_BET_STATE,
  action: SportBetActions
): SportBetState {
  switch (action.type) {
    case SPORT_BET_LOAD_BETS:
    case SPORT_BET_LOAD_BETS_FAIL:
      return {
        ...state,
        sportBetListItems: [],
        selectedSportBetListItem: undefined,
        isLoaded: false
      };
    case SPORT_BET_LOAD_BETS_SUCCESS: {
      const newBets = [...action.payload];
      return {
        ...state,
        isLoaded: true,
        sportBetListItems: newBets.sort((a, b) => b.gameDatePayload.sortDateStr.localeCompare(a.gameDatePayload.sortDateStr))
      };
    }
    case SPORT_BET_SET_SELECTED_BET_EDIT:
      return {
        ...state,
        editingStatus: action.payload,
      };
    case SPORT_BET_SET_SELECTED_BET:
      return {
        ...state,
        selectedSportBetListItem: action.payload,
      };
    default: {
      return state;
    }
  }
}
