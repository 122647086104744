
export const WAGER_SOURCES_LOAD = '[Wager Sources] Load Wager Sources';
export const WAGER_SOURCES_LOAD_FAIL = '[Wager Sources] Load Wager Sources Fail';
export const WAGER_SOURCES_LOAD_SUCCESS = '[Wager Sources] Load Wager Sources Success';

export const WAGER_SOURCE_LIST_ADD_WAGER_SOURCE = '[Wager Sources] Add New Wager Source';
export const WAGER_SOURCE_LIST_ADD_WAGER_SOURCE_FAIL = '[Wager Sources] Add New Wager Source Fail';
export const WAGER_SOURCE_LIST_ADD_WAGER_SOURCE_SUCCESS = '[Wager Sources] Add New Wager Source Success';

export const WAGER_SOURCE_LIST_UPDATE_WAGER_SOURCE = '[Wager Sources] Update Wager Source Document';
export const WAGER_SOURCE_LIST_UPDATE_WAGER_SOURCE_FAIL = '[Wager Sources] Update Wager Source Document Fail';
export const WAGER_SOURCE_LIST_UPDATE_WAGER_SOURCE_SUCCESS = '[Wager Sources] Update Wager Source Document Success';

export const WAGER_SOURCE_LIST_DELETE_WAGER_SOURCE = '[Wager Sources] Delete Wager Source Document';
export const WAGER_SOURCE_LIST_DELETE_WAGER_SOURCE_FAIL = '[Wager Sources] Delete Wager Source Document Fail';
export const WAGER_SOURCE_LIST_DELETE_WAGER_SOURCE_SUCCESS = '[Wager Sources] Delete Wager Source Document Success';

export const WAGER_SOURCE_LIST_SET_SELECTED_WAGER_SOURCE = '[Wager Sources] Set Selected Wager Source';
export const SET_WAGER_SOURCE_EDITING_STATUS = '[Wager Sources] Set Selected Wager Source Editing Status';
