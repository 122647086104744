
export interface UiState {
  isAdminActive: boolean;
  isEditingEntity: boolean;
  isLoading: boolean;
  isMobileDevice: boolean;
  selectedAdminTabIndex: number;
}

export const INITIAL_UI_STATE: UiState = {
  isAdminActive: false,
  isEditingEntity: false,
  isLoading: false,
  isMobileDevice: false,
  selectedAdminTabIndex: 0
};
