import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/core/core.state';
import { SetIsAdminActive, SetIsEditingEntity, SetIsMobileDevice, SetSelectedAdminTabIndex, StartLoading, StopLoading } from '../actions';
import { isAdminActiveSelector, isEditingEntitySelector, isLoadingSelector, isMobileDeviceSelector, selectedAdminTabIndexSelector } from '../selectors/ui.selectors';


@Injectable()
export class UiStateService {
  isLoading$ = this.store.select(isLoadingSelector);
  isMobileDevice$ = this.store.select(isMobileDeviceSelector);
  isAdminActive$ = this.store.select(isAdminActiveSelector);
  isEditingEntity$ = this.store.select(isEditingEntitySelector);
  selectedAdminTabIndex$ = this.store.select(selectedAdminTabIndexSelector);

  constructor(
    private store: Store<AppState>,
  ) {}

  startLoading() {
    this.store.dispatch(new StartLoading());
  }
  stopLoading() {
    this.store.dispatch(new StopLoading());
  }
  setIsMobileDevice(isMobileDevice: boolean) {
    this.store.dispatch(new SetIsMobileDevice(isMobileDevice));
  }
  setIsAdminActive(isActive: boolean) {
    this.store.dispatch(new SetIsAdminActive(isActive));
  }
  setIsEditingEntity(isActive: boolean) {
    this.store.dispatch(new SetIsEditingEntity(isActive));
  }
  setSelectedAdminTabIndex(index: number) {
    this.store.dispatch(new SetSelectedAdminTabIndex(index));
  }

}
