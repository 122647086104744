import { Action } from '@ngrx/store';
import { Client } from 'app/core/models/Client';
import { SgEditingStatus, Guid } from 'app/core/models/common-types';
import * as fromActionTypes from '../action-types';

 // CLIENTS

export class SetClientEditingStatus implements Action {
  readonly type = fromActionTypes.SET_CLIENT_EDITING_STATUS;
  constructor(public payload: SgEditingStatus) {}
}
export class ClientListSetSelectedClient implements Action {
  readonly type = fromActionTypes.CLIENT_LIST_SET_SELECTED_CLIENT;
  constructor(public payload: Client) {}
}

export class LoadClientForUser implements Action {
  readonly type = fromActionTypes.LOAD_CLIENT_FOR_USER;
  constructor(public payload: Guid) {}
}
export class LoadClientForUserFail implements Action {
  readonly type = fromActionTypes.LOAD_CLIENT_FOR_USER_FAIL;
  constructor(public payload: any) {}
}
export class LoadClientForUserSuccess implements Action {
  readonly type = fromActionTypes.LOAD_CLIENT_FOR_USER_SUCCESS;
  constructor(public payload: Client) {}
}

 export class ClientListLoadClients implements Action {
  readonly type = fromActionTypes.CLIENT_LIST_LOAD_CLIENTS;
  constructor() {}
}
export class ClientListLoadClientsFail implements Action {
  readonly type = fromActionTypes.CLIENT_LIST_LOAD_CLIENTS_FAIL;
  constructor(public payload: any) {}
}
export class ClientListLoadClientsSuccess implements Action {
  readonly type = fromActionTypes.CLIENT_LIST_LOAD_CLIENTS_SUCCESS;
  constructor(public payload: Client[]) {}
}
export class ClientListAddClient implements Action {
  readonly type = fromActionTypes.CLIENT_LIST_ADD_CLIENT;
  constructor(public payload: Client) {}
}
export class ClientListAddClientSuccess implements Action {
  readonly type = fromActionTypes.CLIENT_LIST_ADD_CLIENT_SUCCESS;
  constructor(public payload: Client) {}
}
export class ClientListAddClientFail implements Action {
  readonly type = fromActionTypes.CLIENT_LIST_ADD_CLIENT_FAIL;
  constructor(public payload: any) { }
}

export class ClientListAddMultipleClients implements Action {
  readonly type = fromActionTypes.CLIENT_LIST_ADD_MULTIPLE_CLIENTS;
  constructor(public payload: Client[]) {}
}
export class ClientListAddMultipleClientsSuccess implements Action {
  readonly type = fromActionTypes.CLIENT_LIST_ADD_MULTIPLE_CLIENTS_SUCCESS;
  constructor(public payload: Client[]) {}
}
export class ClientListAddMultipleClientsFail implements Action {
  readonly type = fromActionTypes.CLIENT_LIST_ADD_MULTIPLE_CLIENTS_FAIL;
  constructor(public payload: any) { }
}

export class ClientListDeleteClient implements Action {
  readonly type = fromActionTypes.CLIENT_LIST_DELETE_CLIENT;
  constructor(public payload: Guid) {}
}
export class ClientListDeleteClientSuccess implements Action {
  readonly type = fromActionTypes.CLIENT_LIST_DELETE_CLIENT_SUCCESS;
  constructor() {}
}
export class ClientListDeleteClientFail implements Action {
  readonly type = fromActionTypes.CLIENT_LIST_DELETE_CLIENT_FAIL;
  constructor(public payload: any) { }
}
export class ClientListUpdateClient implements Action {
  readonly type = fromActionTypes.CLIENT_LIST_UPDATE_CLIENT;
  constructor(public payload: Client) {}
}
export class ClientListUpdateClientSuccess implements Action {
  readonly type = fromActionTypes.CLIENT_LIST_UPDATE_CLIENT_SUCCESS;
  constructor(public payload: Client) {}
}
export class ClientListUpdateClientFail implements Action {
  readonly type = fromActionTypes.CLIENT_LIST_UPDATE_CLIENT_FAIL;
  constructor(public payload: any) { }
}
// action types
export type ClientListActions =
  | ClientListSetSelectedClient
  | SetClientEditingStatus
  | LoadClientForUser
  | LoadClientForUserFail
  | LoadClientForUserSuccess
  | ClientListAddClient
  | ClientListAddClientFail
  | ClientListAddClientSuccess
  | ClientListAddMultipleClients
  | ClientListAddMultipleClientsSuccess
  | ClientListAddMultipleClientsFail
  | ClientListDeleteClient
  | ClientListDeleteClientFail
  | ClientListDeleteClientSuccess
  | ClientListLoadClients
  | ClientListLoadClientsFail
  | ClientListLoadClientsSuccess
  | ClientListUpdateClient
  | ClientListUpdateClientFail
  | ClientListUpdateClientSuccess

  ;
