/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */

import { PDropdownItem } from './common-ux-types';

export type JSONString = string;
export type CSVString = string;
export type Guid = string;

export const DISPLAY_DATE_FORMAT = 'MM/DD/YYYY';
export const LIST_DATE_FORMAT = 'MMM DD, YYYY';
export const REPORT_DATE_FORMAT = 'YYYYMMDD';
export const LIST_DATE_FORMAT_NO = 'MMM DD YYYY';
export const LIST_DATE_FORMAT_NO2 = 'YYYY MM DD HH:mm';
export const LIST_DATE_TIME_FORMAT = 'MMM DD YYYY hh:mm a';

export enum AmPmType {
  AM = 'AM',
  PM = 'PM',
}
export enum FirestoreCollectionType {
  SG_CLIENTS = 'sbClients',
  // SG_CUSTOMERS = 'sbCustomers',
  // SG_CONTRACTORS = 'sbContractors',
  // SG_TENANTS = 'sbTenants',
  SG_PROJECTS = 'sbProjects',
  SB_SPORT_BETS = 'sbSportBets',
  SG_SERVICES = 'sbServices',
  SG_SETTINGS = 'sbSettings',
  SG_GAME_PLANS = 'sbGamePlans',
  SG_USERS = 'sbUsers',
  SG_REPORT_OPTIONS = 'sbReportOptions',
  SG_REPORT_DOCUMENTS = 'sbReportDocuments',
  SB_WAGER_SOURCES = 'sbWagerSources',
}

export enum SgEditingStatus {
  NOT_EDITING = 'NOT_EDITING',
  ADDING = 'ADDING',
  EDITING = 'EDITING',
}

export enum ClientType {
  SPORTS_BET_CLIENT = 'SportsBetClient',
  // SERVICE_GAMBIT_CLIENT_CUSTOMER = 'SG Client Customer',
}

export enum SgUserType {
  SG_SUPERUSER = 'SGSuperUser',
  SG_ADMIN = 'SGAdmin',
  SG_CLIENT = 'SGClient'
}
export const SgUserTypeMSItems: PDropdownItem[] = [
  { value: SgUserType.SG_SUPERUSER },
  { value: SgUserType.SG_ADMIN},
  { value: SgUserType.SG_CLIENT }
];

export enum SportLeagueType {
  NBA = 'NBA',
  NFL = 'NFL',
  NCAAF = 'NCAAF',
  NCAAB = 'NCAAB',
  NHL = 'NHL',
  MLB = 'MLB',
  NASCAR = 'NASCAR',
  SOCCER = 'SOCCER',
  GOLF = 'GOLF',
  WNBA = 'WNBA',
  MMA = 'MMA',
  OTHER = 'OTHER',
}
export const SportLeagueTypeMSItems: PDropdownItem[] = [
  { value: SportLeagueType.NBA },
  { value: SportLeagueType.NFL },
  { value: SportLeagueType.NHL },
  { value: SportLeagueType.MLB },
  { value: SportLeagueType.NCAAF },
  { value: SportLeagueType.NCAAB },
  { value: SportLeagueType.NASCAR },
  { value: SportLeagueType.GOLF },
  { value: SportLeagueType.WNBA },
  { value: SportLeagueType.SOCCER },
  { value: SportLeagueType.MMA, },
  { value: SportLeagueType.OTHER }
];

export function getSportBetLeague(str: string): SportLeagueType {
  switch (str.toUpperCase()) {
    case SportLeagueType.NBA:
      return SportLeagueType.NBA;
    case SportLeagueType.NFL:
      return SportLeagueType.NFL;
    case SportLeagueType.NHL:
      return SportLeagueType.NHL;
    case SportLeagueType.MLB:
      return SportLeagueType.MLB;
    case SportLeagueType.NCAAF:
      return SportLeagueType.NCAAF;
    case SportLeagueType.NCAAB:
      return SportLeagueType.NCAAB;
    case SportLeagueType.SOCCER:
      return SportLeagueType.SOCCER;
    case SportLeagueType.NASCAR:
      return SportLeagueType.NASCAR;
    case SportLeagueType.GOLF:
      return SportLeagueType.GOLF;
    case SportLeagueType.WNBA:
      return SportLeagueType.WNBA;
    case SportLeagueType.MMA:
      return SportLeagueType.MMA;

  }

  return SportLeagueType.OTHER;
}
// export enum SportBetRating {
//   ONE_STAR = '*',
//   TWO_STAR = '**',
//   THREE_STAR = '***'
// }
// export const SportBetRatingItem: PDropdownItem[] = [
//   { value: SportBetRating.ONE_STAR, viewValue: SportBetRating.ONE_STAR },
// ];

export enum SportBetType {
  MONEYLINE = 'MONEYLINE',
  SPREAD = 'SPREAD',
  OVER_UNDER = 'O/U',
  PROP = 'PROP',
  OTHER = 'OTHER',
}

export const SportBetTypeMSItems: PDropdownItem[] = [
  { value: SportBetType.MONEYLINE },
  { value: SportBetType.SPREAD },
  { value: SportBetType.OVER_UNDER },
  { value: SportBetType.PROP },
  { value: SportBetType.OTHER},
];

export function getSportBetType(str: string): SportBetType {
  const lower = str.toLowerCase();
  const moneyArray = ['m', 'mo', 'money', 'moneyline'];
  const spreadArray = ['s', 'spread'];
  const ouArray = ['ou', 'o/u', 'over/under'];
  const propArray = ['p', 'prop'];
  if (moneyArray.includes(lower)) {
    return SportBetType.MONEYLINE;
  } else if (spreadArray.includes(lower)) {
    return SportBetType.SPREAD;
  } else if (ouArray.includes(lower)) {
    return SportBetType.OVER_UNDER;
  } else if (propArray.includes(lower)) {
    return SportBetType.PROP;
  }
  return SportBetType.OTHER;
}

export enum SportBetStatusType {
  WINNER = 'Winner',
  LOSER = 'Loser',
  PUSH = 'Push',
  CANCELLED = 'Canceled',
  PENDING = 'Pending',
}
export const SportBetResultTypeMSItems: PDropdownItem[] = [
  { value: SportBetStatusType.WINNER},
  { value: SportBetStatusType.LOSER },
  { value: SportBetStatusType.PUSH},
  { value: SportBetStatusType.CANCELLED },
  { value: SportBetStatusType.PENDING }
];

export function getSportBetStatusType(str: string): SportBetStatusType {
  if (str) {
    const lower = str.toLowerCase();
    const winnerArray = ['w', 'win', 'winner'];
    const loserArray = ['l', 'lose', 'loser'];
    const pushArray = ['p', 'push', 'pu'];
    const cancelledArray = ['c', 'cancel', 'canceled', 'cancelled'];
    if (winnerArray.includes(lower)) {
      return SportBetStatusType.WINNER;
    } else if (loserArray.includes(lower)) {
      return SportBetStatusType.LOSER;
    } else if (pushArray.includes(lower)) {
      return SportBetStatusType.PUSH;
    } else if (cancelledArray.includes(lower)) {
      return SportBetStatusType.CANCELLED;
    }
  }
  return SportBetStatusType.PENDING;
}

export enum GamePlanStatusType {
  PENDING = 'Pending',
  COMPLETED = 'Completed',
  CANCELLED = 'Canceled',
}
export const GamePlanStatusTypeMSItems: PDropdownItem[] = [
  { value: GamePlanStatusType.PENDING },
  { value: GamePlanStatusType.COMPLETED },
  { value: GamePlanStatusType.CANCELLED }
];

export enum SubscriptionType {
  BASIC = 'Basic',
  PREMIUM = 'Premium',
}

export const SubscriptionTypeMSItems: PDropdownItem[] = [
  { value: SubscriptionType.BASIC },
  { value: SubscriptionType.PREMIUM}
];
export enum SubscriptionLengthType {
  MONTHLY = 'Monthly',
  YEARLY = 'Yearly'
}
export const SubscriptionLengthTypeMSItems: PDropdownItem[] = [
  { value: SubscriptionLengthType.MONTHLY },
  { value: SubscriptionLengthType.YEARLY },
];


export const UsStates: string[] = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];
