import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/core/core.state';
import { Client } from 'app/core/models/Client';
import { SgEditingStatus, Guid } from 'app/core/models/common-types';
import { ClientListAddClient, ClientListDeleteClient, ClientListLoadClients, ClientListSetSelectedClient, SetClientEditingStatus, ClientListUpdateClient, LoadClientForUser, ClientListAddMultipleClients } from '../actions/clients.action';
import { clientEditingStatusSelector, clientsSelector, isClientSelectedSelector, selectedClientSelector } from '../selectors/clients.selectors';

@Injectable()
export class ClientStateService {
  clients$ = this.store.select(clientsSelector);
  selectedClient$ = this.store.select(selectedClientSelector);
  isClientSelected$ = this.store.select(isClientSelectedSelector);
  // loadedSgUserClient$ = this.store.select(loadedSgUserClientSelector);
  clientEditingStatus$ = this.store.select(clientEditingStatusSelector);

  constructor(
    private store: Store<AppState>
  ) { }

  loadClientForUser(clientId: Guid) {
    this.store.dispatch(new LoadClientForUser(clientId));
  }
  // loadClientForUserSuccess(client: Client) {
  //   this.store.dispatch(new LoadClientForUserSuccess(client));
  // }
  loadClients() {
    this.store.dispatch(new ClientListLoadClients());
  }
  // loadClientsSuccess(clients: Client[]) {
  //   this.store.dispatch(new ClientListLoadClientsSuccess(clients));
  // }
  setSelectedClient(client: Client) {
    this.store.dispatch(new ClientListSetSelectedClient(client));
  }
  setClientEditingStatus(editingStatus: SgEditingStatus) {
    this.store.dispatch(new SetClientEditingStatus(editingStatus));
  }
  addClient(client: Client) {
    this.store.dispatch(new ClientListAddClient(client));
  }
  addMultipleClients(clients: Client[]) {
    this.store.dispatch(new ClientListAddMultipleClients(clients));
  }

  deleteClient(clientId: Guid) {
    this.store.dispatch(new ClientListDeleteClient(clientId));
  }
  updateClient(client: Client) {
    this.store.dispatch(new ClientListUpdateClient(client));
  }
}
