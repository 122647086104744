import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { switchMap, catchError, map, tap } from 'rxjs/operators';
import { WAGER_SOURCE_LIST_ADD_WAGER_SOURCE_SUCCESS, WAGER_SOURCE_LIST_DELETE_WAGER_SOURCE_SUCCESS, WAGER_SOURCE_LIST_UPDATE_WAGER_SOURCE_SUCCESS, WAGER_SOURCE_LIST_ADD_WAGER_SOURCE, WAGER_SOURCES_LOAD, WAGER_SOURCE_LIST_UPDATE_WAGER_SOURCE, WAGER_SOURCE_LIST_DELETE_WAGER_SOURCE, SET_WAGER_SOURCE_EDITING_STATUS } from '../action-types';
import { WagerSourcesDeleteWagerSourceSuccess, WagerSourcesAddWagerSource, WagerSourcesAddWagerSourceFail, WagerSourcesAddWagerSourceSuccess, WagerSourcesLoadWagerSources, WagerSourcesUpdateWagerSource, WagerSourcesUpdateWagerSourceFail, WagerSourcesUpdateWagerSourceSuccess, WagerSourcesDeleteWagerSource, WagerSourcesDeleteWagerSourceFail, WagerSourcesLoadWagerSourcesSuccess, WagerSourcesLoadWagerSourcesFail, SetWagerSourceEditingStatus } from '../actions';
import { Router } from '@angular/router';
import { WagerSource } from 'app/core/models/WagerSource';
import { UiStateService } from '../../ui/state-service';
import { UiService } from 'app/core/services/ui/ui.service';
import { SgEditingStatus } from 'app/core/models/common-types';
import { WagerSourceStateService } from '../state-service';
import { WagerSourceService } from 'app/core/services/wager-source/wager-source.service';

@Injectable()
export class WagerSourceEffects {


  wagerSourceLoadWagerSources$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<WagerSourcesLoadWagerSources>(WAGER_SOURCES_LOAD),
    switchMap(() => this.wagerSourceService
      .loadWagerSources('displayName', 'asc')
      .pipe(
        map((docArray) => {
          const wagerSource: WagerSource[] = docArray.map(a => {
            const data = a.payload.doc.data() as WagerSource;
            const id = a.payload.doc['id'];
            return {
              ...data,
              id,
              //  reportHeaderColor: data.reportHeaderColor ?? DEFAULT_REPORT_HEADER_COLOR
            };
          });
          this.uiStateService.stopLoading();
          return new WagerSourcesLoadWagerSourcesSuccess(wagerSource)
        }
        ),
        catchError((error) => {
          this.uiStateService.stopLoading();
          if (error.code !== 'permission-denied') {
            this.uiService.showSnackbar(
              'Fetching WagerSources failed',
              null,
              3000
            );
          }
          return of(new WagerSourcesLoadWagerSourcesFail(error))
        })
      ))
  ));

  wagerSourceAddWagerSource$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<WagerSourcesAddWagerSource>(
      WAGER_SOURCE_LIST_ADD_WAGER_SOURCE
    ),
    switchMap((action) => this.wagerSourceService
      .addWagerSource(action.payload)
      .pipe(
        map(() =>
          new WagerSourcesAddWagerSourceSuccess(action.payload)
        ),
        catchError((error) =>
          of(new WagerSourcesAddWagerSourceFail(error))
        )
      ))
  ));

  wagerSourceUpdateWagerSource$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<WagerSourcesUpdateWagerSource>(WAGER_SOURCE_LIST_UPDATE_WAGER_SOURCE),
    switchMap((action) => this.wagerSourceService
      .updateWagerSource(action.payload)
      .pipe(
        map(() => new WagerSourcesUpdateWagerSourceSuccess(action.payload)),
        catchError((error) => of(new WagerSourcesUpdateWagerSourceFail(error)))
      ))
  ));

  wagerSourceDeleteWagerSource$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<WagerSourcesDeleteWagerSource>(WAGER_SOURCE_LIST_DELETE_WAGER_SOURCE),
    switchMap((action) => this.wagerSourceService
      .deleteWagerSource(action.payload)
      .pipe(
        map(() => new WagerSourcesDeleteWagerSourceSuccess()),
        catchError((error) => of(new WagerSourcesDeleteWagerSourceFail(error)))
      ))
  ));

  deleteWagerSourceSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<WagerSourcesDeleteWagerSourceSuccess>(
      WAGER_SOURCE_LIST_DELETE_WAGER_SOURCE_SUCCESS,
      WAGER_SOURCE_LIST_UPDATE_WAGER_SOURCE_SUCCESS,
      WAGER_SOURCE_LIST_ADD_WAGER_SOURCE_SUCCESS),
    map(() => {
      this.wagerSourceStateService.loadWagerSources();
    })
    ),{ dispatch: false });

  navigateOnFinish$ = createEffect(() => this.actions$.pipe(
    ofType(WAGER_SOURCE_LIST_ADD_WAGER_SOURCE_SUCCESS,
      WAGER_SOURCE_LIST_UPDATE_WAGER_SOURCE_SUCCESS),
    map(() => {
      // console.log('MS navigateOnFinish SG_ADMIN_ADD_CUSTOMER_SUCCESS!');
      // this.serviceEventStateService.loadServicePointPackages();
    this.router.navigate(['/admin/wager-source-list']);
    })
    ),{ dispatch: false });

  setWagerSourceEditingStatus$ = createEffect(() => this.actions$.pipe(
    ofType<SetWagerSourceEditingStatus>(
      SET_WAGER_SOURCE_EDITING_STATUS),
    tap((action) => {
      console.log('MS setWorkOrderEditingStatus action.payload= ', action.payload);
      this.uiStateService.setIsEditingEntity(action.payload !== SgEditingStatus.NOT_EDITING);
    })
    ),{ dispatch: false });

  constructor(
    private actions$: Actions,
    private router: Router,
    private uiService: UiService,
    private wagerSourceStateService: WagerSourceStateService,
    private wagerSourceService: WagerSourceService,
    private uiStateService: UiStateService) { }

}
