/* eslint-disable @typescript-eslint/naming-convention */
/** *********************************************************************
  Copyright (C) 2023 OneWay Web Apps, LLC.
  This unpublished material is proprietary to OneWay Web Apps, LLC.
  All rights reserved.  The methods and techniques described herein
  are considered trade secrets and/or confidential.
  Reproduction or distribution, in whole or in part, is forbidden
  except by express written permission of OneWay Web Apps, LLC.
***********************************************************************/
import browser from 'browser-detect';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { RouterModule, RouterOutlet } from '@angular/router';
// import { actionSettingsChangeAnimationsPageDisabled } from './core/settings/settings.actions';
import { environment as env } from '../environments/environment';
import { isSgAdminUser, SgUserListItem } from './core/models/SgUser';
// import { isNil } from './shared/utils';
// import { GamePlanStateService } from './featuresgame-plan/store/state-service';
import { APP_COPYRIGHT, APP_MENU_ABOUT, APP_MENU_DASHBOARD, APP_MENU_LOGOUT, APP_MENU_GAME_PLANS, APP_MENU_CONTACT_US, APP_MENU_LOGIN, APP_MENU_PRICING, APP_MENU_ABOUT_LINK, APP_MENU_PRICING_LINK, APP_MENU_CONTACT_US_LINK, APP_MENU_LOGIN_LINK, APP_MENU_DASHBOARD_LINK, APP_MENU_GAME_PLANS_LINK, APP_MENU_ADMIN_LINK, APP_MENU_ADMIN, APP_MENU_HOME_LINK, APP_MENU_HOME, APP_MENU_FAQ, APP_MENU_FAQ_LINK, APP_TITLE_LONG, APP_TITLE_SHORT, APP_MENU_HOW_TO, APP_MENU_HOW_TO_LINK, APP_MENU_SOURCE_REPORT, APP_MENU_SOURCE_REPORT_LINK, } from './core/models/common-ux-types';
import { SgEditingStatus, SgUserType } from './core/models/common-types';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from './auth/service/auth.service';
import { UiStateService } from './core/store/ui/state-service/ui.state-service';
import { AuthStateService } from './auth/store/state-service/auth.state-service';
import { LocalStorageService } from './core/local-storage/local-storage.service';
import { MenuItem, MessageService } from 'primeng/api';
// import { routeAnimations } from './core/animations/route.animations';
// import { LocalStorageService } from './core/local-storage/local-storage.service';
import { selectEffectiveTheme, selectSettingsStickyHeader } from './core/settings/settings.selectors';
// import { GamePlanStateService } from './features/game-plan/store/state-service';
// temporary
export const authNavButtons: MenuItem[] = [
  { routerLink: APP_MENU_FAQ_LINK, label: APP_MENU_FAQ, disabled: false, styleClass: 'menucus' },
  { routerLink: APP_MENU_HOW_TO_LINK, label: APP_MENU_HOW_TO, disabled: false },
  { routerLink: APP_MENU_PRICING_LINK, label: APP_MENU_PRICING, disabled: false },
  { routerLink: APP_MENU_CONTACT_US_LINK, label: APP_MENU_CONTACT_US, disabled: false },
  // { routerLink: APP_MENU_LOGIN_LINK, label: APP_MENU_LOGIN, disabled: false },
];
export const noAuthNavButtons: MenuItem[] = [
  { routerLink: APP_MENU_HOME_LINK, label: APP_MENU_HOME, disabled: false, styleClass: 'menucus' },
  // { routerLink: APP_MENU_ABOUT_LINK, label: APP_MENU_ABOUT, disabled: false },
  // { routerLink: APP_MENU_FAQ_LINK, label: APP_MENU_FAQ, disabled: false },
  // { routerLink: APP_MENU_HOW_TO_LINK, label: APP_MENU_HOW_TO, disabled: false },
  { routerLink: APP_MENU_PRICING_LINK, label: APP_MENU_PRICING, disabled: false },
  { routerLink: `auth/${APP_MENU_CONTACT_US_LINK}`, label: APP_MENU_CONTACT_US, disabled: false },
  { routerLink: `auth/${APP_MENU_LOGIN_LINK}`, label: APP_MENU_LOGIN, disabled: false },
];
export const noAuthNavButtonsSignUpDisabled: MenuItem[] = [
  { routerLink: APP_MENU_HOME_LINK, label: APP_MENU_HOME, disabled: false },
  { routerLink: APP_MENU_ABOUT_LINK, label: APP_MENU_ABOUT, disabled: false },
  //  { routerLink: APP_MENU_PRICING_LINK, label: APP_MENU_PRICING }
];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService],
  //imports: [ RouterOutlet, RouterModule],
  // animations: [routeAnimations],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  isProd = env.production;
  isOld=false;

  // items: MenuItem[] = authNavButtons;
  envName = env.envName;
  version = env.versions.app;
  isAlmlycm = env.almlycm;

  year = new Date().getFullYear();
  // logo2 = new URL('assets/img/ecpLogo50H.jpg', import.meta.url);
  logo = '../assets/img/ecpLogo50H.jpg';
  // wagerEventsLink = APP_MENU_GAME_PLANS_LINK; // GAME_PLANS_CONTAINER_LINK;

  logoutMenuItems: MenuItem[] = [{
    label: 'AA',
    items: [
      {
        label: 'Update',
        icon: 'pi pi-refresh',
        command: () => {
          this.onLogoutClick();
        }
      }
    ]
  }];
  navMenuItems = [
    ...noAuthNavButtons
  ];
  navigationSideMenu = [
    ...this.navMenuItems,
    //  { routerLink: APP_MENU_SETTINGS_LINK, label: APP_MENU_SETTINGS }
  ];
  APP_TITLE_SHORT = APP_TITLE_SHORT;
  APP_TITLE_LONG = APP_TITLE_LONG;
  APP_MENU_LOGOUT = APP_MENU_LOGOUT;
  APP_COPYRIGHT = APP_COPYRIGHT;


  isAdminActive$ = this.uiStateService.isAdminActive$;
  isSgAdminUser$ = this.authStateService.isSgAdminUser$;
  isSgUserAuthenticated$ = this.authStateService.isSgUserAuthenticated$;
  stickyHeader$: Observable<boolean>;
  language$: Observable<string>;
  theme$: Observable<string>;

  // isSgClientUser$ = this.authStateService.isSgClientUser$;

  loggedInSGUser: SgUserListItem;
  userInitials: string;
  isSignUpActive = false;
  gamePlanEditStatus = SgEditingStatus.NOT_EDITING;
  isAdminActive = false;
  hideFooter = false;
  clientLogoPath: string;
  isEditingEntity: boolean;

  private subscriptions = new Subscription();
  constructor(
    private changeDetector: ChangeDetectorRef,
    private authService: AuthService,
    private uiStateService: UiStateService,
    private authStateService: AuthStateService,
    private store: Store,
    // private gamePlanStateService: GamePlanStateService,
    private storageService: LocalStorageService,
    private router: Router
  ) { }

  private static isIEorEdgeOrSafari() {
    return ['ie', 'edge', 'safari'].includes(browser().name);
  }

  ngOnInit(): void {
    // this.wagerEventReportService.setLogoForReport('/assets/img/ecpLogo50H.jpg');
    this.authService.loadRcSettings();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        console.log('MS event.url= ', event.url);
        if (event.url.indexOf(APP_MENU_ADMIN_LINK) !== -1) {
          // if (!this.isAdminActive) {
          this.isAdminActive = true;
          //  }
        } else {
          this.isAdminActive = false;
          // }
        }
        console.log('MS this.isAdminActive= ', this.isAdminActive);
        // if ((event.url.indexOf(APP_MENU_ADMIN_LINK) !== -1) || (event.url.indexOf('dashboard') !== -1)) {
        //   this.hideFooter = true;
        // } else {
        //   this.hideFooter = false;
        // }
      }
    });
    // this.signInAnonymously();

    // this.authService.autoAuthUser();

    this.authService.initAuthListener();
    this.storageService.testLocalStorage();
    // if (AppComponent.isIEorEdgeOrSafari()) {
    //   this.store.dispatch(
    //     actionSettingsChangeAnimationsPageDisabled({
    //       pageAnimationsDisabled: true
    //     })
    //   );
    // }
    this.subscriptions.add(
      this.authStateService.rcSettings$.subscribe(rcSettings => {
        this.isSignUpActive = rcSettings?.isSignUpActive ? rcSettings.isSignUpActive : false;
        console.log('MS  this.isSignUpActive = ', this.isSignUpActive);
        if (!this.loggedInSGUser) {
          this.setNoAuthButtons();
        }
      })
    );
    // this.subscriptions.add(
    //   this.gamePlanStateService.gamePlanEditStatus$.subscribe(gamePlanEditStatus => {
    //     this.gamePlanEditStatus = gamePlanEditStatus;
    //     console.log('MS APP this.navMenuItems = ', this.navMenuItems);
    //     this.changeDetector.detectChanges();
    //     //  // console.log('MS HeaderComponent gamePlanEditStatus = ', this.gamePlanEditStatus);
    //   })
    // );
    this.subscriptions.add(
      this.uiStateService.isEditingEntity$.subscribe(isEditingEntity => {
        this.isEditingEntity = isEditingEntity;
        this.navMenuItems.forEach(item =>
          item.disabled = isEditingEntity
        )
        this.changeDetector.detectChanges();
      })
    );

    this.subscriptions.add(
      this.authStateService.loggedInSGUser$.subscribe(loggedInSGUser => {
        this.loggedInSGUser = loggedInSGUser;
        if (loggedInSGUser) {
          //   this.loggedInSGUser = loggedInSGUser;
          this.userInitials = ''
          this.setAuthNavButtons(loggedInSGUser.userType)
          if (loggedInSGUser.first && loggedInSGUser.first.length > 0 &&
            loggedInSGUser.last && loggedInSGUser.last.length > 0) {
            this.userInitials = `${loggedInSGUser.first[0]}${loggedInSGUser.last[0]}`;
          }
        } else {
          this.setNoAuthButtons();
        }
      })
    );

    // this.stickyHeader$ = this.store.pipe(select(selectSettingsStickyHeader));
    // // this.language$ = this.store.pipe(select(selectSettingsLanguage));
    this.theme$ = this.store.pipe(select(selectEffectiveTheme));
    this.uiStateService.setIsMobileDevice(this.isMobileDevice());
  }
  setAuthNavButtons(sgUserType: SgUserType) {
    if (isSgAdminUser(sgUserType)) {
      this.navMenuItems = [

        { routerLink: APP_MENU_DASHBOARD_LINK, label: APP_MENU_DASHBOARD, disabled: false },
        { routerLink: APP_MENU_SOURCE_REPORT_LINK, label: APP_MENU_SOURCE_REPORT, disabled: false },
        { routerLink: APP_MENU_GAME_PLANS_LINK, label: APP_MENU_GAME_PLANS, disabled: false },
        { routerLink: APP_MENU_ADMIN_LINK, label: APP_MENU_ADMIN, disabled: false },
        ...authNavButtons,
        { routerLink: APP_MENU_ABOUT_LINK, label: APP_MENU_ABOUT, disabled: false },
      ];
    } else {
      this.navMenuItems = [
        { routerLink: APP_MENU_DASHBOARD_LINK, label: APP_MENU_DASHBOARD, disabled: false },
        { routerLink: APP_MENU_SOURCE_REPORT_LINK, label: APP_MENU_SOURCE_REPORT, disabled: false },
        { routerLink: APP_MENU_ABOUT_LINK, label: APP_MENU_ABOUT, disabled: false },
      ];
    }
    this.navigationSideMenu = [
      ...this.navMenuItems
      //  { routerLink: APP_MENU_SETTINGS_LINK, label: APP_MENU_SETTINGS }
    ];
  }
  setNoAuthButtons() {
    console.log('MS setNoAuthButtons 1');
    this.userInitials = '';
    if (this.isSignUpActive) {
      this.navMenuItems = [
        ...noAuthNavButtons
      ];
    } else {
      this.navMenuItems = [
        ...noAuthNavButtonsSignUpDisabled
      ];
    }
    this.navigationSideMenu = [
      ...this.navMenuItems
    ];
    console.log('MS setNoAuthButtons this.navMenuItems = ', this.navMenuItems);
    this.changeDetector.detectChanges();
  }

  isMobileDevice() {
    return (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1);
  };

  onSelectMenuItem(menuItem: MenuItem) {
    console.log('MS menuItem= ',menuItem);
 this.router.navigate([menuItem.routerLink]);
  }


  // getIsDisabled(linkObj: MenuItem) {
  //   // if ((linkObj.link === APP_MENU_GAME_PLANS_LINK && !this.selectedClient) || this.wagerEventEditingStatus !== SgEditingStatus.NOT_EDITING ||
  //   // this.isEditingEntity) {
  //   //   return true;
  //   // }
  //   return false;
  // }

  onSettingsClick() {
    // if (isSgAdminUser(this.loggedInSGUser.userType)) {
    //   this.router.navigate([APP_MENU_SETTINGS_LINK]);
    // } else if (this.isAdminActive) {
    // this.clientStateService.setClientEditingStatus(SgEditingStatus.EDITING);
    // this.clientStateService.setSelectedClient(this.selectedClient);
    // this.router.navigate(['/admin/edit-client']);
    // }
  }

  // onClientSelected(event: MatSelectChange) {
  //   const selectedClient: Client = event.value;
  //   this.clientStateService.setSelectedClient(selectedClient);
  // }

  // onLoginClick() {
  //   this.authStateService.setSgUserAuthenticated(adminSgUser);
  // }

  onLogoutClick() {
    this.authService.logout();
    // this.authService.signOut();
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
