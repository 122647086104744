import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FirestoreCollectionType, Guid } from 'app/core/models/common-types';
import { GamePlan, GamePlanListItem, toGamePlanPayload } from 'app/core/models/GamePlan';
import { UiStateService } from 'app/core/store/ui/state-service';
import { Observable, from } from 'rxjs';

@Injectable()
export class GamePlanService {

  //  private fbSubs: Subscription[] = [];
  constructor(
    private db: AngularFirestore,
    private uiStateService: UiStateService) { }

  loadAllGamePlans(loadAll: boolean) {
    this.uiStateService.startLoading();
    if (loadAll) {
      const gpRefAll =
      this.db.collection<GamePlan>(FirestoreCollectionType.SG_GAME_PLANS, ref => ref.orderBy('createdTimestamp', 'desc'));
      return gpRefAll.snapshotChanges();
    }
    const gpRef =
    this.db.collection<GamePlan>(FirestoreCollectionType.SG_GAME_PLANS, ref => ref.where('active', '==', true));
    return gpRef.snapshotChanges();

  }

  // loadActiveGamePlans() {
  //   this.uiStateService.startLoading();
  //   const serviceEventsRef =
  //   this.db.collection<GamePlan>(FirestoreCollectionType.SG_GAME_PLANS, ref => ref.where('active', '==', true).orderBy('createdTimestamp', 'desc'));
  //   return serviceEventsRef.snapshotChanges();
  //   // const docRef = this.db.collection<GamePlan>(FirestoreCollectionType.SG_GAME_PLANS, x => x.where('active', '==', true));
  //   // return docRef.get();
  // }

  // loadGamePlans(
  //   customerId: string,
  //   locations: Project[],
  //   sgUser: SGUser) {
  //   this.uiStateService.startLoading();
  //   //  const collection = `${FirestoreCollectionType.SG_GAME_PLANS}_${customer.id}`;
  //   // this.fbSubs.push(
  //   const subscription = this.db
  //     .collection<GamePlan>(FirestoreCollectionType.SG_GAME_PLANS, x => x.where('customerId', '==', customerId))
  //     .snapshotChanges().pipe(
  //       map(docArray =>
  //         docArray.map(a => {
  //           const data = a.payload.doc.data() as GamePlan;
  //           const id = a.payload.doc['id'];
  //           return toGamePlanListItem(id, data, locations);
  //         })
  //       ))
  //     .subscribe(
  //       (gamePlanListItems: GamePlanListItem[]) => {
  //         this.uiStateService.stopLoading();

  //         this.gamePlanStateService.loadGamePlansSuccess(gamePlanListItems);
  //         // if (!sgUser.facilities || sgUser.facilities.length === 0) {
  //         //   this.gamePlanStateService.loadGamePlansSuccess(gamePlanListItems);
  //         // } else {
  //         //   this.gamePlanStateService.loadGamePlansSuccess(filterGamePlansForUser(gamePlanListItems, sgUser));
  //         // };
  //         subscription.unsubscribe();
  //       },
  //       error => {
  //         //  console.log('MS loadGamePlans error = ', error);
  //         this.uiStateService.stopLoading();
  //         this.uiService.showSnackbar(
  //           'Fetching Service Events failed',
  //           null,
  //           3000
  //         );
  //         subscription.unsubscribe();
  //       }
  //     )
  //   // );
  // }

  // addProject(project: Project): Observable<any> {
  //   //  return this.http.post<any>(AppServiceApiConfig.sgUsersSignUp, sgUser);
  //   const collection = `${FirestoreCollectionType.SG_FACTILITIES}`;
  //   return from(this.db.collection(collection).add(project));
  // }

  addGamePlan(gamePlanListItem: GamePlanListItem): Observable<any> {
    const gamePlan = toGamePlanPayload(gamePlanListItem);
    console.log('MS addGamePlan gamePlan = ', gamePlan);
    const collection = `${FirestoreCollectionType.SG_GAME_PLANS}`;
    return from(this.db.collection(collection).add(gamePlan));
  }

  deleteMultipleGamePlans(documentIdList: string[]) {
    const batch = this.db.firestore.batch()
    documentIdList.forEach(id => {
      const transDoc = this.db.firestore.doc(`${FirestoreCollectionType.SG_GAME_PLANS}/${id}`);
      batch.delete(transDoc);
    });
    return from(batch.commit());
  }

  deleteGamePlan(serviceEventId: Guid) {
    return from(this.db.collection(FirestoreCollectionType.SG_GAME_PLANS).doc(serviceEventId).delete());
  }

  updateGamePlan(gamePlanListItem: GamePlanListItem) {
    const gamePlan = toGamePlanPayload(gamePlanListItem);
    console.log('MS updateGamePlan gamePlan= ', gamePlan);

    return from(this.db.collection(FirestoreCollectionType.SG_GAME_PLANS).doc(gamePlan.id)
      .set(gamePlan));
  }
  // updateGamePlan(serviceEventListItem: GamePlanListItem) {
  //    NEED TO CONVERT THIS TO A GamePlan
  //   const collection = `${FirestoreCollectionType.SG_GAME_PLANS}`;
  //   const documentId = customer.id;
  //   return from(this.db.collection(collection).doc(documentId).set(customer));
  // }

  // loadTouchPointProjects2(selectedCustomerId: string): Observable<DocumentChangeAction<TouchPointProject>[]> {
  //   this.uiStateService.startLoading();
  //   console.log('MS loadTouchPointProjects selectedCustomerId= ', selectedCustomerId);
  //   const clRef = this.db.collection<TouchPointProject>(FirestoreCollectionType.SG_TOUCH_POINT_LOCATIONS,
  //      ref => ref.where('customerId', '==', selectedCustomerId))
  //      return clRef.snapshotChanges();
  // }

  // loadTouchPointProjects(customerId: string) {
  //   this.uiStateService.startLoading();
  //   // this.fbSubs.push(
  //   const subscription = this.db
  //     .collection<TouchPointProject>(FirestoreCollectionType.SG_TOUCH_POINT_LOCATIONS, x => x.where('customerId', '==', customerId))
  //     .stateChanges().pipe(
  //       map(docArray =>
  //         docArray.map(a => {
  //           const data = a.payload.doc.data() as TouchPointProject;
  //           const id = a.payload.doc['id'];
  //           return {
  //             ...data,
  //             id
  //           };
  //         })
  //       ))
  //     .subscribe(
  //       (locs: TouchPointProject[]) => {
  //         //  console.log('MS loadTouchPointProjects locs = ', locs);
  //         this.uiStateService.stopLoading();
  //         this.gamePlanStateService.loadTouchPointProjectsSuccess(locs);
  //         subscription.unsubscribe();
  //       },
  //       error => {
  //         //  console.log('MS loadProjects error = ', error);
  //         this.uiStateService.stopLoading();
  //         this.uiService.showSnackbar(
  //           'Fetching Customers Projects failed',
  //           null,
  //           3000
  //         );
  //         subscription.unsubscribe();
  //       }
  //     )
  //   // );
  // }
  //   async getEvents(customerId: string) {
  //     this.db
  //         .collection<TouchPointProject>(FirestoreCollectionType.SG_TOUCH_POINT_LOCATIONS, x => x.where('customerId', '==', customerId))
  //     .get()
  //     .then((querySnapshot) => {
  //         querySnapshot.forEach((doc) => {
  //             // doc.data() is never undefined for query doc snapshots
  //             console.log(doc.id, " => ", doc.data());
  //         });
  //     })
  //     .catch((error) => {
  //         console.log("Error getting documents: ", error);
  //     });

  // }

  // getServicePointProjects(customerId: string):
  //   Observable<{ message: string; touchPointProjectListItems: ServicePointProjectResponse[] }> {
  //   this.uiStateService.startLoading();
  //   const queryParams = `?customerId=${customerId}`;
  //   // console.log('MS getSgUsers queryParams = ', queryParams);
  //   return this.http.get<{ message: string; touchPointProjectListItems: ServicePointProjectResponse[] }>(
  //     AppServiceApiConfig.touchPointProjectListItems + queryParams
  //   );
  // }

  // seedTouchPointProjects(touchPointProjectListItems: TouchPointProject[]) {
  //   console.log('MS seedTouchPointProjects touchPointProjectListItems= ', touchPointProjectListItems);
  //   touchPointProjectListItems.forEach((touchPointProject) => {
  //     this.db.collection(FirestoreCollectionType.SG_TOUCH_POINT_LOCATIONS).add(touchPointProject);
  //   });
  // }
  // seedGamePlans(gamePlanListItems: GamePlan[]) {
  //   console.log('MS seedGamePlans gamePlanListItems= ', gamePlanListItems);
  //   gamePlanListItems.forEach((serviceEvent) => {
  //     this.db.collection(FirestoreCollectionType.SG_GAME_PLANS).add(serviceEvent);
  //   });
  // // }

  // addTouchPointProject(touchPointProject: TouchPointProject) {
  //   return from(this.db.collection(FirestoreCollectionType.SG_TOUCH_POINT_LOCATIONS).add(touchPointProject));
  // }


  // deleteTouchPointProject(touchPointProject: TouchPointProject) {
  //   return from(this.db.collection(FirestoreCollectionType.SG_TOUCH_POINT_LOCATIONS).doc(touchPointProject.id)
  //     .delete());
  // }

  // updateTouchPointProject(touchPointProjectListItem: TouchPointProjectListItem) {
  //   const touchPointProject = toTouchPointProjectPayload(touchPointProjectListItem);

  //   return from(this.db.collection(FirestoreCollectionType.SG_TOUCH_POINT_LOCATIONS).doc(touchPointProjectListItem.id)
  //     .set(touchPointProject));
  // }

  // cancelSubscriptions() {
  //   console.log('MS service events! cancelSubscriptions  this.fbSubs = ', this.fbSubs);
  //   // this.fbSubs.forEach((sub) => sub.unsubscribe());
  // }
}
