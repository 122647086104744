import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AdminGuardService, AuthGuardService } from './auth/auth.guard';
import { APP_MENU_DASHBOARD_LINK, APP_MENU_ADMIN_LINK, APP_MENU_GAME_PLANS_LINK, APP_MENU_SOURCE_REPORT_LINK } from './core/models/common-ux-types';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '#/',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./features/home/home.module').then((m) => m.HomeModule)
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./features/about/about.module').then((m) => m.AboutModule)
  },
  // {
  //   path: 'faq',
  //   loadChildren: () =>
  //     import('./features/faq/faq.module').then(
  //       (m) => m.FaqModule
  //     )
  // },
  // {
  //   path: 'how-to',
  //   loadChildren: () =>
  //     import('./features/how-to-stepper/how-to-stepper.module').then(
  //       (m) => m.HowToStepperModule
  //     )
  // },
  {
    path: 'pricing',
    loadChildren: () =>
      import('./features/pricing/pricing.module').then(
        (m) => m.PricingModule
      )
  },
  {
    path: APP_MENU_DASHBOARD_LINK,
    loadChildren: () =>
      import('./features/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      )
  },
  // {
  //   path: APP_MENU_SOURCE_REPORT_LINK,
  //   loadChildren: () =>
  //     import('./features/source-report/source-report.module').then(
  //       (m) => m.SourceReportModule
  //     )
  // },
  {
    path: 'auth',
    loadChildren: () =>
      import('./auth/auth.module').then(
        (m) => m.AuthModule
      )
  },
  {
    path: APP_MENU_ADMIN_LINK,
    canActivate: [AdminGuardService],
    loadChildren: () =>
      import('./features/rc-admin/rc-admin.module').then(
        (m) => m.RcAminModule
      )
  },
  // {
  //   path: APP_MENU_SETTINGS_LINK,
  //   canActivate: [AuthGuardService],
  //   loadChildren: () =>
  //     import('./features/settings/settings.module').then(
  //       (m) => m.SettingsModule
  //     )
  // },
  {
    path: APP_MENU_GAME_PLANS_LINK,
    canActivate: [AdminGuardService],
    loadChildren: () =>
      import('./features/game-plan/game-plan.module').then(
        (m) => m.GamePlanModule
      )
  },
  // {
  //   path: APP_MENU_BRACKET_PLANS_LINK,
  //   canActivate: [AdminGuardService],
  //   loadChildren: () =>
  //     import('./features/bracket-plan/bracket-plan.module').then(
  //       (m) => m.BracketPlanModule
  //     )
  // },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  // useHash supports github.io demo page, remove in your app
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule],
  providers: [AuthGuardService, AdminGuardService]
  // providers: [AuthGuardService, AdminGuardService, AlmlyCmAuthGuard, AlmlyPmAuthGuard]
})
export class AppRoutingModule { }
