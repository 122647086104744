import { Action } from '@ngrx/store';
import { Guid, SgEditingStatus } from 'app/core/models/common-types';
import { SportBetListItem } from 'app/core/models/SportBet';
import * as fromActionTypes from '../action-types';

// BETS

export class SetSportBetEditingStatus implements Action {
  readonly type = fromActionTypes.SPORT_BET_SET_SELECTED_BET_EDIT;
  constructor(public payload: SgEditingStatus) { }
}
export class SportBetSetSelectedBet implements Action {
  readonly type = fromActionTypes.SPORT_BET_SET_SELECTED_BET;
  constructor(public payload: SportBetListItem) { }
}
export class SportBetLoadBets implements Action {
  readonly type = fromActionTypes.SPORT_BET_LOAD_BETS;
  constructor() { }
}
export class SportBetLoadBetsFail implements Action {
  readonly type = fromActionTypes.SPORT_BET_LOAD_BETS_FAIL;
  constructor(public payload: any) { }
}
export class SportBetLoadBetsSuccess implements Action {
  readonly type = fromActionTypes.SPORT_BET_LOAD_BETS_SUCCESS;
  constructor(public payload: SportBetListItem[]) { }
}
export class SportBetAddBet implements Action {
  readonly type = fromActionTypes.SPORT_BET_ADD_BET;
  constructor(public payload: SportBetListItem) { }
}
export class SportBetAddBetSuccess implements Action {
  readonly type = fromActionTypes.SPORT_BET_ADD_BET_SUCCESS;
  constructor(public payload: SportBetListItem) { }
}
export class SportBetAddBetFail implements Action {
  readonly type = fromActionTypes.SPORT_BET_ADD_BET_FAIL;
  constructor(public payload: any) { }
}
export class SportBetDeleteBet implements Action {
  readonly type = fromActionTypes.SPORT_BET_DELETE_BET;
  constructor(public payload: SportBetListItem) { }
}
export class SportBetDeleteBetSuccess implements Action {
  readonly type = fromActionTypes.SPORT_BET_DELETE_BET_SUCCESS;
  constructor(public payload: SportBetListItem) { }
}
export class SportBetDeleteBetFail implements Action {
  readonly type = fromActionTypes.SPORT_BET_DELETE_BET_FAIL;
  constructor(public payload: any) { }
}
export class SportBetUpdateBet implements Action {
  readonly type = fromActionTypes.SPORT_BET_UPDATE_BET;
  constructor(public payload: SportBetListItem) { }
}
export class SportBetUpdateBetSuccess implements Action {
  readonly type = fromActionTypes.SPORT_BET_UPDATE_BET_SUCCESS;
  constructor(public payload: SportBetListItem) { }
}
export class SportBetUpdateBetFail implements Action {
  readonly type = fromActionTypes.SPORT_BET_UPDATE_BET_FAIL;
  constructor(public payload: any) { }
}
export class SportBetDeleteAllBets implements Action {
  readonly type = fromActionTypes.SPORT_BET_DELETE_ALL_BETS;
  constructor(public payload: Guid[]) { }
}
// action types
export type SportBetActions =
  | SportBetSetSelectedBet
  | SetSportBetEditingStatus
  | SportBetAddBet
  | SportBetAddBetFail
  | SportBetAddBetSuccess
  | SportBetDeleteAllBets
  | SportBetDeleteBet
  | SportBetDeleteBetFail
  | SportBetDeleteBetSuccess
  | SportBetLoadBets
  | SportBetLoadBetsFail
  | SportBetLoadBetsSuccess
  | SportBetUpdateBet
  | SportBetUpdateBetFail
  | SportBetUpdateBetSuccess

  ;
