import { createSelector } from '@ngrx/store';
import { AppState } from 'app/core/core.state';
import { SgEditingStatus } from 'app/core/models/common-types';
import { WagerSourcesState } from '../state/wager-source.state.interface';

export const wagerSourceStateSelector = (state: AppState): WagerSourcesState =>
  state.wagerSourceState;

export const wagerSourceSelector = createSelector(
  wagerSourceStateSelector,
  (state: WagerSourcesState) => state.wagerSources ? state.wagerSources : []
  // (state: RcAdminState) => state.customers? state.customers.sort((a, b) => a.name.localeCompare(b.name)) : []
);
export const selectedWagerSourceSelector = createSelector(
  wagerSourceStateSelector,
  (state: WagerSourcesState) => state.selectedWagerSource ?? undefined
);
export const isLoadedWagerSourceSelector = createSelector(
  wagerSourceStateSelector,
  (state: WagerSourcesState) => state.isLoaded ?? false
);


export const isWagerSourceSelectedSelector = createSelector(
  wagerSourceStateSelector,
  (state: WagerSourcesState) => state.selectedWagerSource ? true : false
);

// export const loadedSgUserWagerSourceSelector = createSelector(
//   wagerSourceStateSelector,
//   (state: WagerSourcesState) => state.loadedSgUserWagerSource ? state.loadedSgUserWagerSource : undefined
// );

export const wagerSourceEditingStatusSelector = createSelector(
  wagerSourceStateSelector,
  (state: WagerSourcesState) => state.editingStatus ? state.editingStatus : SgEditingStatus.NOT_EDITING
);

