import { Guid, GamePlanStatusType, LIST_DATE_TIME_FORMAT, SportBetStatusType } from './common-types';
import { SportBetListItem } from './SportBet';
import firebase from 'firebase/compat/app';
import { DatePayload, PTableColumn } from './common-ux-types';
import { getDatePayloadFromTimeStamp } from 'app/shared/utils/timestamp-utils';

export interface GamePlan {
  active: boolean;
  id?: Guid;
  createdTimestamp: firebase.firestore.Timestamp;
  notes: string;
  sportBetIds: Guid[];
  status: GamePlanStatusType;
  subtitle: string;
  title: string;
}

export const defaultGamePlan: GamePlan = {
  active: true,
  createdTimestamp: null,
  notes: '',
  sportBetIds: null,
  status: null,
  subtitle: '',
  title: '',
}

export interface GamePlanListItem extends GamePlan {
  createdDatePayload: DatePayload;
  parlayOdds?: number;
  totalPayouts?: number;
  totalWinnings?: number;
  totalWinners?: number;
  totalCompleted?: number;
  totalBets?: number;
  // startDatePayload: DatePayload;
  // endDatePayload: DatePayload;
  // updatedDatePayload: DatePayload;
  startBetDateStr?: string;
  endBetDateStr?: string;
  sportBetListItems?: SportBetListItem[];
}

// export const defaultGamePlanListItem: GamePlanListItem = {
//   ...defaultGamePlan,
//   createdDatePayload: undefined,
//   sportBetListItems: undefined,
// }

// export const gamePlanListDisplayColumns =
//   ['title', 'notes', 'status', 'sportBetListItems', 'startDate', 'endDate', 'active', 'actions'];
//  ['title', 'subtitle', 'notes', 'status', 'sportBetListItems', 'startDate', 'endDate', 'active','actions'];

export const gamePlanListDisplayColumns: PTableColumn[] = [
  { field: 'title', header: 'Title' , width: '10%' },
  { field: 'notes', header: 'Notes' , width: '10%' },
  { field: 'status', header: 'Status' , width: '3%' },
  { field: 'sportBetListItems', header: '# Recs', width: '3%' },
  { field: 'startBetDateStr', header: 'Start' , width: '3%' },
  { field: 'endBetDateStr', header: 'End' , width: '3%' },
  { field: 'active', header: 'Active' , width: '3%' },
  { field: 'actions', header: 'Actions' , width: '7%' }
];

export function toGamePlanPayload(
  gamePlanListItem: GamePlanListItem): GamePlan {

  console.log('MS toGamePlanPayload gamePlanListItem=  ', gamePlanListItem);
  const sportBetIds: Guid[] = [];
  if (gamePlanListItem.sportBetListItems?.length > 0) {
    gamePlanListItem.sportBetListItems.forEach(item => {
      sportBetIds.push(item.id);
    })
  };
  const gamePlan: GamePlan = {
    active: gamePlanListItem.active,
    id: gamePlanListItem.id ?? null,
    title: gamePlanListItem.title,
    subtitle: gamePlanListItem.subtitle,
    notes: gamePlanListItem.notes,
    sportBetIds: sportBetIds?.length > 0 ? [...sportBetIds] : null,
    createdTimestamp: gamePlanListItem.createdTimestamp,
    status: gamePlanListItem.status,
  };
  console.log('MS toGamePlanPayload gamePlan=  ', gamePlan);

  return gamePlan;
}

export function toGamePlanListItem(
  gamePlan: GamePlan,
  sportBetListItems: SportBetListItem[]
  // project: Project
): GamePlanListItem {
  // const schedRetVal = getDatePayloadFromTimeStamp(gamePlan.scheduledTimestamp, LIST_DATE_TIME_FORMAT);
  // console.log('MS toGamePlanPayload gamePlanListItem=  ', gamePlanListItem);

  // let startBetDateStr;
  // let endBetDateStr;
  // const sportBetListItems: SportBetListItem[] = [];
  // if (gamePlan.sportBetIds?.length > 0) {
  //   gamePlan.sportBetIds.forEach(item => {
  //     sportBetListItems.push(toSportBetListItem(item, null));
  //   });
  //   sportBetListItems.sort((a, b) => (a.gameDatePayload.sortDateStr > b.gameDatePayload.sortDateStr) ? 1 : -1);
  //   startBetDateStr = sportBetListItems[0].gameDatePayload.dateStr;
  //   endBetDateStr = sportBetListItems.length > 1 ?
  //     sportBetListItems[sportBetListItems.length - 1].gameDatePayload.dateStr : null;
  // };
  let startBetDateStr = null;
  let endBetDateStr = null;
  let parlayOdds = null;
  let totalCompleted = 0;
  let totalWinners = 0;
  let totalBets = 0
  let totalPayouts = 0
  let totalWinnings = 0
  let hasOpenBets = false;
  let decimalParlayOdds = 1;
  if (sportBetListItems?.length > 0) {
    sportBetListItems.sort((a, b) => (a.gameDatePayload.sortDateStr > b.gameDatePayload.sortDateStr) ? 1 : -1);
    startBetDateStr = sportBetListItems[0].gameDatePayload.dateStr;
    endBetDateStr = sportBetListItems.length > 1 ? sportBetListItems[sportBetListItems.length - 1].gameDatePayload.dateStr : null;
    sportBetListItems.forEach(item => {
      switch (item.status) {
        case SportBetStatusType.PENDING: {
          hasOpenBets = true;
          break;
        }
        case SportBetStatusType.WINNER: {
          totalCompleted++;
          totalWinners++;
          totalBets += item.bet;
          totalPayouts += item.payout;
          totalWinnings += item.winnings;
          break;
        }
        case SportBetStatusType.LOSER: {
          totalCompleted++;
          totalBets += item.bet;
          totalCompleted++;
          break;
        }
        case SportBetStatusType.PUSH: {
          totalCompleted++;
          break;
        }
      }
      const decimalOdds = item.odds > 0 ? ((item.odds / 100) + 1) : ((100 / (-1 * item.odds)) + 1);
      decimalParlayOdds = decimalParlayOdds * decimalOdds;
      //  console.log('MS toGamePlanListItem item.odds= ' + item.odds + ' decimalOdds= ' + decimalOdds + ' decimalParlayOdds= ' + decimalParlayOdds);
    });
    parlayOdds = (decimalParlayOdds - 1) * 100;
  }
  if (!hasOpenBets && sportBetListItems?.length > 0) {
    gamePlan.status = GamePlanStatusType.COMPLETED;
  }
  const retVal: GamePlanListItem = {
    ...gamePlan,
    createdDatePayload: getDatePayloadFromTimeStamp(gamePlan.createdTimestamp, LIST_DATE_TIME_FORMAT),
    sportBetListItems: sportBetListItems?.length > 0 ? [...sportBetListItems] : undefined,
    startBetDateStr,
    endBetDateStr,
    totalPayouts,
    totalWinnings,
    totalBets,
    totalWinners,
    totalCompleted,
    parlayOdds

  };
  console.log('MS toGamePlanListItem retVal=  ', retVal);
  return retVal;
}
