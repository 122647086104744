export const SET_FIREBASE_USER_AUTHENTICATED = '[Auth] Set Firebase User Authenticated';
export const SET_FIREBASE_USER_UNAUTHENTICATED = '[Auth] Set Firebase User Unauthenticated';

export const LOAD_SG_USERS = '[Auth] Load RC Users';
export const LOAD_SG_USERS_FAIL = '[Auth] Load RC Users Fail';
export const LOAD_SG_USERS_SUCCESS = '[Auth] Load RC Users Success';

export const LOAD_SG_USER_FOR_AUTH = '[Auth] Load RC User for Auth';
export const LOAD_SG_USER_FOR_AUTH_FAIL = '[Auth] Load RC Users Auth Fail';
export const LOAD_SG_USER_FOR_AUTH_SUCCESS = '[Auth] Load RC Users Auth Success';

export const SET_SG_USER_AUTHENTICATED = '[Auth] Set RC User Authenticated';
export const SET_SG_USER_UNAUTHENTICATED = '[Auth] Set RC User Unauthenticated';
export const SET_SG_USER_EDITING_STATUS = '[Auth] Set Selected User Editing Status';

export const LOAD_SG_SETTINGS = '[Auth] Load RcSettings';
export const LOAD_SG_SETTINGS_FAIL = '[Auth] Load RcSettings Fail';
export const LOAD_SG_SETTINGS_SUCCESS = '[Auth] Load RcSettings Success';
export const UPDATE_SG_SETTINGS = '[Auth] Update RcSettings Document';
export const UPDATE_SG_SETTINGS_FAIL = '[Auth] Update RcSettings Document Fail';
export const UPDATE_SG_SETTINGS_SUCCESS = '[Auth] Update RcSettings Document Success';

export const ADD_SG_USER = '[Auth] Add New SgUser';
export const ADD_SG_USER_FAIL = '[Auth] Add New SgUser Fail';
export const ADD_SG_USER_SUCCESS = '[Auth] Add New SgUser Success';
export const UPDATE_SG_USER = '[Auth] Update SgUser Document';
export const UPDATE_SG_USER_FAIL = '[Auth] Update SgUser Document Fail';
export const UPDATE_SG_USER_SUCCESS = '[Auth] Update SgUser Document Success';
export const DELETE_SG_USER = '[Auth] Delete SgUser Document';
export const DELETE_SG_USER_FAIL = '[Auth] Delete SgUser Document Fail';
export const DELETE_SG_USER_SUCCESS = '[Auth] Delete SgUser Document Success';

// this is to send the sign up for to support@projastic.com
export const EMAIL_NEW_CLIENT_CONTACT_INFO = '[Auth] Email New Client Contact Info';
export const EMAIL_NEW_CLIENT_CONTACT_INFO_FAIL = '[Auth] Email New Client Contact Info Fail';
export const EMAIL_NEW_CLIENT_CONTACT_INFO_SUCCESS = '[Auth] Email New Client Contact Info Success';
// this is to send an email to a customer
export const SEND_EMAIL_TO_CUSTOMER = '[Auth] Send Email to Customer';
export const SEND_EMAIL_TO_CUSTOMER_FAIL = '[Auth] Send Email to Customer Fail';
export const SEND_EMAIL_TO_CUSTOMER_SUCCESS = '[Auth] Send Email to Customer Success';

export const SEND_RECAPTCHA_TOKEN = '[Auth] Send Recaptcha';
export const SEND_RECAPTCHA_TOKEN_FAIL = '[Auth] Send Recaptcha Fail';
export const SEND_RECAPTCHA_TOKEN_SUCCESS = '[Auth] Send Recaptcha Success';

export const EMAIL_CUSTOMER_SERVICE_REQUEST = '[Auth] Email Customer Service Request';
export const EMAIL_CUSTOMER_SERVICE_REQUEST_FAIL = '[Auth] Email Customer Service Request Fail';
export const EMAIL_CUSTOMER_SERVICE_REQUEST_SUCCESS = '[Auth] Email Customer Service Request Success';
