// import { Client } from 'app/core/models/Client';
// import { SgEditingStatus } from 'app/core/models/common-types';

import { Client } from 'app/core/models/Client';
import { SgEditingStatus } from 'app/core/models/common-types';

export interface ClientListState {
  clientEditingStatus: SgEditingStatus;
  clients: Client[];
  // loadedSgUserClient: Client;
  selectedClient: Client;
}

export const INITIAL_CLIENT_LIST_STATE: ClientListState = {
  clientEditingStatus: SgEditingStatus.NOT_EDITING,
  clients: [],
  // loadedSgUserClient: undefined,
  selectedClient: undefined,
};
