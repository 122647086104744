import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { UiStateService } from 'app/core/store/ui/state-service';
import { FirestoreCollectionType, Guid } from 'app/core/models/common-types';
import { Observable, from } from 'rxjs';
import { SportBet, SportBetListItem , toSportBetPayload} from 'app/core/models/SportBet';

@Injectable({ providedIn: 'root' })
export class SportBetListService {

  constructor(
    private db: AngularFirestore,
    // private uiService: UiService,
    // private sportbetListStateService: SportBetStateService,
    private uiStateService: UiStateService) { }

  loadSportBetsOld(projectId: Guid) {
    this.uiStateService.startLoading();
  //   if (clientId === AG_SPORTS_BET_CLIENT_ID) {
      const customerCollection =
      this.db.collection<SportBet>(FirestoreCollectionType.SB_SPORT_BETS, ref => ref.orderBy('gameDateTimestamp', 'desc'));
      return customerCollection.snapshotChanges();
    // } else {
    //   const customerCollection = this.db.collection<SportBet>(FirestoreCollectionType.SB_SPORT_BETS, x => x.where('clientId', '==', clientId));
    //   return customerCollection.snapshotChanges();
    // }
    // or .valueChanges() does not have the id
  }

  loadSportBets(sortField: string, directionStr: firebase.firestore.OrderByDirection) {
    this.uiStateService.startLoading();
    return this.db
      .collection<SportBet>(FirestoreCollectionType.SB_SPORT_BETS, x => x.orderBy(sortField, directionStr))
      .snapshotChanges();
    // or .snapshotChanges()
  }

  loadSportBet(id: Guid) {
    this.uiStateService.startLoading();
    return this.db
      .doc(`${FirestoreCollectionType.SB_SPORT_BETS}/${id}`)
      // you can use either:
      .valueChanges();
    // or .snapshotChanges()
  }

  // loadSportBets(sortField: string, directionStr: firebase.firestore.OrderByDirection) {

  //   this.uiStateService.startLoading();
  //   const subscription = this.db
  //     .collection<SportBet>(FirestoreCollectionType.SB_SPORT_BETS, x => x.orderBy(sortField, directionStr))
  //     .snapshotChanges().pipe(
  //       map(docArray =>
  //         docArray.map(a => {
  //           const data = a.payload.doc.data() as SportBet;
  //           const id = a.payload.doc['id'];
  //           return {
  //             ...data,
  //             id
  //           } as SportBetListItem;
  //         })
  //       ))
  //     .subscribe(
  //       (sportbets: SportBetListItem[]) => {
  //         this.uiStateService.stopLoading();
  //         this.sportbetListStateService.loadSportBetsSuccess(sportbets);
  //         subscription.unsubscribe();
  //       },
  //       error => {
  //         console.log('MS loadSportBets error = ', error);
  //         this.uiStateService.stopLoading();
  //         this.uiService.showSnackbar(
  //           'Fetching SportBets failed',
  //           null,
  //           3000
  //         );
  //         subscription.unsubscribe();
  //       }
  //     )
  // }

  deleteMultipleSportBets(sportbetIdList: string[]) {
    const batch = this.db.firestore.batch()
    sportbetIdList.forEach(sportbetId => {
      const transDoc = this.db.firestore.doc(`${FirestoreCollectionType.SB_SPORT_BETS}/${sportbetId}`);
      batch.delete(transDoc);
    });
    return from(batch.commit());
  }

  addSportBet(sportbetListItem: SportBetListItem): Observable<DocumentReference<any>> {
    //  return this.http.post<CustomerResponse>(AppServiceApiConfig.customers, customer);
    return from(this.db.collection(FirestoreCollectionType.SB_SPORT_BETS).add(toSportBetPayload(sportbetListItem)));
  }

  deleteSportBet(sportbetId: string) {
    const collection = `${FirestoreCollectionType.SB_SPORT_BETS}`;
    return from(this.db.collection(collection).doc(sportbetId).delete());
  }


  updateSportBet(sportbetListItem: SportBetListItem) {
    const collection = `${FirestoreCollectionType.SB_SPORT_BETS}`;
    return from(this.db.collection(collection).doc(sportbetListItem.id).set(toSportBetPayload(sportbetListItem)));
  }

}
