<div class="main-container">
  <p-menubar [model]="navMenuItems">
    <ng-template pTemplate="start">
      <span routerLink=""
                class="">
            <img [src]="logo"
                 [alt]="logo" /></span>
  </ng-template>

    <ng-template pTemplate="end">
      <p-button *ngIf="(isSgUserAuthenticated$ | async)"
                (click)="onLogoutClick()"
                label="Logout"
                icon="pi pi-power-off"></p-button>
    </ng-template>
  </p-menubar>

  <div class="main-container-outlet">
    <router-outlet></router-outlet>
    <p-toast></p-toast>
    <p-confirmDialog></p-confirmDialog>
  </div>
  <!-- <div *ngIf="!hideFooter"
         class="footer d-none d-sm-block">
      <div class="row">
        <div class="col-sm-12 links">
        </div>
      </div>
      <div class="row">
        <div class="col-12 signature pb-2">
          &#169; <span class="year">{{year}}</span> - {{APP_COPYRIGHT}}
          <br class="d-block d-sm-none ">
          <a> {{APP_TITLE_SHORT}}

            {{version}} <span *ngIf="!isProd">[{{envName}}]</span>
          </a>
        </div>
      </div>
    </div>
  </div> -->
</div>
