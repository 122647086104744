import { Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { FileUpload } from 'app/core/models/FileUpload';
import { from, Observable, of } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import 'firebase/storage';
import firebase from 'firebase/compat/app';

export interface FilesUploadMetadata {
  uploadProgress$: Observable<number>;
  downloadUrl$: Observable<string>;
}
export interface FileListMetadata {
  fileList$: Observable<string[]>;
}

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  // currentFileUpload: FileUpload;
  // uploadState: Observable<string>;
  // uploadProgress: Observable<number>;
  // downloadURL: Observable<string>;
  // private basePath = '/uploads';
  // storageRef = firebase.storage().ref();

  // storage = getStorage();

  constructor(private storage: AngularFireStorage) { }

  // getCurrentFileUpload() {
  // return this.currentFileUpload;
  // }

  uploadDocumentDefinitionAndGetMetadata(clientPath: string, reportName: string, docDef: any): FilesUploadMetadata {
    // const { name } = fileToUpload;
    // const filePath = `${mediaFolderPath}/${new Date().getTime()}_${name}`;
    // const uploadTask: AngularFireUploadTask = this.storage.upload(filePath, fileToUpload);
    reportName = '20210615_reportDocDef';

    const filePath = `${clientPath}/${reportName}`;
   //  console.log('MS filePath = ', filePath);
    const storageRef = this.storage.ref(filePath);
   //  console.log('MS storageRef = ', storageRef);
    const uploadTask = storageRef.putString(docDef);

    return {
      uploadProgress$: uploadTask.percentageChanges(),
      downloadUrl$: this.getDownloadUrl$(uploadTask, filePath),
    };
  }
  uploadDataURLStringAndGetMetadata(clientPath: string, reportName: string,urlString: string): FilesUploadMetadata {
    // const { name } = fileToUpload;
    // const filePath = `${mediaFolderPath}/${new Date().getTime()}_${name}`;
    // const uploadTask: AngularFireUploadTask = this.storage.upload(filePath, fileToUpload);
    reportName = '20210615_reportblob';

    const filePath = `${clientPath}/${reportName}`;
   //  console.log('MS filePath = ', filePath);
    const storageRef = this.storage.ref(filePath);
   //  console.log('MS storageRef = ', storageRef);
    const uploadTask = storageRef.putString(urlString);

    return {
      uploadProgress$: uploadTask.percentageChanges(),
      downloadUrl$: this.getDownloadUrl$(uploadTask, filePath),
    };
  }
  uploadBytesAndGetMetadata(clientPath: string, reportName: string, bytes: Uint8Array): FilesUploadMetadata {
    // const { name } = fileToUpload;
    // const filePath = `${mediaFolderPath}/${new Date().getTime()}_${name}`;
    // const uploadTask: AngularFireUploadTask = this.storage.upload(filePath, fileToUpload);
   //  reportName = '20210615_reportblob';

    const filePath = `${clientPath}/${reportName}`;
   //  console.log('MS filePath = ', filePath);
    const storageRef = this.storage.ref(filePath);
   //  console.log('MS storageRef = ', storageRef);
    const uploadTask = storageRef.put(bytes);

    return {
      uploadProgress$: uploadTask.percentageChanges(),
      downloadUrl$: this.getDownloadUrl$(uploadTask, filePath),
    };
  }
  uploadFileAndGetMetadata(clientPath: string, fileUpload: FileUpload): FilesUploadMetadata {
    // const { name } = fileToUpload;
    // const filePath = `${mediaFolderPath}/${new Date().getTime()}_${name}`;
    // const uploadTask: AngularFireUploadTask = this.storage.upload(filePath, fileToUpload);

    const filePath = `${clientPath}/${fileUpload.file.name}`;
   //  console.log('MS filePath = ', filePath);
    const storageRef = this.storage.ref(filePath);
   //  console.log('MS storageRef = ', storageRef);
    const uploadTask = this.storage.upload(filePath, fileUpload.file);

    return {
      uploadProgress$: uploadTask.percentageChanges(),
      downloadUrl$: this.getDownloadUrl$(uploadTask, filePath),
    };
  }
  getDownloadUrl$(uploadTask: AngularFireUploadTask, path: string): Observable<string> {
    return from(uploadTask).pipe(switchMap((_) => this.storage.ref(path).getDownloadURL()));
  }


  // pushFileToStorage(clientPath: string, fileUpload: FileUpload): Observable<number | undefined> {
  //   const filePath = `${clientPath}/${fileUpload.file.name}`;
  //   console.log('MS filePath = ', filePath);
  //   const storageRef = this.storage.ref(filePath);
  //   console.log('MS storageRef = ', storageRef);
  //   const uploadTask = this.storage.upload(filePath, fileUpload.file);

  //   this.uploadState = uploadTask.snapshotChanges().pipe(map(s => s.state));
  //   this.uploadProgress = uploadTask.percentageChanges();
  //   this.downloadURL = uploadTask.downloadURL();

  //   uploadTask.snapshotChanges().pipe(
  //     finalize(() => {
  //       storageRef.getDownloadURL().subscribe(downloadURL => {
  //         fileUpload.url = downloadURL;
  //         fileUpload.name = fileUpload.file.name;
  //         console.log('MS fileUpload = ', fileUpload);
  //         this.currentFileUpload = {...fileUpload};
  //         return of(101);
  //         // this.saveFileData(fileUpload);
  //       });
  //     })
  //   ).subscribe();

  //   return uploadTask.percentageChanges();
  // }
  getImageFromStorage(clientPath: string,filename: string): Observable<string> | undefined {
    const filePath = `${clientPath}/${filename}`;
    // console.log('MS getImageFromStorage filePath = ', filePath);
    return this.storage.ref(filePath).getDownloadURL().pipe(
      take(1),
      catchError((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        //  console.log('MS getImageFromStorage ERROR = ', error.code);
        switch (error.code) {
          case 'storage/object-not-found':
            return of('ERROR: File doesn\'t exist');
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            return of('ERROR: User doesn\'t have permission');
          case 'storage/canceled':
            // User canceled the upload
            return of('ERROR: User canceled');
        }
        return of('ERROR: Unknown error occurred');
      })
    )
  }

  getPdfBytesFromStorage(url: string): Observable<string> | undefined {
   //  console.log('MS getImageFromStorage url = ', url);
    return this.storage.refFromURL(url).getDownloadURL().pipe(
      take(1),
      catchError((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        //  console.log('MS getImageFromStorage ERROR = ', error.code);
        switch (error.code) {
          case 'storage/object-not-found':
            return of('ERROR: File doesn\'t exist');
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            return of('ERROR: User doesn\'t have permission');
          case 'storage/canceled':
            // User canceled the upload
            return of('ERROR: User canceled');
        }
        return of('ERROR: Unknown error occurred');
      })
    )
  }
  // this does list the files.. just need to put it in a store for use.
  getFiles(clientPath: string){

  const storageRef = firebase.storage().ref(clientPath);
  const fileList: string[] = [];
   // Find all the prefixes and items.
   storageRef.listAll()
   .then((res) => {
   // console.log('MS getFiles res= ', res);
     res.prefixes.forEach((folderRef) => {
       // All the prefixes under listRef.
       // You may call listAll() recursively on them.
          //  console.log('MS getFiles folderRef= ', folderRef);
     });
     res.items.forEach((itemRef) => {
       // All the items under listRef.
          //  console.log('MS getFiles itemRef= ', itemRef.name);
           fileList.push(itemRef.name);
     });
     if (fileList) {
       return fileList;
     }
   }).catch((error) => {
     // Uh-oh, an error occurred!
   })



    // const storageRef = this.storage.ref().child(clientPath)

    // const listRef = storageRef.child(clientPath);
    // const listOfFIles: string[] = [];
    // // Find all the prefixes and items.
    // listRef.listAll()
    //   .then((res) => {
    //     res.prefixes.forEach((folderRef) => {
    //       // All the prefixes under listRef.
    //       // You may call listAll() recursively on them.
    //       console.log('MS getFiles folderRef= ', folderRef);
    //     });
    //     res.items.forEach((itemRef) => {
    //       // All the items under listRef.
    //       console.log('MS getFiles itemRef= ', itemRef);
    //     });
     //  })
      // .catch((error) => {
      //   // Uh-oh, an error occurred!
      // });

    // return this.db.list(this.basePath, ref =>
    //   ref.limitToLast(numberItems));
  }

  deleteFileStorage(clientPath: string, filename: string): Observable<string> | undefined {
    // const storageRef = this.storage.ref(clientPath);
    // const deleteRef = storageRef.child(filename);
    // //  const filePath = `${this.basePath}/${filename}`;
    // //  const storageRef = this.storage.ref(filePath);
    // // Delete the file
    // // eslint-disable-next-line arrow-body-style
    // deleteRef.delete().then(() => {
    //   console.log('MS deleteFileStorage Success');
    //   return of('Success')
    // }
    //   // eslint-disable-next-line arrow-body-style
    // ).catch((error) => {
    //   console.log('MS deleteFileStorage error = ', error.code);
    //   return of('ERROR: Unknown error occurred')
    // })
     return of('ERROR: Unknown error occurred')

  }


  // getImageFromStorage2(filename: string) : Observable<string> | undefined {
  //   const filePath = `${this.basePath}/${filename}`;
  //   console.log('MS filename = ', filename);
  //   // const storageRef = this.storage.ref();
  //   const storageRef = firebase.storage().ref();
  //   // Get the download URL
  //   storageRef.child(filePath).getDownloadURL()
  //   .then((url: string) => {
  //     console.log('MS getImageFromStorage url = ', url);
  //     // Insert url into an <img> tag to "download"
  //     return url;
  //   })
  //   .catch((error) => {
  //     // A full list of error codes is available at
  //     // https://firebase.google.com/docs/storage/web/handle-errors
  //     console.log('MS getImageFromStorage  error= ', error);
  //     switch (error.code) {
  //       case 'storage/object-not-found':
  //         // File doesn't exist
  //         break;
  //       case 'storage/unauthorized':
  //         // User doesn't have permission to access the object
  //         break;
  //       case 'storage/canceled':
  //         // User canceled the upload
  //         break;
  //       case 'storage/unknown':
  //         // Unknown error occurred, inspect the server response
  //         break;
  //     }
  //     return error.code;
  //   });
  //   return '';

  // }

  // getFiles(numberItems: number): AngularFireList<FileUpload> {
  //   const storageRef = this.storage.ref();
  //   return this.db.list(this.basePath, ref =>
  //     ref.limitToLast(numberItems));
  // }

  // deleteFile(fileUpload: FileUpload): void {
  //   this.deleteFileDatabase(fileUpload.key)
  //     .then(() => {
  //       this.deleteFileStorage(fileUpload.name);
  //     })
  //     .catch(error => console.log(error));
  // }

  // private saveFileData(fileUpload: FileUpload): void {
  //   this.db.list(this.basePath).push(fileUpload);
  // }
  // private deleteFileDatabase(key: string): Promise<void> {
  //   return this.db.list(this.basePath).remove(key);
  // }

  // private deleteFileStorage(name: string): void {
  //   const storageRef = this.storage.ref(this.basePath);
  //   storageRef.child(name).delete();
  // }
}
