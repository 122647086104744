// import { AuthService } from 'app/auth/service/auth.service';
import { AuthEffects } from 'app/auth/store/effects/auth.effects';
import { AuthStateService } from 'app/auth/store/state-service';
import { GamePlanStateService } from 'app/features/game-plan/store/state-service';
// import { GoogleAnalyticsEffects } from './google-analytics/google-analytics.effects';
import { ClientService } from './services/clients/clients.service';
// import { ConfirmationService } from './services/confirmation/confirmation.service';
import { EmailService } from './services/email/email.service';
import { ExportFileService } from './services/export-file/export-file.service';
import { FileUploadService } from './services/file-upload/file-upload.service';
import { UiService } from './services/ui/ui.service';
import { SettingsEffects } from './settings/settings.effects';
import { UiStateService } from './store/ui/state-service';
import { ClonerService } from './services/cloner-service/cloner-service';
import { SportBetStateService } from './store/sport-bets/state-service/sport-bets.state-service';
import { SportBetEffects } from './store/sport-bets/effects/sport-bets.effects';
import { AuthService } from 'app/auth/service/auth.service';
import { GamePlanEffects } from 'app/features/game-plan/store/effects/game-plan.effects';
import { GamePlanService } from 'app/features/game-plan/service';
import { ClientStateService } from './store/clients/state-service';
import { ClientListEffects } from './store/clients/effects/clients.effects';
import { WagerSourceStateService } from './store/wager-source/state-service';
import { WagerSourceEffects } from './store/wager-source/effects/wager-source.effects';
import { SportBetListService } from './services/sport-bets/sport-bets.service';

export const SG_WEB_SERVICES = [
  AuthService,
  UiService,
  // ConfirmationService,
  EmailService,
  SportBetListService,
  // ReportOptionsService,
  // ReportDocumentService,
  ExportFileService,
  // ReportService,
  FileUploadService,
  ClientService,
  ClonerService,
  GamePlanService,
  // PropertyService
];

export const SG_EFFECTS = [
  AuthEffects,
  // GoogleAnalyticsEffects,
  GamePlanEffects,
  ClientListEffects,
  // SgAdminEffects,
  // ReportOptionsEffects,
  // ReportDocumentEffects,
  SportBetEffects,
  SettingsEffects,
  WagerSourceEffects
];

export const SG_STATE_SERVICES = [
  UiStateService,
  AuthStateService,
  GamePlanStateService,
  ClientStateService,
  WagerSourceStateService,
  SportBetStateService,
];
