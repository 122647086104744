import { CLIENT_LIST_LOAD_CLIENTS, CLIENT_LIST_LOAD_CLIENTS_FAIL, CLIENT_LIST_LOAD_CLIENTS_SUCCESS,
  LOAD_CLIENT_FOR_USER,
  LOAD_CLIENT_FOR_USER_FAIL,
  LOAD_CLIENT_FOR_USER_SUCCESS,
   CLIENT_LIST_SET_SELECTED_CLIENT, SET_CLIENT_EDITING_STATUS } from '../action-types';
import { ClientListActions } from '../actions';
import { INITIAL_CLIENT_LIST_STATE, ClientListState } from '../state/client.state.interface';

export function clientListReducer(
  state = INITIAL_CLIENT_LIST_STATE,
  action: ClientListActions
) : ClientListState{
  switch (action.type) {

    case LOAD_CLIENT_FOR_USER:
    case LOAD_CLIENT_FOR_USER_FAIL:
      return {
        ...state,
        selectedClient: undefined,
        clients: [],
      };
    case LOAD_CLIENT_FOR_USER_SUCCESS: {
      return {
        ...state,
        selectedClient: action.payload,
        clients: [{...action.payload}],
      };
    }

    case CLIENT_LIST_LOAD_CLIENTS:
    case CLIENT_LIST_LOAD_CLIENTS_FAIL:
      return {
        ...state,
        clients: [],
        selectedClient: undefined
      };
    case CLIENT_LIST_LOAD_CLIENTS_SUCCESS: {
      const newClients = [...action.payload];
      return {
        ...state,
        clients: newClients.sort((a, b) => a.last.localeCompare(b.last))
      };
    }
    case SET_CLIENT_EDITING_STATUS:
      return {
        ...state,
        clientEditingStatus: action.payload,
      };
    case CLIENT_LIST_SET_SELECTED_CLIENT:
      return {
        ...state,
        selectedClient: action.payload,
      };
    default: {
      return state;
    }
  }
}
