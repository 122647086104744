/* eslint-disable @typescript-eslint/naming-convention */
import { Time } from '@angular/common';
import { AmPmType, Guid } from './common-types';


export const AG_SPORTS_BET_CLIENT_ID = 'agpicksClientId';
export const CLIENT_ID_DOCUMENT_FIELD = 'ClientId';

export const APP_MENU_ABOUT = 'About';
export const APP_MENU_ADMIN = 'Admin';
export const APP_MENU_DASHBOARD = 'Dashboard';
export const APP_MENU_SOURCE_REPORT = 'SourceReport';
export const APP_MENU_FAQ = 'FAQ';
export const APP_MENU_GAME_PLANS = 'Game Plans';
export const APP_MENU_HOME = 'Home';
export const APP_MENU_HOW_TO = 'How To';
export const APP_MENU_LOGIN = 'Login';
export const APP_MENU_LOGOUT = 'Logout';
export const APP_MENU_PRICING = 'Pricing';
export const APP_MENU_SETTINGS = 'Settings';
export const APP_MENU_SIGN_UP = 'Sign Up';
export const APP_MENU_CONTACT_US = 'Contact Us';

export const APP_MENU_ABOUT_LINK = 'about';
export const APP_MENU_ADMIN_LINK = 'admin';
export const APP_MENU_SOURCE_REPORT_LINK = 'source-report';
export const APP_MENU_DASHBOARD_LINK = 'dashboard';
export const APP_MENU_FAQ_LINK = 'faq';
export const APP_MENU_GAME_PLANS_LINK = 'game-plan-view';
export const APP_MENU_HOME_LINK = 'home';
export const APP_MENU_HOW_TO_LINK = 'how-to';
export const APP_MENU_LOGIN_LINK = 'login';
export const APP_MENU_PRICING_LINK = 'pricing';
export const APP_MENU_SETTINGS_LINK = 'settings';
export const APP_MENU_SIGN_UP_LINK = 'sign-up';
export const APP_MENU_CONTACT_US_LINK = 'contact-us';


export const APP_TITLE_LONG = 'AG Picks';
export const APP_TITLE_SHORT = 'AGPicks';
export const APP_PARENT_COMPANY = 'AG Picks';
// export const APP_COPYRIGHT = 'OneWay Web Applications, LLC.';
export const APP_COPYRIGHT = 'OneWay Web Apps';
// export const APP_NAME = 'AGPicks';
export const APP_INFO_EMAIL = 'info@projastic.com';
export const APP_SUPPORT_EMAIL = 'support@projastic.com';
// TODO SEE IF THIS WORKS in about.html
export const APP_INFO_HREF = 'mailto:info@projastic.com';

export const APP_LINK_ADMIN = '/admin';

// export function getAppTitleShort() {
//   if (environment.almlycm) {
//     return 'AG Picks';
//   } else {
//     return 'PropGambit';
//   }
// }
// export function getAppTitleLong() {
//   if (environment.almlycm) {
//     return 'AG Picks';
//   } else {
//     return 'PropGambit';
//   }
// }


export interface SinglePluralString {
  single: string;
  plural: string;
}
export interface FetchPayloadData {
  entityId: Guid;
  documentField: string;
}

export interface DatePayload {
  date: Date,
  gameDateTime: Date,
  gameTime?: Time,
  dateStr: string,
  sortDateStr: string
}

export interface PTableColumn {
  field: string;
  header: string;
  width?: string;
}

export interface PDropdownItem {
  value: string;
}
