import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/core/core.state';
import { SgEditingStatus } from 'app/core/models/common-types';
import { SportBet, SportBetListItem } from 'app/core/models/SportBet';
import { SportBetLoadBets, SportBetSetSelectedBet, SetSportBetEditingStatus, SportBetAddBet, SportBetDeleteBet, SportBetUpdateBet } from '../actions';
import { isSportBetLoadedSourceSelector, selectedSportBetListItemSelector, sportBetditingStatusSelector, sportBetListSelector } from '../selectors';

@Injectable()
export class SportBetStateService {
  sportBetListItems$ = this.store.select(sportBetListSelector);
  selectedSportBetListItem$ = this.store.select(selectedSportBetListItemSelector);
  sportBetEditingStatus$ = this.store.select(sportBetditingStatusSelector);
  isLoaded$ = this.store.select(isSportBetLoadedSourceSelector);

  constructor(
    private store: Store<AppState>
  ) { }

  loadSportBets() {
    this.store.dispatch(new SportBetLoadBets());
  }

  setSelectedSportBet(sportBetListItem: SportBetListItem) {
    this.store.dispatch(new SportBetSetSelectedBet(sportBetListItem));
  }
  setSportBetEditingStatus(editingStatus: SgEditingStatus) {
    this.store.dispatch(new SetSportBetEditingStatus(editingStatus));
  }
  addSportBet(sportBetListItem: SportBetListItem) {
    this.store.dispatch(new SportBetAddBet(sportBetListItem));
  }

  // deleteAllBets(productIds: Guid[]) {
  //   this.store.dispatch(new SportBetDeleteAllBets(productIds));
  // }
  deleteSportBet(sportBetListItem: SportBetListItem) {
    this.store.dispatch(new SportBetDeleteBet(sportBetListItem));
  }
  updateSportBet(sportBetListItem: SportBetListItem) {
    this.store.dispatch(new SportBetUpdateBet(sportBetListItem));
  }
}
