import { Guid, SgUserType, SubscriptionLengthType, SubscriptionType } from './common-types';
import { ClientSubscription, ClientSubscriptionItem, getDefultClientSubscription, toClientSubscription, toClientSubscriptionItem } from './ClientSubscription';
import { PTableColumn } from './common-ux-types';

export interface SGUser {
  id?: Guid; // guid
  //  clientId: Guid;
  clientSubscription: ClientSubscription;
  email: string;
  first: string;
  isFirebaseUser: boolean;
  isActive: boolean;
  last: string;
  phone: string;
  userType: SgUserType;
}

export interface SgUserListItem extends SGUser { // RC Users
  //  clientName?: string;
  // displayName: string;
  clientSubscriptionItem: ClientSubscriptionItem;
  subscriptionType: SubscriptionType;
  subscriptionLengthType: SubscriptionLengthType;
  subStartDate: string;
  subEndDate: string;
  //   customerName?: string;
  //   propertyName?: string;
}


export function toSGUser(item: SgUserListItem): SGUser {
  return {
    id: item.id ?? null,
    // clientId: item.clientId,
    clientSubscription: item.clientSubscriptionItem ? toClientSubscription(item.clientSubscriptionItem) : null,
    email: item.email,
    first: item.first,
    last: item.last,
    isActive: item.isActive,
    isFirebaseUser: item.isFirebaseUser,
    phone: item.phone,
    userType: item.userType,
  };
}

export function toSGUserListItem(item: SGUser): SgUserListItem {

  const clientSubscription = item.clientSubscription ? toClientSubscriptionItem(item.clientSubscription) : null;

  return {
    ...item,
    clientSubscriptionItem: clientSubscription,
    subscriptionType: clientSubscription?.subscriptionType ? clientSubscription?.subscriptionType : null,
    subscriptionLengthType: clientSubscription?.subscriptionLengthType ? clientSubscription?.subscriptionLengthType : null,
    subStartDate: clientSubscription?.startDatePayload ? clientSubscription?.startDatePayload.sortDateStr : null,
    subEndDate: clientSubscription?.endDatePayload ? clientSubscription?.endDatePayload.sortDateStr : null
  };
}


// data-label="subscriptionType">{{row.clientSubscriptionItem?.subscriptionType}}</mat-cell>
// </ng-container>
// <ng-container matColumnDef="subscriptionLengthType">
//   <mat-header-cell *matHeaderCellDef
//                    mat-sort-header>Length</mat-header-cell>
//   <mat-cell *matCellDef="let row"
//             data-label="subscriptionLengthType">{{row.clientSubscriptionItem?.subscriptionLengthType}}</mat-cell>
// </ng-container>
// <ng-container matColumnDef="startDate">
//   <mat-header-cell *matHeaderCellDef
//                    mat-sort-header> startDate </mat-header-cell>
//   <mat-cell *matCellDef="let row"
//             data-label="startDate">{{row.clientSubscriptionItem?.startDatePayload.dateStr}}</mat-cell>
// </ng-container>
// <ng-container matColumnDef="endDate">
//   <mat-header-cell *matHeaderCellDef
//                    mat-sort-header> endDate </mat-header-cell>
//   <mat-cell *matCellDef="let row"
//             data-label="endDate">{{row.clientSubscriptionItem?.endDatePayload.dateStr}}</mat-cell>

export const sgUserListDisplayColumns: PTableColumn[] = [
  { field: 'first', header: 'First', width: '5%' },
  { field: 'last', header: 'Last', width: '5%' },
  { field: 'email', header: 'Email', width: '10%' },
  { field: 'phone', header: 'Phone', width: '8%' },
  { field: 'userType', header: 'UserType', width: '6%' },
  { field: 'subscriptionType', header: 'SubType', width: '6%' },
  { field: 'subscriptionLengthType', header: 'SubLen', width: '6%' },
  { field: 'subStartDate', header: 'Start', width: '5%' },
  { field: 'subEndDate', header: 'End', width: '5%' },
  { field: 'isActive', header: 'Active', width: '6%' },
  { field: 'isFirebaseUser', header: 'FbUser', width: '6%' },
  { field: 'actions', header: 'Actions', width: '12%' },
];
// ['first', 'last', 'email', 'phone', 'userType',
//   'subscriptionType',
//   'subscriptionLengthType', 'startDate', 'endDate', 'isActive', 'isFirebaseUser', 'actions'];

export const defaultSGUser: SGUser =
{
  userType: null, first: '', last: '', email: '', phone: '', // clientId: null,
  clientSubscription: null, isFirebaseUser: false, isActive: true,
};
export const dummySgUser: SGUser =
{
  ...defaultSGUser,
  userType: SgUserType.SG_CLIENT, first: 'Billy', last: 'Bob', email: 'billy@bob.com', phone: '360-679-4441', clientSubscription: { ...getDefultClientSubscription() },

};
//  export const dummyTechSgUser: SgUserListItem =
//  {
//      ...defaultSGUser,
//      userType: SgUserType.SG_CLIENT_TECHNICIAN, first: 'Ray', last: 'Technician', displayName: 'Ray Technician', email: 'hisrodshop@outlook.com', phone: '360-679-4441',

//  };
const adminSgUser: SGUser =
{
  ...defaultSGUser,
  userType: SgUserType.SG_ADMIN, first: 'admin', last: 'agpicks', email: 'admin@projastic.com', phone: '360-679-4441', isFirebaseUser: true
  // clientId: AG_SPORTS_BET_CLIENT_ID,
};

const agpicksUserList: SGUser[] = [
  {
    ...defaultSGUser, userType: SgUserType.SG_SUPERUSER, first: 'Super', last: 'User', // clientId: AG_SPORTS_BET_CLIENT_ID,
    email: 'su@projastic.com', phone: '360-679-1231', isFirebaseUser: true
  },
  { ...adminSgUser }
];

export const seedSGUserList: SGUser[] = [
  ...agpicksUserList
];

export function isSgAdminUser(sgUserType: SgUserType) {
  return sgUserType === SgUserType.SG_ADMIN || sgUserType === SgUserType.SG_SUPERUSER;
}
// export function hasAdminPrivileges(sgUserType: SgUserType) {
//   return sgUserType === SgUserType.SG_ADMIN || sgUserType === SgUserType.SUPER_USER;
// }
// // export function hasPropMmgmtPrivileges(sgUserType: SgUserType) {
// //   return sgUserType === SgUserType.SG_CLIENT_PM || sgUserType === SgUserType.SG_ADMIN || sgUserType === SgUserType.SUPER_USER;
// // }
// export function isSuperUser(sgUserType: SgUserType) {
//   return sgUserType === SgUserType.SUPER_USER;
// }
// // export function isTechnicianUser(sgUserType: SgUserType) {
// //   return sgUserType === SgUserType.SG_CLIENT_TECHNICIAN;
// // }
// export function isClientUserType(sgUserType: SgUserType): boolean {
//   return sgUserType !== SgUserType.SUPER_USER &&
//     sgUserType !== SgUserType.SG_ADMIN;
// }
// export function isClientCustomerUser(sgUserType: SgUserType): boolean {
//   return sgUserType === SgUserType.SG_CLIENT_CUSTOMER;
// }
// export interface SGUserResponse { // RC Users
//   _id?: Guid; // guid
//   facilities?: string[];
//   customerId?: Guid;
//   displayName: string;
//   email: string;
//   first: string;
//   last: string;
//   password?: string;
//   phone: string;
//   userType: SgUserType; // UserType
//   // isQaUser: boolean;
//   // parentOrganizationId?: number;
//   // address?: string;
//   // city?: string;
//   // state?: string;
//   // zip?: string;
// }
