import { createSelector } from '@ngrx/store';
import { AppState } from 'app/core/core.state';
import { SgEditingStatus } from 'app/core/models/common-types';
import { ClientListState } from '../state/client.state.interface';

export const clientListStateSelector = (state: AppState): ClientListState =>
  state.clientListState;

export const clientsSelector = createSelector(
  clientListStateSelector,
  (state: ClientListState) => state.clients? state.clients : []
  // (state: RcAdminState) => state.customers? state.customers.sort((a, b) => a.name.localeCompare(b.name)) : []
);
export const selectedClientSelector = createSelector(
  clientListStateSelector,
  (state: ClientListState) => state.selectedClient ?? undefined
);

export const isClientSelectedSelector = createSelector(
  clientListStateSelector,
  (state: ClientListState) => state.selectedClient ? true : false
  );

// export const loadedSgUserClientSelector = createSelector(
//   clientListStateSelector,
//   (state: ClientListState) => state.loadedSgUserClient ? state.loadedSgUserClient : undefined
// );

export const clientEditingStatusSelector = createSelector(
  clientListStateSelector,
  (state: ClientListState) => state.clientEditingStatus ? state.clientEditingStatus : SgEditingStatus.NOT_EDITING
  );

