import { SgEditingStatus } from 'app/core/models/common-types';
import { SportBetListItem } from 'app/core/models/SportBet';

export interface SportBetState {
  editingStatus: SgEditingStatus;
  isLoaded:boolean;
  selectedSportBetListItem: SportBetListItem;
  sportBetListItems: SportBetListItem[];
}

export const INITIAL_SPORT_BET_STATE: SportBetState = {
  editingStatus: SgEditingStatus.NOT_EDITING,
  isLoaded: false,
  selectedSportBetListItem: undefined,
  sportBetListItems: [],
};

