import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { SportBetStatusType } from 'app/core/models/common-types';


@Pipe({
  name: 'betStatusDisplay'
})
export class BetStatusDisplayPipe implements PipeTransform {

  transform(status: SportBetStatusType): string {
    if (status === SportBetStatusType.WINNER) {
      return '(W)';
    } else if (status === SportBetStatusType.LOSER) {
      return '(L)';
    }
    return status;
  }
}
@NgModule({
  declarations: [BetStatusDisplayPipe],
  exports: [BetStatusDisplayPipe],
})
export class BetStatusDisplayPipeModule { }
