
import { Injectable } from '@angular/core';
import { WagerSource } from 'app/core/models/WagerSource';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { FirestoreCollectionType, Guid } from 'app/core/models/common-types';
import { Observable, from } from 'rxjs';
import { UiStateService } from 'app/core/store/ui/state-service';

@Injectable({ providedIn: 'root' })
export class WagerSourceService {
  constructor(
    private db: AngularFirestore,
    private uiStateService: UiStateService) { }

  loadWagerSources(sortField: string, directionStr: firebase.firestore.OrderByDirection) {
    this.uiStateService.startLoading();
    return this.db
      .collection<WagerSource>(FirestoreCollectionType.SB_WAGER_SOURCES, x => x.orderBy(sortField, directionStr))
      .snapshotChanges();
    // or .snapshotChanges()
  }

  loadWagerSource(wagerSourceId: Guid) {
    this.uiStateService.startLoading();
    return this.db
      .doc(`${FirestoreCollectionType.SB_WAGER_SOURCES}/${wagerSourceId}`)
      // you can use either:
      .valueChanges();
    // or .snapshotChanges()
  }

  addWagerSource(wagerSource: WagerSource): Observable<DocumentReference<any>> {
    return from(this.db.collection(FirestoreCollectionType.SB_WAGER_SOURCES).add(wagerSource));
  }


  deleteWagerSource(wagerSourceId: string) {
    const collection = `${FirestoreCollectionType.SB_WAGER_SOURCES}`;
    return from(this.db.collection(collection).doc(wagerSourceId).delete());
  }

  updateWagerSource(wagerSource: WagerSource) {
    const collection = `${FirestoreCollectionType.SB_WAGER_SOURCES}`;
    const documentId = wagerSource.id;
    return from(this.db.collection(collection).doc(documentId).set(wagerSource));
  }

}
