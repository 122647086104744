import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GetOddsPipeModule } from './pipes/odds/odds.pipe';
import { BetStatusColorPipeModule } from './pipes/bet-status/bet-status-color.pipe';
import { GamePlanStatusColorPipeModule } from './pipes/game-plan-status/game-plan-status.pipe';
import { BetStatusDisplayPipeModule } from './pipes/bet-status/bet-status-display.pipe';
import { SportBetRatingPipeModule } from './pipes/rating/rating.pipe';
import { PrimeNgModule } from '../core/primeng.module';
// import { WorkOrderEventDialogComponent } from './components/work-order-event-dialog/work-order-event-dialog.component';
// import { VehicleModalComponent } from './components/vehicle-modal/vehicle-modal.component';
// import { ProjectFormComponent } from './components/project-form/project-form.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    // PdfViewerModule,
    PrimeNgModule,
    ReactiveFormsModule,
    GetOddsPipeModule,
    SportBetRatingPipeModule,
    BetStatusColorPipeModule,
    BetStatusDisplayPipeModule,
    GamePlanStatusColorPipeModule
    // FontAwesomeModule
  ],
  declarations: [
    // BigInputComponent,
    // BigInputActionComponent,
    //  EditProjectEventComponent,
    //  ReportViewModalComponent,
    // VehicleModalComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    //  PdfViewerModule,
    ReactiveFormsModule,
    PrimeNgModule,
    GetOddsPipeModule,
    SportBetRatingPipeModule,
    BetStatusColorPipeModule,
    BetStatusDisplayPipeModule,
    GamePlanStatusColorPipeModule
    // ReportViewModalComponent,
    //  EditTouchPointProjectComponent
    // AddEditRecsComponent,
    // AddEditServicesComponent,
    // FontAwesomeModule,
    // BigInputComponent,
    // BigInputActionComponent
  ]
})
export class SharedModule {
}
