import { SgEditingStatus } from 'app/core/models/common-types';
import { GamePlanListItem } from 'app/core/models/GamePlan';

export interface GamePlanState {
  gamePlanEditStatus: SgEditingStatus;
  sportBetEditingStatus: SgEditingStatus;
  selectedGamePlanListItem: GamePlanListItem;
  // reportGamePlanListItem: GamePlanListItem;
  gamePlanListItems: GamePlanListItem[];
}

export const INITIAL_GAME_PLAN_STATE: GamePlanState = {
  gamePlanEditStatus: SgEditingStatus.NOT_EDITING,
  sportBetEditingStatus: SgEditingStatus.NOT_EDITING,
  selectedGamePlanListItem: null,
  gamePlanListItems: [],
};
