/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import { AppServiceApiConfig } from 'app/app-api-config';
import { SgEmailRequest } from 'app/core/models/SgEmailRequest';
import { UiStateService } from 'app/core/store/ui/state-service';
// @Injectable({
//   providedIn: 'root'
// })
@Injectable()
export class EmailService {

  constructor(private http:HttpClient,
    private uiStateService: UiStateService) { }

  sendMessage(rcEmailRequest: SgEmailRequest){
    const headers = {
      headers : new HttpHeaders({
        'Content-Type' :'application/json'
      })
    }
    this.uiStateService.startLoading();
   // 'https://us-central1-service-gambit.cloudfunctions.net/sendMail'
  //  console.log('MS sendMessage AppServiceApiConfig.email = ', AppServiceApiConfig.email);
  //  console.log('MS sendMessage rcEmailRequest = ', rcEmailRequest);

   
    return this.http.post<any>(AppServiceApiConfig.email, rcEmailRequest,headers);
    
  }
}
