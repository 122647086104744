
export const RESET_ON_USER_LOGOUT = '[Game Plan] Reset on User Logout';
export const RESET_ON_ADMIN_ACTIVE = '[Game Plan] Reset on Admin Active';

export const SET_SELECTED_GAME_PLAN_LIST_ITEM = '[Game Plan] Set Selected Game Plan';
export const SET_GAME_PLAN_EDITING_STATUS = '[Game Plan] Set Editing Game Plan';
export const SET_GAME_PLAN_SPORT_BET_EDITING_STATUS = '[Game Plan] Set Editing Game Plan Sport Bet';

export const LOAD_GAME_PLANS = '[Game Plan] Load Game Plans';
export const LOAD_GAME_PLANS_FAIL = '[Game Plan] Load Game Plans Fail';
export const LOAD_GAME_PLANS_SUCCESS = '[Game Plan] Load Game Plans Success';
export const ADD_NEW_GAME_PLAN = '[Game Plan] Add New Game Plan';
export const ADD_NEW_GAME_PLAN_FAIL = '[Game Plan] Add New Game Plan Fail';
export const ADD_NEW_GAME_PLAN_SUCCESS = '[Game Plan] Add New Game Plan Success';
export const DELETE_GAME_PLAN = '[Game Plan] Delete Game Plan Document';
export const DELETE_GAME_PLAN_FAIL = '[Game Plan] Delete Game Plan Document Fail';
export const DELETE_GAME_PLAN_SUCCESS = '[Game Plan] Delete Game Plan Document Success';
export const UPDATE_GAME_PLAN = '[Game Plan] Update Game Plan Document';
export const UPDATE_GAME_PLAN_FAIL = '[Game Plan] Update Game Plan Document Fail';
export const UPDATE_GAME_PLAN_SUCCESS = '[Game Plan] Update Game Plan Document Success';
