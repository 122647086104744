import {
  ActionReducerMap,
  MetaReducer,
  createFeatureSelector
} from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { environment } from '../../environments/environment';
import { initStateFromLocalStorage } from './meta-reducers/init-state-from-local-storage.reducer';
import { debug } from './meta-reducers/debug.reducer';
import { RouterStateUrl } from './router/router.state';
import { settingsReducer } from './settings/settings.reducer';
import { SettingsState } from './settings/settings.model';
import { authReducer } from 'app/auth/store/reducers/auth.reducer';
import { RcAuthState } from 'app/auth/store/state/auth.state.interface';
import { uiReducer } from './store/ui/reducers';
import { UiState } from './store/ui/state/ui.state.interface';
import { GamePlanState } from 'app/features/game-plan/store/state/game-plan.state.interface';
import { clientListReducer } from './store/clients/reducers';
import { ClientListState } from './store/clients/state/client.state.interface';
import { wagerSourceReducer } from './store/wager-source/reducers';
import { WagerSourcesState } from './store/wager-source/state/wager-source.state.interface';
import { sportBetReducer } from './store/sport-bets/reducers';
import { SportBetState } from './store/sport-bets/state/sport-bets.state.interface';
import { gamePlanReducer } from 'app/features/game-plan/store/reducers';

export const reducers: ActionReducerMap<AppState> = {
  uiState: uiReducer,
  settingsState: settingsReducer,
  router: routerReducer,
  authState: authReducer,
   gamePlanState: gamePlanReducer,
  clientListState: clientListReducer,
  sportBetState: sportBetReducer,
  wagerSourceState: wagerSourceReducer
};

export const metaReducers: MetaReducer<AppState>[] = [
  initStateFromLocalStorage
];

if (!environment.production) {
  if (!environment.test) {
    metaReducers.unshift(debug);
  }
}

export const selectAuthState = createFeatureSelector<AppState, RcAuthState>(
  'authState'
);

export const selectSettingsState = createFeatureSelector<
  AppState,
  SettingsState
>('settingsState');

export const selectRouterState = createFeatureSelector<
  AppState,
  RouterReducerState<RouterStateUrl>
>('router');

export interface AppState {
  authState: RcAuthState;
  clientListState: ClientListState;
  settingsState: SettingsState;
  router: RouterReducerState<RouterStateUrl>;
  // reportOptionsState: ReportOptionsState;
  // reportDocumentState: ReportDocumentState;
   gamePlanState: GamePlanState;
  sportBetState: SportBetState;
  // projectState: ProjectState;
  uiState: UiState;
  wagerSourceState: WagerSourcesState;
}
