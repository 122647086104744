

export const SPORT_BET_SET_SELECTED_BET = '[Sport Bet ] Set Selected SportBet';
export const SPORT_BET_SET_SELECTED_BET_EDIT = '[Sport Bet ] Set Selected SportBet Editing Status' ;

export const SPORT_BET_LOAD_BETS = '[Sport Bet ] Load Bets';
export const SPORT_BET_LOAD_BETS_FAIL = '[Sport Bet ] Load Bets Fail';
export const SPORT_BET_LOAD_BETS_SUCCESS = '[Sport Bet ] Load Bets Success';

export const SPORT_BET_ADD_BET = '[Sport Bet ] Add New SportBet';
export const SPORT_BET_ADD_BET_FAIL = '[Sport Bet ] Add New SportBet Fail';
export const SPORT_BET_ADD_BET_SUCCESS = '[Sport Bet ] Add New SportBet Success';
export const SPORT_BET_UPDATE_BET = '[Sport Bet ] Update SportBet Document';
export const SPORT_BET_UPDATE_BET_FAIL = '[Sport Bet ] Update SportBet Document Fail';
export const SPORT_BET_UPDATE_BET_SUCCESS = '[Sport Bet ] Update SportBet Document Success';
export const SPORT_BET_DELETE_BET = '[Sport Bet ] Delete SportBet Document';
export const SPORT_BET_DELETE_BET_FAIL = '[Sport Bet ] Delete SportBet Document Fail';
export const SPORT_BET_DELETE_BET_SUCCESS = '[Sport Bet ] Delete SportBet Document Success';

export const SPORT_BET_DELETE_ALL_BETS = '[Sport Bet ] Delete All SportBet Documents for Client';
