export const LOAD_CLIENT_FOR_USER = '[Client List] Load Client for User';
export const LOAD_CLIENT_FOR_USER_FAIL = '[Client List] Load Client for User Fail';
export const LOAD_CLIENT_FOR_USER_SUCCESS = '[Client List] Load Client for User Success';

export const CLIENT_LIST_LOAD_CLIENTS = '[Client List] Load Clients';
export const CLIENT_LIST_LOAD_CLIENTS_FAIL = '[Client List] Load Clients Fail';
export const CLIENT_LIST_LOAD_CLIENTS_SUCCESS = '[Client List] Load Clients Success';

export const CLIENT_LIST_ADD_CLIENT = '[Client List] Add New Client';
export const CLIENT_LIST_ADD_CLIENT_FAIL = '[Client List] Add New Client Fail';
export const CLIENT_LIST_ADD_CLIENT_SUCCESS = '[Client List] Add New Client Success';

export const CLIENT_LIST_ADD_MULTIPLE_CLIENTS = '[Client List] Add New Clients';
export const CLIENT_LIST_ADD_MULTIPLE_CLIENTS_FAIL = '[Client List] Add New Clients Fail';
export const CLIENT_LIST_ADD_MULTIPLE_CLIENTS_SUCCESS = '[Client List] Add New Clients Success';

export const CLIENT_LIST_UPDATE_CLIENT = '[Client List] Update Client Document';
export const CLIENT_LIST_UPDATE_CLIENT_FAIL = '[Client List] Update Client Document Fail';
export const CLIENT_LIST_UPDATE_CLIENT_SUCCESS = '[Client List] Update Client Document Success';
export const CLIENT_LIST_DELETE_CLIENT = '[Client List] Delete Client Document';
export const CLIENT_LIST_DELETE_CLIENT_FAIL = '[Client List] Delete Client Document Fail';
export const CLIENT_LIST_DELETE_CLIENT_SUCCESS = '[Client List] Delete Client Document Success';

export const CLIENT_LIST_SET_SELECTED_CLIENT = '[Client List] Set Selected Client';
export const SET_CLIENT_EDITING_STATUS = '[Client List] Set Selected Client Editing Status' ;
