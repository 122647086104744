import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FirebaseUser } from 'app/auth/models/auth-data.model';
import { AppState } from 'app/core/core.state';
import { SgEmailRequest } from 'app/core/models/SgEmailRequest';
import { SGUser, SgUserListItem } from 'app/core/models/SgUser';
import { RCSettings } from 'app/core/models/Settings';
import { LoadSgUsers, SetSgUserAuthenticated, SetSgUserUnAuthenticated, AddSgUser, DeleteSgUser, UpdateSgUser, EmailNewClientContact, SendEmailToCustomer, SetFirebaseUserAuthenticated, SetFirebaseUserUnAuthenticated, EmailCustomerServiceRequest, UpdateRcSettings, LoadRcSettings, LoadRcSettingsSuccess, LoadSgUserForAuth, SetSgUserEditingStatus } from '../actions';
import { sgUserListItemsSelector, loggedInSGUserSelector, isSgUserAuthenticatedSelector, isSgAdminUserSelector, isRecaptchaTokenAuthenticatedSelector, isFirebaseUserAuthenticatedSelector, fireBaseUserSelector, rcSettingsSelector, isSgClientUserSelector, isSgSuperUserSelector, sgUserEditingStatusSelector, isDadoUserSelector } from '../selectors';
import { Guid, SgEditingStatus } from 'app/core/models/common-types';
import { Client } from 'app/core/models/Client';

@Injectable()
export class AuthStateService {
  isFirebaseUserAuthenticated$ = this.store.select(isFirebaseUserAuthenticatedSelector);
  fireBaseUser$ = this.store.select(fireBaseUserSelector);
  sgUserListItems$ = this.store.select(sgUserListItemsSelector);
  loggedInSGUser$ = this.store.select(loggedInSGUserSelector);
  isDadoUser$ = this.store.select(isDadoUserSelector);
  isSgUserAuthenticated$ = this.store.select(isSgUserAuthenticatedSelector);
  isRecaptchaTokenAuthenticated$ = this.store.select(isRecaptchaTokenAuthenticatedSelector);
  rcSettings$ = this.store.select(rcSettingsSelector);
  isSgAdminUser$ = this.store.select(isSgAdminUserSelector);
  isSgSuperUser$ = this.store.select(isSgSuperUserSelector);
  isSgClientUser$ = this.store.select(isSgClientUserSelector);
  sgUserEditingStatus$ = this.store.select(sgUserEditingStatusSelector);

  constructor(private store: Store<AppState>) { }

  setFirebaseUserAuthenticated(firebaseUser: FirebaseUser) {
    this.store.dispatch(new SetFirebaseUserAuthenticated(firebaseUser));
    this.store.dispatch(new LoadSgUserForAuth(firebaseUser));
  }
  loadUsers() {
    this.store.dispatch(new LoadSgUsers());
  }
  reloadUsers() {
    this.store.dispatch(new LoadSgUsers());
  }

  setFirebaseUserUnAuthenticated() {
    this.store.dispatch(new SetFirebaseUserUnAuthenticated());
  }

  setSgUserAuthenticated(sGUserListItem: SgUserListItem) {
    this.store.dispatch(new SetSgUserAuthenticated(sGUserListItem));
  }

  setSgUserUnAuthenticated() {
    this.store.dispatch(new SetSgUserUnAuthenticated());
  }

  setSgUserEditingStatus(editingStatus: SgEditingStatus) {
    this.store.dispatch(new SetSgUserEditingStatus(editingStatus));
  }

  addSgUser(sgUser: SGUser) {
    this.store.dispatch(new AddSgUser(sgUser));
  }

  // deleteMultipleSgUsers(guids: Guid[]) {
  //   this.store.dispatch(new DeleteMultipleSgUsers(guids));
  // }
  deleteSgUser(sgUserId: Guid) {
    this.store.dispatch(new DeleteSgUser(sgUserId));
  }

  updateSgUser(sgUser: SGUser) {
    this.store.dispatch(new UpdateSgUser(sgUser));
  }

  loadRcSettings() {
    this.store.dispatch(new LoadRcSettings());
  }

  loadRcSettingsSuccess(rcSettings: RCSettings) {
    this.store.dispatch(new LoadRcSettingsSuccess(rcSettings));
  }

  updateRcSettings(rCSettings: RCSettings) {
    this.store.dispatch(new UpdateRcSettings(rCSettings));
  }

  emailCustomerContactInfo(rcEmailRequest: SgEmailRequest, newClient: Client) {
    this.store.dispatch(new EmailNewClientContact({ rcEmailRequest, newClient }));
  }

  emailCustomerServiceRequest(rcEmailRequest: SgEmailRequest) {
    this.store.dispatch(new EmailCustomerServiceRequest(rcEmailRequest));
  }

  sendEmailToCustomer(rcEmailRequest: SgEmailRequest) {
    this.store.dispatch(new SendEmailToCustomer(rcEmailRequest));
  }

  // sendRecaptchaTokenForVerification(token: string) {
  //   this.store.dispatch(new SendRecaptchaToken(token));
  // }

}
