import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UiService } from '../../core/services/ui/ui.service';
import { UiStateService } from '../../core/store/ui/state-service';
import { AuthStateService } from '../store/state-service/auth.state-service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthData, FirebaseUser } from '../models/auth-data.model';
import { seedSGUserList, SGUser } from 'app/core/models/SgUser';
import { FirestoreCollectionType, Guid } from 'app/core/models/common-types';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import firebase from 'firebase/compat/app';
import { RCSettings } from 'app/core/models/Settings';
// import { GamePlanStateService } from 'app/features/game-plan/store/state-service';
@Injectable()
export class AuthService {

  addedUserEmail = '';
  origAuthData: AuthData;
  currentUser: FirebaseUser;
  authWorkerApp: firebase.app.App;
  authWorkerAuth: firebase.auth.Auth;
  isAuthWorkerAppInitialized = false;

  //  private fbSubs: Subscription[] = [];
  constructor(
    private db: AngularFirestore,
    private router: Router,
    private afAuth: AngularFireAuth,
    private uiService: UiService,
    private authStateService: AuthStateService,
    private uiStateService: UiStateService,
    // private gamePlanStateService: GamePlanStateService
  ) { }

  // loadSgUsers(): Observable<DocumentChangeAction<SGUser>[]> {
  //   this.uiStateService.startLoading();
  //   const clRef = this.db.collection<SGUser>(FirestoreCollectionType.SG_USERS);
  //   console.log('MS loadSgUsers clRef= ', clRef);
  //   const clRefSnapshot =  clRef.snapshotChanges();
  //   console.log('MS loadSgUsers clRefSnapshot= ', clRefSnapshot);
  //   return clRefSnapshot;
  // }

  //   loadSgUsers2() {
  //     this.uiStateService.startLoading();
  //      const usersCollection = this.db.collection<SGUser>(FirestoreCollectionType.SG_USERS, ref => ref.orderBy('last', 'asc'));
  //      const retVal = usersCollection.snapshotChanges();
  //     // console.log('MS loadSgUsers after snapshotChanges');
  //      return retVal;
  //    // or .valueChanges() does not have the id
  //  }

  getAllSgUsers() {
    this.uiStateService.startLoading();
    const docRef = this.db.collection<SGUser>(FirestoreCollectionType.SG_USERS, ref => ref.orderBy('last', 'asc'));
    return docRef.get();
  }

  loadSgUserForAuthentication(userEmail: string) {
    this.uiStateService.startLoading();
    const docRef = this.db.collection<SGUser>(FirestoreCollectionType.SG_USERS, x => x.where('email', '==', userEmail));
    return docRef.get();
  }

  seedUsers() {
    seedSGUserList.map(user => {
      console.log('MS seedUsers user', user);
      this.authStateService.addSgUser(user);
    });
  }

  // loadSgUsers2(sortField: string, directionStr: firebase.firestore.OrderByDirection, isReload: boolean) {
  //   this.uiStateService.startLoading();
  //  //  this.fbSubs.push(
  //     const subscription = this.db
  //       .collection<SGUser>(FirestoreCollectionType.SG_USERS, x => x.orderBy(sortField, directionStr))
  //       //  .collection<SGUser>(FirestoreCollectionType.SG_USERS)
  //       .snapshotChanges().pipe(
  //         map(docArray =>
  //           docArray.map(a => {
  //             const data = a.payload.doc.data() as SGUser;
  //             const id = a.payload.doc['id'];
  //             return {
  //               ...data,
  //               id
  //             };
  //           })
  //         ))
  //       .subscribe(
  //         (sgUsers: SGUser[]) => {
  //           this.uiStateService.stopLoading();
  //          // console.log('MS loadSgUsers sgUsers = ', sgUsers);
  //           this.authStateService.loadSgUsersSuccess(sgUsers, isReload);
  //           subscription.unsubscribe();
  //         },
  //         error => {
  //           //  console.log('MS sgUsers error = ', error);
  //           this.uiStateService.stopLoading();
  //           this.uiService.showSnackbar(
  //             'Fetching SgUsers failed, please try again later',
  //             null,
  //             3000
  //           );
  //           subscription.unsubscribe();
  //         }
  //       )
  //  // );
  // }

  addSgUser(sgUser: SGUser) {
    return from(this.db.collection(FirestoreCollectionType.SG_USERS).add(this.toSgUserPayload(sgUser)));
  }

  deleteSgUser(sgUserId: Guid) {
    return from(this.db.collection(FirestoreCollectionType.SG_USERS).doc(sgUserId).delete());
  }


  // deleteMultipleSgUsers(documentIdList: string[]) {
  //   const batch = this.db.firestore.batch()
  //   documentIdList.forEach(id => {
  //     const transDoc = this.db.firestore.doc(`${FirestoreCollectionType.SG_USERS}/${id}`);
  //     batch.delete(transDoc);
  //   });
  //   return from(batch.commit());
  // }

  updateSgUser(sgUser: SGUser) {
    return from(this.db.collection(FirestoreCollectionType.SG_USERS).doc(sgUser.id)
      .set(this.toSgUserPayload(sgUser)));
  }

  updateRcSettings(rCSettings: RCSettings) {
    return from(this.db.collection(FirestoreCollectionType.SG_SETTINGS).doc(rCSettings.id)
      .set(this.toRcSettingsPayload(rCSettings)));
  }

  initAuthListener() {
    this.afAuth.authState.subscribe(fbuser => {
      console.log('MS initAuthListener this.addedUserEmail= ', this.addedUserEmail);
      console.log('MS initAuthListener firebaseUser = ', fbuser);
      this.currentUser = fbuser;
      if (fbuser) {
        console.log('MS 2 useruid = ', fbuser.uid);
        console.log('MS 3 user = ', fbuser.providerData);
        const firebaseUser: FirebaseUser = {
          ...fbuser.providerData[0]
        }
        console.log('MS firebaseUser = ', firebaseUser);
        // seed here
        // if (fbuser?.email === 'su@agsportsbet.com') {
        //   console.log('MS seedUsers');
        //   this.seedUsers();
        // }


        this.authStateService.setFirebaseUserAuthenticated(firebaseUser);
        //  this.router.navigate(['/game-plan-view']);
      } else {
        console.log('MS NOT firebaseUser = ');
        //  this.trainingService.cancelSubscriptions();
        this.uiStateService.stopLoading();
        //  this.cancelSubscriptions();
        // this.gamePlanService.cancelSubscriptions();
        this.authStateService.setFirebaseUserUnAuthenticated();
        this.authStateService.setSgUserUnAuthenticated();
        // this.gamePlanStateService.resetOnUserLogout();
        // this.customerStateService.resetOnUserLogout();
        // this.clientStateService.resetOnUserLogout();
        // this.store.dispatch(new Auth.SetFirebaseUserUnAuthenticated());

        // this.navigateToAbout();
        this.router.navigate(['/home']);
      }
      // // THIS IS FOR SEED THE USERS, PACKAGES and CUSTOMERS TABLEs
      // if (fbuser?.email === 'fred@agsportsbet.com') {
      //   console.log('MS seedUsers');
      //   this.seedAll();
      // }
    });
  }
  // seedAll() {
  //   console.log('MS seedUsers');
  //  // this.gamePlanService.seedUsers();
  //   //  console.log('MS seedCustomers');
  //   // this.gamePlanService.seedCustomers();
  //   //   console.log('MS seedServicePointPackages');
  //   // this.gamePlanService.seedServicePointPackages();
  // }

  registerUser(authData: AuthData, sgUser: SGUser) {
    if (!this.isAuthWorkerAppInitialized) {
      this.authWorkerApp = firebase.initializeApp(firebase.app().options, 'auth-worker');
      this.authWorkerAuth = firebase.auth(this.authWorkerApp);
      this.authWorkerAuth.setPersistence(firebase.auth.Auth.Persistence.NONE); // disables caching of account credentials
      this.isAuthWorkerAppInitialized = true;
    }

    // console.log('MS 1 registerUser sgUser= ', sgUser);
    // console.log('MS 1 registerUser authData= ', authData);
    this.uiStateService.startLoading();
    // this.store.dispatch(new UI.StartLoading());
    this.addedUserEmail = authData.email;
    //   console.log('MS 1 this.addedUserEmail= ', this.addedUserEmail);
    this.authWorkerAuth.createUserWithEmailAndPassword(authData.email, authData.password)
      .then((userCredential) => {
        // this.uiService.loadingStateChanged.next(false);
        // this.store.dispatch(new UI.StopLoading());
        const user = userCredential.user;
        //  console.log('MS 2 registerUser user = ', user);
        user.sendEmailVerification();

        this.authStateService.updateSgUser({
          ...sgUser,
          isFirebaseUser: true
        });

        // this.updateSgUser({
        //   ...sgUser,
        //   isFirebaseUser: true
        // })
        // this.afAuth.updateCurrentUser (this.currentUser )
        // this.signOut();
        //  console.log('MS 2 registerUser this.origAuthData = ', this.origAuthData);
        // this.login({
        //   email: loggedInUser.email
        //   password: string;
        //  /// recaptchaToken : string;
        // });
        // this.sendEmailVerification();
        this.uiStateService.stopLoading();
      })
      .catch(error => {
        // this.uiService.loadingStateChanged.next(false);
        //  console.log('MS 3 registerUser firebaseUser = ', error);
        alert(error.message);
        this.uiStateService.stopLoading();
        this.uiService.showSnackbar(error.message, null, 3000);
      });
  }
  async sendEmailVerification() {
    console.log('MS sendEmailVerification this.afAuth.currentUser = ', this.afAuth.currentUser);
    (await this.afAuth.currentUser).sendEmailVerification().then(function () {
      // Email Verification sent!
      console.log('MS sendEmailVerification Email Verification sent!');
      alert('Email Verification Sent! ');
    });
  }

  signOut() {
    this.afAuth.signOut().then(function () {
      console.log('MS signOut= ');
      // alert('User signed out!');
    }).catch(function (error) {
      alert(error.message);
      // Handle Errors here.
      // const errorCode = error.code;
      // const errorMessage = error.message;
      // if (errorCode === 'auth/invalid-email') {
      //   alert(errorMessage);
      // } else if (errorCode === 'auth/user-not-found') {
      //   alert(errorMessage);
      // }
      console.log(error);
    });
  }


  sendPasswordReset(email: string) {
    //  var email = document.getElementById('email').value;
    this.afAuth.sendPasswordResetEmail(email).then(function () {
      // Password Reset Email Sent!
      alert(`Password Reset Email Sent to ${email} !`);
    }).catch(function (error) {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      if (errorCode === 'auth/invalid-email') {
        alert(errorMessage);
      } else if (errorCode === 'auth/user-not-found') {
        alert(errorMessage);
      }
      console.log(error);
    });
  }

  // addFirebaseUser(sgUser: SGUser) {
  //   console.log('MS 1 addFirebaseUser sgUser= ', sgUser);
  // this.afAuth.createUserWithEmailAndPassword(sgUser.email, sgUser.password)
  // .then((userCredential) => {
  //   // Signed in
  //   const user = userCredential.user;
  //   console.log('MS 2 addFirebaseUser user = ', user);
  //   // ...
  // })
  // .catch((error) => {
  //   console.log('MS 3 addFirebaseUser firebaseUser = ', error);
  //   // var errorCode = error.code;
  //   // var errorMessage = error.message;
  //   // // ..
  // });

  // }

  login(authData: AuthData) {
    // this.uiService.loadingStateChanged.next(true);
    // console.log('MS login origAuthData = ', this.origAuthData);
    //  console.log('MS login authData = ', authData);
    this.origAuthData = { ...authData };
    this.uiStateService.startLoading();
    this.afAuth
      .signInWithEmailAndPassword(authData.email, authData.password)
      .then(() => {
        // this.uiService.loadingStateChanged.next(false);
        this.uiStateService.stopLoading();
      })
      .catch(error => {
        console.log('MS error = ', error);
        // this.uiService.loadingStateChanged.next(false);
        this.uiStateService.stopLoading();
        //  this.uiService.showSnackbar(error.message, null, 5000);
      });
  }

  logout() {
    return this.afAuth.signOut().then(() => {
      //  console.log('MS logout');
      this.router.navigate(['/home']);
    })
  }

  loadRcSettings() {
    this.uiStateService.startLoading();
    // this one we will only call 1x
    // this.fbSubs.push(
    const subscription = this.db
      .collection<RCSettings>(FirestoreCollectionType.SG_SETTINGS)
      .snapshotChanges().pipe(
        map(docArray =>
          docArray.map(a => {
            const data = a.payload.doc.data() as RCSettings;
            const id = a.payload.doc['id'];
            return {
              ...data,
              id
            };
          })
        ))
      .subscribe(
        (settings: RCSettings[]) => {
          this.uiStateService.stopLoading();
          // for now there is only 1
          this.authStateService.loadRcSettingsSuccess(settings[0]);
          subscription.unsubscribe();
        },
        error => {
          console.log('MS loadRcSettings error = ', error);
          this.uiStateService.stopLoading();
          this.uiService.showSnackbar(
            'Fetching RC Settings failed',
            null,
            3000
          );
          subscription.unsubscribe();
        }
      )
    // );
  }

  // cancelSubscriptions() {
  //   //  console.log('MS AUTH cancelSubscriptions  ');
  //   this.fbSubs.forEach((sub) => sub.unsubscribe());
  // }
  // removes the id for the update
  toSgUserPayload(sgUser: SGUser): SGUser {
    // const newUser: SGUser =
    return {
      isActive: sgUser.isActive,
      email: sgUser.email,
      //  facilities: sgUser.facilities ? sgUser.facilities : null,
      first: sgUser.first,
      isFirebaseUser: sgUser.isFirebaseUser,
      last: sgUser.last,
      clientSubscription: sgUser.clientSubscription,
      // password: sgUser.password,
      phone: sgUser.phone,
      userType: sgUser.userType
    }
  }

  toRcSettingsPayload(rCSettings: RCSettings): RCSettings {
    return {
      isSignUpActive: rCSettings.isSignUpActive
    }
  }
  // logout3() {
  //  this.afAuth.signOut().then(() => {
  //     this.router.navigate(['sign-in']);
  //  )};

  //  // this.afAuth.signOut();
  // }
}
