import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { switchMap, catchError, map, tap, withLatestFrom } from 'rxjs/operators';
import { GamePlanService } from '../../service/game-plan.service';
import { DELETE_GAME_PLAN, UPDATE_GAME_PLAN, LOAD_GAME_PLANS, ADD_NEW_GAME_PLAN_SUCCESS, ADD_NEW_GAME_PLAN, SET_GAME_PLAN_EDITING_STATUS, SET_GAME_PLAN_SPORT_BET_EDITING_STATUS } from '../action-types';
import { AddGamePlanSuccess, DeleteGamePlan, DeleteGamePlanSuccess, DeleteGamePlanFail, UpdateGamePlan, UpdateGamePlanSuccess, UpdateGamePlanFail, LoadGamePlans, AddGamePlan, AddGamePlanFail, LoadGamePlansFail, LoadGamePlansSuccess, SetGamePlanEditingStatus, SetGamePlanSportBetEditingStatus } from '../actions';
import { Router } from '@angular/router';
import { UiStateService } from 'app/core/store/ui/state-service';
import { UiService } from 'app/core/services/ui/ui.service';
import { GamePlan, GamePlanListItem, toGamePlanListItem } from 'app/core/models/GamePlan';
import { SgEditingStatus } from 'app/core/models/common-types';
import { SportBetStateService } from 'app/core/store/sport-bets/state-service';
import { SportBetListItem } from 'app/core/models/SportBet';


//
// deleteProject$: Observable<Action> = createEffect(() => this.actions$.pipe(
//   ofType<DeleteProject>(DELETE_PROJECT),
//   withLatestFrom(
//     this.reportDocumentStateService.reportDocuments$,
//     this.serviceEventStateService.serviceEventListItems$,
//   ),
//   switchMap(([action, reportDocuments, serviceEventListItems]) => this.projectService

@Injectable()
export class GamePlanEffects {

  loadGamePlans$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<LoadGamePlans>(LOAD_GAME_PLANS),
    withLatestFrom(
      this.sportBetStateService.sportBetListItems$,
    ),
    switchMap(([action, sportBetListItems]) => this.gamePlanService
      .loadAllGamePlans(action.loadAll)
      .pipe(
        map((docArray) => {
          console.log('MS LOAD_GAME_PLANS docArray= ', docArray);
          console.log('MS LOAD_GAME_PLANS sportBetListItems= ', sportBetListItems);
          const gamePlanListItems: GamePlanListItem[] = docArray.map(a => {

            const gamePlan: GamePlan = {
              ...a.payload.doc.data() as GamePlan,
              id: a.payload.doc['id']
            }

            const sportBetListItemsFromIds: SportBetListItem[] = [];
            gamePlan.sportBetIds?.map(id => {
              const sb = sportBetListItems.find(item => item.id === id);
              if (sb) {
                sportBetListItemsFromIds.push(sb);
              }
            });

            return toGamePlanListItem(gamePlan, sportBetListItemsFromIds);
          });
          console.log('MS LOAD_GAME_PLANS gamePlanListItems= ', gamePlanListItems);

          this.uiStateService.stopLoading();
          return new LoadGamePlansSuccess(gamePlanListItems);
        }),
        catchError((error) => {
          this.uiStateService.stopLoading();
          if (error.code !== 'permission-denied') {
            this.uiService.showSnackbar(
              `Fetching Game Plans failed ${error.code}`,
              null,
              3000
            );
          }
          return of(new LoadGamePlansFail(error))
        })
      ))
  ));

  addGamePlan$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<AddGamePlan>(ADD_NEW_GAME_PLAN),
    switchMap((action) => this.gamePlanService
      .addGamePlan(action.payload)
      .pipe(
        map((res) => {
          const gamePlan = {
            ...action.payload,
            id: res.id
          }
          const gamePlanListItem = toGamePlanListItem(gamePlan, undefined);
          console.log('MS addGamePlan gamePlanListItem = ', gamePlanListItem);
          return new AddGamePlanSuccess(gamePlanListItem);
        }),
        catchError((error) => of(new AddGamePlanFail(error)))
      ))
  ));

  // @Effect({ dispatch: false })
  // addCustomerGamePlanRequestSuccess$ = createEffect(() => this.actions$.pipe(
  //   ofType<AddCustomerGamePlanRequestSuccess>(ADD_CUSTOMER_GAME_PLAN_REQUEST_SUCCESS),
  //   withLatestFrom(
  //     this.authStateService.loggedInSGUser$,
  //     this.projectStateService.projects$
  //   ),
  //   map(([action, sgUser, projects]: [
  //     AddCustomerGamePlanRequestSuccess,
  //     SGUser,
  //     Project[]
  //   ]) => {
  //     const project = projects.find(cf => cf.id === action.payload.projectId);
  //     const rcEmailRequest: SgEmailRequest = {
  //       // email: 'info@servicegambit.com',
  //       email: 'info@onewaywebapps.com',
  //       subject: 'New Customer Service Event Request - ' + action.payload.customerName,
  //       html: getAddCustomerGamePlanRequestInfo(action.payload, sgUser, project),
  //       // recaptchaToken: token
  //     }
  //     //   console.log('MS addCustomerGamePlanRequestSuccess rcEmailRequest = ', rcEmailRequest);
  //     this.authStateService.emailCustomerServiceRequest(rcEmailRequest);
  //   })
  // );
  // @Effect({ dispatch: false })
  // addCustomerGamePlanRequestSuccess2$ = createEffect(() => this.actions$.pipe(
  //   ofType<AddCustomerGamePlanRequestSuccess>(ADD_CUSTOMER_GAME_PLAN_REQUEST_SUCCESS),
  //   withLatestFrom(
  //     this.projectStateService.projects$),
  //   map((action) => {
  //     // this.gamePlanStateService.loadServicePointProjects();
  //     const rcEmailRequest: SgEmailRequest = {
  //       email: 'info@servicegambit.com',
  //       subject: 'New Customer Service Event Request - ' + action.payload.customerName,
  //       html: getAddCustomerGamePlanRequestInfo( action.payload),
  //       // recaptchaToken: token
  //     }
  //     this.authStateService.emailCustomerContactInfo(rcEmailRequest, newCustomer);
  //   })
  // );



  // @Effect({ dispatch: false })
  // loadGamePlans2$ = createEffect(() => this.actions$.pipe(
  //   ofType<LoadGamePlans>(LOAD_GAME_PLANS),
  //   withLatestFrom(
  //     this.customerStateService.selectedCustomer$,
  //     this.projectStateService.projects$,
  //     this.authStateService.loggedInSGUser$),
  //   map(([_, selectedCustomer, projects, sgUser]: [
  //     LoadGamePlans,
  //     Customer,
  //     Project[],
  //     SGUser
  //   ]) => {
  //     this.gamePlanService.loadAllGamePlans(selectedCustomer.clientId); // , projects, sgUser);
  //   })
  // );


  // @Effect({ dispatch: false })
  // loadServicePointProjectsOnGamePlansSuccess$ = createEffect(() => this.actions$.pipe(
  //   ofType<LoadGamePlansSuccess>(LOAD_GAME_PLANS_SUCCESS),
  //   map(() => {
  //     // this.gamePlanStateService.loadServicePointProjects();
  //     this.gamePlanStateService.loadTouchPointProjects();
  //   })
  // );

  //
  // deleteMultipleGamePlans$: Observable<Action> = createEffect(() => this.actions$.pipe(
  //   ofType<DeleteMultipleGamePlans>(DELETE_MULTIPLE_GAME_PLANS),
  //   switchMap((action) => this.gamePlanService
  //     .deleteMultipleGamePlans(action.payload)
  //     .pipe(
  //       map(() => new DeleteMultipleGamePlansSuccess()),
  //       catchError((error) => of(new DeleteGamePlanFail(error)))
  //     ))
  // );

  deleteGamePlan$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<DeleteGamePlan>(DELETE_GAME_PLAN),
    switchMap((action) => this.gamePlanService
      .deleteGamePlan(action.payload)
      .pipe(
        map(() => new DeleteGamePlanSuccess(action.payload)),
        catchError((error) => of(new DeleteGamePlanFail(error)))
      ))
  ));


  updateGamePlan$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<UpdateGamePlan>(UPDATE_GAME_PLAN),
    switchMap((action) => this.gamePlanService
      .updateGamePlan(action.payload)
      .pipe(
        map((res) =>
          //  console.log('MS res = ', res);
          //   const wagerEventResponse: MdbGamePlanResponse = toGamePlanResponse(action.payload);
          new UpdateGamePlanSuccess(action.payload)
          // new UpdateGamePlanSuccess(toGamePlanListItem(action.payload.id, action.payload, projects))
        ),
        catchError((error) => of(new UpdateGamePlanFail(error)))
      ))
  ));


  //
  // updateReportGamePlan$: Observable<Action> = createEffect(() => this.actions$.pipe(
  //   ofType<UpdateReportGamePlan>(UPDATE_REPORT_GAME_PLAN),
  //   withLatestFrom(
  //     this.projectStateService.projects$),
  //   switchMap(([action, projects]) => this.gamePlanService
  //     .updateGamePlan(action.payload)
  //     .pipe(
  //       map((res) =>
  //         //  console.log('MS res = ', res);
  //         //   const wagerEventResponse: MdbGamePlanResponse = toGamePlanResponse(action.payload);
  //         new UpdateReportGamePlanSuccess(toGamePlanListItem(action.payload.id, action.payload, projects))
  //       ),
  //       catchError((error) => of(new UpdateReportGamePlanFail(error)))
  //     ))
  // );

  setGamePlanEditingStatus$ = createEffect(() => this.actions$.pipe(
    ofType<SetGamePlanEditingStatus>(
      SET_GAME_PLAN_EDITING_STATUS),
    tap((action) => {
      console.log('MS setGamePlanEditingStatus action.payload= ', action.payload);
      this.uiStateService.setIsEditingEntity(action.payload !== SgEditingStatus.NOT_EDITING);
    })
    ),{ dispatch: false });

  addGamePlanSuccessMessage$ = createEffect(() => this.actions$.pipe(
    ofType<AddGamePlanSuccess>(ADD_NEW_GAME_PLAN_SUCCESS),
    tap(() => {

      this.router.navigate(['/game-plan-view']);
      // REMOVING THIS FOR NOW, the user can duplicate easy enough from the list
      // const modalRef = this.confirmationService.open({
      //   title: 'Service Event created!',
      //   description: 'You may Create another or Select Service Events Tab to View Service Events!',
      //   actions: ['Create Another', 'View Service Events'],
      // });
      // modalRef.cancel$.subscribe(() => {
      //   this.router.navigate(['/game-plan-view']);
      // });
    })
    ),{ dispatch: false });

  constructor(
    private sportBetStateService: SportBetStateService,
    private uiStateService: UiStateService,
    private router: Router,
    private uiService: UiService,
    private actions$: Actions,
    private gamePlanService: GamePlanService
  ) { }
}
