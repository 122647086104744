import { FirebaseUser } from 'app/auth/models/auth-data.model';
import { Client } from 'app/core/models/Client';
import { SgEmailRequest } from 'app/core/models/SgEmailRequest';
import { SGUser } from 'app/core/models/SgUser';

export function authenticateSgUser(sgUsers: SGUser[], firebaseUser: FirebaseUser): SGUser {
  let sgUser: SGUser;
  console.log('MS authenticateSgUser firebaseUser= ', firebaseUser);
  if (firebaseUser) {
    sgUser = sgUsers.find(user => user.email === firebaseUser.email);
  }
  console.log('MS authenticateSgUser sgUser= ', sgUser);
  return sgUser;
}

export function transformSgEmailRequest(rcEmailRequest: SgEmailRequest, newClient: Client): SgEmailRequest {
  return {
    email: newClient.email,
    subject: 'Thank You from AG Picks',
    html: `Thanks for contacting us, ${newClient.first} ${newClient.last}. <br><br>
          One of our account specialists will be in contact with you soon!<br><br>
          We look forward to speaking with you.<br>
          Best Regards,<br>
          AG Picks`,
    // recaptchaToken: rcEmailRequest.recaptchaToken
  };

}
// }

// sendThankYouToCustomer(newCustomer, token: string): SgEmailRequest {
//     const reqObj: SgEmailRequest = {
//       email: newCustomer.email,
//       subject: 'Thank You from OneWay Web Apps',
//       html: `Thanks for contacting us, ${newCustomer.contactName}. <br><br>
//       One of our account specialists will be in contact with you soon!<br><br>
//       We look forward to speaking with you.<br>
//       Best Regards,<br>
//       OneWay Web Apps`,
//       recaptchaToken: token
//     };
//     return reqObj;
//     // this.emailService.sendMessage(reqObj).subscribe(data => {
//     //   console.log('sendMessage ', data);
//     // });
//   }
