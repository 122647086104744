
import { DISPLAY_DATE_FORMAT, Guid, SportBetStatusType, SportBetType, SportLeagueType } from './common-types';
import firebase from 'firebase/compat/app';
import { getDatePayloadFromTimeStamp } from 'app/shared/utils/timestamp-utils';
import { DatePayload, PTableColumn } from './common-ux-types';
import {WagerSource } from './WagerSource';

// DRAFTKINGS LINE MOVEMENTS
// Money Line	Spread	Total	1st Half	2nd Half
// Date	Time	Fav	Dog	Fav	Dog	Over	Under	Fav	Dog	Fav	Dog

export interface SportBet {
  active?: boolean;
  id?: Guid;
  bet?: number;
  createdTimestamp: firebase.firestore.Timestamp;
  description: string;
  gameDateTimestamp: firebase.firestore.Timestamp;
  // isBestBet: boolean;
  //  gameTime: Time;
  league: SportLeagueType;
  odds: number;
  notes: string;
  rating: number;
  status: SportBetStatusType;
  title: string;
  type: SportBetType;
  updatedTimestamp: firebase.firestore.Timestamp;
  wagerSourceId?: Guid;
}

export interface SportBetListItem extends SportBet {
  createdDatePayload: DatePayload;
  gameDateStr: string;
  gameDatePayload: DatePayload;
  payout: number;
  updatedDatePayload: DatePayload;
  source: string;
  wagerSource: WagerSource;
  winnings: number;
}
// export const sportBetItemDisplayColumns =
//   ['number', 'title', 'sportsBetType', 'favorite', 'dog`', 'result', 'actions'];
// export const sportBetListDisplayColumnsold =
//   ['gameDateStr', 'source', 'league', 'type', 'title', 'odds', 'status', 'bet', 'winnings', 'payout', 'actions'];

// export const clientSportBetItemDisplayColumns =
//   ['gameDateStr', 'source', 'league', 'type', 'title', 'odds', 'status', 'bet', 'winnings', 'payout']; // , 'status'];


export const sportBetListDisplayColumns: PTableColumn[] = [
  { field: 'gameDateStr', header: 'Date' },
  { field: 'source', header: 'Source' },
  { field: 'league', header: 'League' },
  { field: 'type', header: 'Type' },
  { field: 'title', header: 'Title' },
  { field: 'odds', header: 'Odds' },
  { field: 'status', header: 'Status' },
  { field: 'bet', header: 'Bet' },
  { field: 'winnings', header: 'Winnings' },
  { field: 'payout', header: 'Payout' },
  { field: 'actions', header: 'Actions', width: '12%' },
];

export const defaultSportBet: SportBet = {
  active: true,
  id: null,
  bet: 100,
  createdTimestamp: null,
  description: '',
  gameDateTimestamp: null,
  //  gameTime: null,
  league: null,
  odds: 0,
  notes: '',
  rating: 0,
  status: SportBetStatusType.PENDING,
  title: '',
  type: null,
  updatedTimestamp: null,
  wagerSourceId: null
};

export function toSportBetListItem(item: SportBet, wagerSource: WagerSource): SportBetListItem {
  const gameDatePayload = item.gameDateTimestamp ? getDatePayloadFromTimeStamp(item.gameDateTimestamp, DISPLAY_DATE_FORMAT) : undefined;
  return {
    ...item,
    createdDatePayload: null, // item.createdTimestamp ? getDatePayloadFromTimeStamp(item.createdTimestamp, LIST_DATE_TIME_FORMAT) : undefined,
    gameDatePayload,
    gameDateStr: gameDatePayload ? gameDatePayload.dateStr : '',
    //   gameDatePayload: item.gameDateTimestamp ? getDatePayloadFromTimeStamp(item.gameDateTimestamp, DISPLAY_DATE_FORMAT, item.gameTime) : undefined,
    updatedDatePayload: null, //  item.updatedTimestamp ? getDatePayloadFromTimeStamp(item.updatedTimestamp, LIST_DATE_TIME_FORMAT) : undefined,
    winnings: calcWinnings(item.status, item.bet, item.odds),
    payout: calcPayout(item.status, item.bet, item.odds),
    wagerSource,
    source: wagerSource?.displayName
  };
}

export function toSportBetPayload(sportbetListItem: SportBetListItem): SportBet {
  console.log('MS toSportBetPayload sportbetListItem = ', sportbetListItem);
  const sportbet: SportBet = {
    active:  sportbetListItem.active ?? true,
    bet: sportbetListItem.bet,
    createdTimestamp: sportbetListItem.createdTimestamp,
    description: sportbetListItem.description,
    gameDateTimestamp: sportbetListItem.gameDateTimestamp,
    rating: sportbetListItem.rating ?? 0,
    // gameTime: sportbetListItem.gameTime,
    league: sportbetListItem.league,
    odds: sportbetListItem.odds,
    notes: sportbetListItem.notes,
    status: sportbetListItem.status,
    wagerSourceId: sportbetListItem.wagerSource ? sportbetListItem.wagerSource.id : null,
    title: sportbetListItem.title,
    type: sportbetListItem.type,
    updatedTimestamp: sportbetListItem.updatedTimestamp,
  }
  console.log('MS toSportBetPayload sportbet = ', sportbet);
  return sportbet;
}

// The math behind calculating payouts on sports bets
// When the odds are negative, change the number to positive and use this formula: 100/Odds * Stake = Profit.
// When the odds are positive: Odds/100 * Stake = Profit.

export function calcWinnings(status: SportBetStatusType, bet: number, odds: number): number {
  switch (status) {
    case SportBetStatusType.CANCELLED:
    case SportBetStatusType.PENDING:
    case SportBetStatusType.LOSER:
      return (0 - bet);
    case SportBetStatusType.PUSH:
      return 0;
    case SportBetStatusType.WINNER: {
      if (odds < 0) {
        const win = (100 / odds * bet) * -1;
        return Math.round(win * 100) / 100;
        //  return (100/odds * bet) * -1;
      } else {
        return odds / 100 * bet;
      }
    }
  }
}

export function calcPayout(status: SportBetStatusType, bet: number, odds: number): number {
  switch (status) {
    case SportBetStatusType.LOSER:
    case SportBetStatusType.CANCELLED:
    case SportBetStatusType.PENDING:
    case SportBetStatusType.PUSH:
      return 0;
    case SportBetStatusType.WINNER: {
      if (odds < 0) {
        return bet += calcWinnings(status, bet, odds);
      } else {
        return bet + odds;
      }
    }
  }
}

// export function toSportBet(item: SportBetListItem): SportBet {
//   return {
//     // id: item.id,
//     bet: item.bet,
//     createdTimestamp: item.createdTimestamp,
//     description: item.description ?? null,
//     gameDateTimestamp: firebase.firestore.Timestamp.fromDate(item.gameDatePayload.date),
//     gameTime: item.gameDatePayload.gameTime,
//     league: item.league,
//     odds: item.odds,
//     status: item.status,
//     notes: item.notes ?? null,
//     //  payout: calcPayout(item.status,item.bet,item.odds),
//     title: item.title,
//     type: item.type,
//     updatedTimestamp: item.updatedTimestamp ?? null,
//     source: item.source,
//     // winnings: calcWinnings(item.status, item.bet, item.odds)
//   };
// }

export const dummySportBets: SportBet[] = [
  {
    ...defaultSportBet,
    createdTimestamp: null,
    description: 'Seattle Mariners (-150) Action American League',
    gameDateTimestamp: null,
    // gameTime: null,
    league: SportLeagueType.MLB,
    odds: -150,
    wagerSourceId: null,
    notes: '',
    status: SportBetStatusType.PENDING,
    title: 'SEATTLE MARINERS vs Los Angeles Angels',
    type: SportBetType.MONEYLINE,
    updatedTimestamp: null,
  }, {
    ...defaultSportBet,
    createdTimestamp: null,
    description: 'Oakland As (-150) Action American League',
    gameDateTimestamp: null,
    // gameTime: null,
    odds: -150,
    wagerSourceId: null,
    league: SportLeagueType.MLB,
    notes: '',
    status: SportBetStatusType.PENDING,
    title: 'OAKLAND As vs Houston Astros',
    type: SportBetType.MONEYLINE,
    updatedTimestamp: null,
  }, {
    ...defaultSportBet,
    createdTimestamp: null,
    description: 'New York Yankees (-150) Action American League',
    gameDateTimestamp: null,
    // gameTime: null,
    league: SportLeagueType.MLB,
    odds: -150,
    wagerSourceId: null,
    notes: '',
    status: SportBetStatusType.PENDING,
    title: 'NEW YORK YANKEES vs Los Angeles Dodgers',
    type: SportBetType.MONEYLINE,
    updatedTimestamp: null,
  }, {
    ...defaultSportBet,
    createdTimestamp: null,
    description: 'Texas Rangers(-130) Action American League',
    gameDateTimestamp: null,
    // gameTime: null,
    league: SportLeagueType.MLB,
    odds: -150,
    notes: '',
    wagerSourceId: null,
    status: SportBetStatusType.PENDING,
    title: 'TEXAS RANGERS vs Tampa Bay Rays',
    type: SportBetType.MONEYLINE,
    updatedTimestamp: null,
  }
]


export function newSportBetListItem(dummyIndex = -1): SportBetListItem {
  if (dummyIndex >= 0) {
    return toSportBetListItem({
      ...dummySportBets[dummyIndex < dummySportBets.length ? dummyIndex : 0],
      createdTimestamp: firebase.firestore.Timestamp.now(),
    }, null);
  } else {
    return toSportBetListItem({
      ...defaultSportBet,
      createdTimestamp: firebase.firestore.Timestamp.now(),
    }, null);
  }
}
