import { Action } from '@ngrx/store';
import { WagerSource } from 'app/core/models/WagerSource';
import { SgEditingStatus, Guid } from 'app/core/models/common-types';
import * as fromActionTypes from '../action-types';

// WAGER_SOURCES

export class SetWagerSourceEditingStatus implements Action {
  readonly type = fromActionTypes.SET_WAGER_SOURCE_EDITING_STATUS;
  constructor(public payload: SgEditingStatus) { }
}
export class WagerSourcesSetSelectedWagerSource implements Action {
  readonly type = fromActionTypes.WAGER_SOURCE_LIST_SET_SELECTED_WAGER_SOURCE;
  constructor(public payload: WagerSource) { }
}

export class WagerSourcesLoadWagerSources implements Action {
  readonly type = fromActionTypes.WAGER_SOURCES_LOAD;
  constructor() { }
}
export class WagerSourcesLoadWagerSourcesFail implements Action {
  readonly type = fromActionTypes.WAGER_SOURCES_LOAD_FAIL;
  constructor(public payload: any) { }
}
export class WagerSourcesLoadWagerSourcesSuccess implements Action {
  readonly type = fromActionTypes.WAGER_SOURCES_LOAD_SUCCESS;
  constructor(public payload: WagerSource[]) { }
}
export class WagerSourcesAddWagerSource implements Action {
  readonly type = fromActionTypes.WAGER_SOURCE_LIST_ADD_WAGER_SOURCE;
  constructor(public payload: WagerSource) { }
}
export class WagerSourcesAddWagerSourceSuccess implements Action {
  readonly type = fromActionTypes.WAGER_SOURCE_LIST_ADD_WAGER_SOURCE_SUCCESS;
  constructor(public payload: WagerSource) { }
}
export class WagerSourcesAddWagerSourceFail implements Action {
  readonly type = fromActionTypes.WAGER_SOURCE_LIST_ADD_WAGER_SOURCE_FAIL;
  constructor(public payload: any) { }
}

export class WagerSourcesDeleteWagerSource implements Action {
  readonly type = fromActionTypes.WAGER_SOURCE_LIST_DELETE_WAGER_SOURCE;
  constructor(public payload: Guid) { }
}
export class WagerSourcesDeleteWagerSourceSuccess implements Action {
  readonly type = fromActionTypes.WAGER_SOURCE_LIST_DELETE_WAGER_SOURCE_SUCCESS;
  constructor() { }
}
export class WagerSourcesDeleteWagerSourceFail implements Action {
  readonly type = fromActionTypes.WAGER_SOURCE_LIST_DELETE_WAGER_SOURCE_FAIL;
  constructor(public payload: any) { }
}
export class WagerSourcesUpdateWagerSource implements Action {
  readonly type = fromActionTypes.WAGER_SOURCE_LIST_UPDATE_WAGER_SOURCE;
  constructor(public payload: WagerSource) { }
}
export class WagerSourcesUpdateWagerSourceSuccess implements Action {
  readonly type = fromActionTypes.WAGER_SOURCE_LIST_UPDATE_WAGER_SOURCE_SUCCESS;
  constructor(public payload: WagerSource) { }
}
export class WagerSourcesUpdateWagerSourceFail implements Action {
  readonly type = fromActionTypes.WAGER_SOURCE_LIST_UPDATE_WAGER_SOURCE_FAIL;
  constructor(public payload: any) { }
}
// action types
export type WagerSourcesActions =
  | WagerSourcesSetSelectedWagerSource
  | SetWagerSourceEditingStatus
  | WagerSourcesAddWagerSource
  | WagerSourcesAddWagerSourceFail
  | WagerSourcesAddWagerSourceSuccess
  | WagerSourcesDeleteWagerSource
  | WagerSourcesDeleteWagerSourceFail
  | WagerSourcesDeleteWagerSourceSuccess
  | WagerSourcesLoadWagerSources
  | WagerSourcesLoadWagerSourcesFail
  | WagerSourcesLoadWagerSourcesSuccess
  | WagerSourcesUpdateWagerSource
  | WagerSourcesUpdateWagerSourceFail
  | WagerSourcesUpdateWagerSourceSuccess

  ;
