import { Injectable } from '@angular/core';
import { ofType, Actions, createEffect } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { UiService } from '../../../core/services/ui/ui.service';
import { ADD_SG_USER, ADD_SG_USER_SUCCESS, DELETE_SG_USER, EMAIL_NEW_CLIENT_CONTACT_INFO_SUCCESS, EMAIL_CUSTOMER_SERVICE_REQUEST_SUCCESS, LOAD_SG_SETTINGS, LOAD_SG_USERS, SEND_EMAIL_TO_CUSTOMER, SET_FIREBASE_USER_UNAUTHENTICATED, SET_SG_USER_AUTHENTICATED, SET_SG_USER_UNAUTHENTICATED, UPDATE_SG_SETTINGS, UPDATE_SG_USER, LOAD_SG_USER_FOR_AUTH_SUCCESS, EMAIL_NEW_CLIENT_CONTACT_INFO, LOAD_SG_USER_FOR_AUTH, SET_SG_USER_EDITING_STATUS } from '../action-types';
import { AddSgUser, AddSgUserFail, AddSgUserSuccess, DeleteSgUser, DeleteSgUserFail, DeleteSgUserSuccess, EmailNewClientContactSuccess, EmailCustomerServiceRequestSuccess, LoadRcSettings, LoadSgUsers, LoadSgUsersFail, LoadSgUsersSuccess, SendEmailToCustomer, SendEmailToCustomerFail, SendEmailToCustomerSuccess, SetFirebaseUserUnAuthenticated, SetSgUserAuthenticated, SetSgUserUnAuthenticated, UpdateRcSettings, UpdateRcSettingsSuccess, UpdateSgUser, UpdateSgUserFail, UpdateSgUserSuccess, EmailNewClientContact, EmailNewClientContactFail, LoadSgUserForAuth, LoadSgUserForAuthSuccess, LoadSgUserForAuthFail, SetSgUserEditingStatus } from '../actions';
import { AuthService } from 'app/auth/service/auth.service';
import { Router } from '@angular/router';
import { FirebaseUser } from 'app/auth/models/auth-data.model';
import { AuthStateService } from '../state-service';
import { EmailService } from 'app/core/services/email/email.service';
import { SgEmailRequest } from 'app/core/models/SgEmailRequest';
import { ConfirmationService } from 'primeng/api';
import { isSgAdminUser, SGUser, SgUserListItem, toSGUserListItem } from 'app/core/models/SgUser';
import { UiStateService } from 'app/core/store/ui/state-service';
import { QuerySnapshot } from '@angular/fire/compat/firestore';
import { APP_MENU_GAME_PLANS_LINK } from 'app/core/models/common-ux-types';
import { checkSgUserSubscription } from 'app/core/models/ClientSubscription';
import { transformSgEmailRequest } from './auth.effects.utils';
import { SgEditingStatus } from 'app/core/models/common-types';


@Injectable()
export class AuthEffects {

  // @Effect({ dispatch: false })
  // loadSgUsers$ = this.actions$.pipe(
  //   ofType<LoadSgUsers>(LOAD_SG_USERS),
  //   tap(() => {
  //     this.authService.loadSgUsers('last', 'asc', false);
  //   })
  // );
  // @Effect()

  // @Effect()
  // loadSgUserForAuth$: Observable<Action> = this.actions$.pipe(
  //   ofType<LoadSgUserForAuth>(LOAD_SG_USER_FOR_AUTH),

  loadSgUserForAuth$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<LoadSgUserForAuth>(LOAD_SG_USER_FOR_AUTH),

    switchMap((action) => this.authService
      .loadSgUserForAuthentication(action.payload.email)
      .pipe(
        map((querySnapshot: QuerySnapshot<SGUser>) => {
          console.log('MS LOAD_SG_USER_FOR_AUTH querySnapshot= ', querySnapshot);
          const sgUsers: SGUser[] = [];
          querySnapshot.forEach(doc => {
            const data = doc.data() as SGUser;
            const id = doc['id'];
            const sgUser: SGUser = {
              ...data,
              id,

            };
            sgUsers.push(sgUser);
          });
          console.log('MS LOAD_SG_USER_FOR_AUTH sgUsers= ', sgUsers);
          this.uiStateService.stopLoading();
          return new LoadSgUserForAuthSuccess(sgUsers[0])
        }),
        catchError((error) => {
          this.uiStateService.stopLoading();
          if (error.code !== 'permission-denied') {
            this.uiService.showSnackbar(
              'Fetching Users failed',
              null,
              3000
            );
          }
          return of(new LoadSgUserForAuthFail(error))
        })
      ))
  ));

  // @Effect({ dispatch: false })
  // loadSgUserForAuthSuccess$ = this.actions$.pipe(

  loadSgUserForAuthSuccess$ = createEffect(() => this.actions$.pipe(

    ofType<LoadSgUserForAuthSuccess>(LOAD_SG_USER_FOR_AUTH_SUCCESS),
    withLatestFrom(
      this.authStateService.fireBaseUser$,
      this.authStateService.isSgUserAuthenticated$,
      this.authStateService.isFirebaseUserAuthenticated$,
      this.uiStateService.isAdminActive$,
    ),
    map(([action, firebaseUser, _isSgUserAuthenticated, isFirebaseUserAuthenticated, isAdminActive]:
      [LoadSgUserForAuthSuccess, FirebaseUser, boolean, boolean, boolean]) => {
      console.log('MS loadSgUserForAuthSuccess firebaseUser', firebaseUser);
      console.log('MS loadSgUserForAuthSuccess isFirebaseUserAuthenticated', isFirebaseUserAuthenticated);
      console.log('MS loadSgUserForAuthSuccess action.payload', action.payload);
      if (isAdminActive) {
        console.log('MS loadSgUserForAuthSuccess isAdminActive!!! STOP!');
        return;
      }
      if (firebaseUser && isFirebaseUserAuthenticated) {
        // console.log('MS LoadSgUsersSuccess sgUser', sgUser);
        let message = `The user for ${firebaseUser.email} was not found. If this is an error contact customer support.`;
        if (action.payload) {
          const adminUser = isSgAdminUser(action.payload.userType);
          if (adminUser) {
            this.authStateService.setSgUserAuthenticated(toSGUserListItem(action.payload));
            this.authStateService.loadUsers();
            return;
          } else {
            const clientSubscriptionCheck = checkSgUserSubscription(action.payload.clientSubscription.endTimestamp);
            console.log('MS loadSgUserForAuthSuccess retVal', clientSubscriptionCheck);
            if (action.payload.isActive && !clientSubscriptionCheck.isExpired) {
              this.authStateService.setSgUserAuthenticated(toSGUserListItem(action.payload));
              return;
            } else if (!action.payload.isActive) {
              message = 'Your account is not activated. If this is an error please contact customer support.';
            } else if (clientSubscriptionCheck.isExpired) {
              message = `${clientSubscriptionCheck.expirationStr}. If this is an error please contact customer support. `;
            }
          }
        }
        this.uiService.showSnackbar(
          message,
          null,
          10000
        );
        this.authStateService.setSgUserUnAuthenticated();
      }
    })
  ), { dispatch: false });
  // addSgUser$: Observable<Action> = this.actions$.pipe(
  //   ofType<LoadSgUserForAuth>(LOAD_SG_USER_FOR_AUTH),
  //   switchMap((action) => this.authService
  //     .loadSgUserForAuthentication(action.payload.email).pipe(map((docRef) =>
  //     console.log('MS LOAD_SG_USERS querySnapshot= ', querySnapshot);
  //     // querySnapshot.forEach(doc => {
  //     //   const data = doc.data() as SGUser;
  //     //   const id = doc['id'];
  //     //   const sgUser: SGUser = {
  //     //     ...data,
  //     //     id,

  //     //   };

  //     return new LoadSgUserForAuthSuccess()

  //     ),
  //       catchError((error) =>
  //         of(new AddSgUserFail(error))
  //       )
  //     ))
  // );
  // TO DO REFACTOR THIS LATER
  loadSgUsers$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<LoadSgUsers>(LOAD_SG_USERS),

    // withLatestFrom(this.authStateService.loggedInSGUser$),
    switchMap(() => this.authService
      .getAllSgUsers()
      .pipe(
        map((querySnapshot: QuerySnapshot<SGUser>) => {
          // console.log('MS LOAD_SG_USERS querySnapshot= ', querySnapshot);
          const sgUsers: SgUserListItem[] = [];
          querySnapshot.forEach(doc => {
            const data = doc.data() as SGUser;
            const id = doc['id'];
            const sgUser: SGUser = {
              ...data,
              id,
            };
            sgUsers.push(toSGUserListItem(sgUser));
          });
          //  console.log('MS LOAD_REPORT_DOCUMENTS reportDocumentList= ', reportDocumentList);
          this.uiStateService.stopLoading();
          return new LoadSgUsersSuccess(sgUsers)
        }),
        catchError((error) => {
          this.uiStateService.stopLoading();
          if (error.code !== 'permission-denied') {
            this.uiService.showSnackbar(
              'Fetching Users failed',
              null,
              3000
            );
          }
          return of(new LoadSgUsersFail(error))
        })
      ))
  ));
  // @Effect()
  // loadSgUsersOLD$: Observable<Action> = this.actions$.pipe(
  //   ofType<LoadSgUsers>(LOAD_SG_USERS),
  //   //  withLatestFrom(this.authStateService.loggedInSGUser$),
  //   switchMap(() => this.authService
  //     .loadSgUsers2()
  //     .pipe(
  //       map((docArray) => {
  //         //  console.log('MS LOAD_SG_USERS docArray= ', docArray);
  //         const sgUsers: SGUser[] = docArray.map(a => {
  //           const data = a.payload.doc.data() as SGUser;
  //           const id = a.payload.doc['id'];
  //           return {
  //             ...data,
  //             id
  //           };
  //         });
  //         //  console.log('MS LOAD_SG_USERS sgUsers= ', sgUsers);

  //         this.uiStateService.stopLoading();
  //         return new LoadSgUsersSuccess(sgUsers)
  //       }),
  //       catchError((error) => {
  //         this.uiStateService.stopLoading();
  //         if (error.code !== 'permission-denied') {
  //           this.uiService.showSnackbar(
  //             'Fetching Users failed',
  //             null,
  //             3000
  //           );
  //         }
  //         return of(new LoadSgUsersFail(error))
  //       })
  //     ))
  // );


  // @Effect({ dispatch: false })
  // loadSgUsersSuccess$ = this.actions$.pipe(
  //   ofType<LoadSgUsersSuccess>(LOAD_SG_USERS_SUCCESS),
  //   withLatestFrom(
  //     this.authStateService.fireBaseUser$,
  //     this.authStateService.isSgUserAuthenticated$,
  //     this.authStateService.isFirebaseUserAuthenticated$,
  //     this.uiStateService.isAdminActive$,
  //   ),
  //   map(([action, firebaseUser, _isSgUserAuthenticated, isFirebaseUserAuthenticated, isAdminActive]:
  //     [LoadSgUsersSuccess, FirebaseUser, boolean, boolean, boolean]) => {
  //     console.log('MS LoadSgUsersSuccess firebaseUser', firebaseUser);
  //     console.log('MS LoadSgUsersSuccess isFirebaseUserAuthenticated', isFirebaseUserAuthenticated);
  //     console.log('MS LoadSgUsersSuccess action.payload', action.payload);
  //     if (isAdminActive) {
  //       console.log('MS LoadSgUsersSuccess isAdminActive!!! STOP!');
  //       return;
  //     }
  //     if (firebaseUser && isFirebaseUserAuthenticated) {
  //       const sgUser = authenticateSgUser(action.payload, firebaseUser);
  //       // console.log('MS LoadSgUsersSuccess sgUser', sgUser);
  //       if (sgUser) {
  //         // const retVal = checkSubscription(sgUser);
  //         // console.log('MS LoadSgUsersSuccess sgUser', sgUser);

  //         this.authStateService.setSgUserAuthenticated(sgUser);
  //       } else {
  //         this.authStateService.setSgUserUnAuthenticated();
  //       }
  //     }
  //   })
  // );


  // @Effect({ dispatch: false })
  // reloadSgUsers$ = this.actions$.pipe(
  //   ofType<LoadSgUsers>(
  //     UPDATE_SG_USER_SUCCESS,
  //     DELETE_SG_USER_SUCCESS,
  //     ADD_SG_USER_SUCCESS),
  //   tap(() => {
  //     this.authService.loadSgUsers('last', 'asc', true);
  //   })
  // );


  addSgUserSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<AddSgUserSuccess>(ADD_SG_USER_SUCCESS),
    withLatestFrom(
      this.authStateService.isSgAdminUser$
    ),
    map(([action, isAdminUser]: [AddSgUserSuccess, boolean]) => {
      // console.log('MS LoadSgUsersSuccess firebaseUser', firebaseUser);
      // console.log('MS LoadSgUsersSuccess isFirebaseUserAuthenticated', isFirebaseUserAuthenticated);
      // console.log('MS LoadSgUsersSuccess isSgUserAuthenticated', isSgUserAuthenticated);
      //   this.authStateService.addFirebaseUser(action.payload);

      if (isAdminUser) {
        this.confirmationService.confirm({
          message: `The user: ${action.payload.first} ${action.payload.last} with the email ${action.payload.email} has been added! <p>
          Would you like to add user to the Firebase account now?`,
          header: 'Add User',
          icon: 'pi pi-exclamation-triangle',
          acceptButtonStyleClass:"p-button-danger p-button-text",
          rejectButtonStyleClass:"p-button-text p-button-text",
          acceptLabel: 'Yes',
          rejectLabel: 'No',
          accept: () => {
            this.authService.registerUser({
              email: action.payload.email,
              password: `${action.payload.first}123`,
            }, action.payload);
          },
          reject: () => {}
        });

        // modalRef.acceptFunc$.subscribe(() => {
        //   this.confirmationService.dismiss();

        //   // console.log('MS confirm sgUser= ', sgUser);
        //   //  this.authStateService.deleteSgUser(sgUser);
        //   this.authService.registerUser({
        //     email: action.payload.email,
        //     password: `${action.payload.first}123`,
        //   }, action.payload);

        // });
      }
    })
  ), { dispatch: false });

  loadRcSettings$ = createEffect(() => this.actions$.pipe(
    ofType<LoadRcSettings>(LOAD_SG_SETTINGS),
    tap(() => {
      this.authService.loadRcSettings();
    })
  ), { dispatch: false });


  setSgUserAuthenticated$ = createEffect(() => this.actions$.pipe(
    ofType<SetSgUserAuthenticated>(
      SET_SG_USER_AUTHENTICATED),
    tap((action) => {
      console.log('MS setSgUserAuthenticated user= ', action.payload);
      this.router.navigate(['/home']);
      // if (isClientUserType(action.payload.userType)) {
      //   this.clientStateService.loadClientForUser(action.payload.clientId);
      // } else {
      //   this.clientStateService.loadClients();
      // }
    })
  ), { dispatch: false });

  sgUserUnAuthenticated$ = createEffect(() => this.actions$.pipe(
    ofType<SetSgUserUnAuthenticated>(SET_SG_USER_UNAUTHENTICATED),
    ofType<SetFirebaseUserUnAuthenticated>(SET_FIREBASE_USER_UNAUTHENTICATED),
    tap(() => {
      this.uiService.showSnackbar(
        'Your Login Failed, Please Try Again or Contact Customer Support!',
        null,
        3000
      );
    })
  ), { dispatch: false });

  addSgUser$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<AddSgUser>(ADD_SG_USER),
    switchMap((action) => this.authService
      .addSgUser(action.payload).pipe(map((docRef) =>
        new AddSgUserSuccess(toSGUserListItem({
          ...action.payload,
          id: docRef.id
        }))

      ),
        catchError((error) =>
          of(new AddSgUserFail(error))
        )
      ))
  ));

  updateSgUser$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<UpdateSgUser>(UPDATE_SG_USER),
    switchMap((action) => this.authService
      .updateSgUser(action.payload).pipe(map(() =>
        new UpdateSgUserSuccess(action.payload)
      ),
        catchError((error) =>
          of(new UpdateSgUserFail(error))
        )
      ))
  ));

  // @Effect()
  // deleteMultipleSgUsers$: Observable<Action> = this.actions$.pipe(
  //   ofType<DeleteMultipleSgUsers>(DELETE_MULTIPLE_SG_USERS),
  //   switchMap((action) => this.authService
  //     .deleteMultipleSgUsers(action.payload).pipe(map(() =>
  //       new DeleteMultipleSgUsersSuccess()
  //     ),
  //       catchError((error) =>
  //         of(new DeleteSgUserFail(error))
  //       )
  //     ))
  // );

  // @Effect({ dispatch: false })
  // deleteMultipleSgUsersSuccess$ = this.actions$.pipe(
  //   ofType<DeleteMultipleSgUsersSuccess>(DELETE_MULTIPLE_SG_USERS_SUCCESS),
  //   tap(() => {
  //     this.authStateService.reloadUsers();
  //   })
  // );


  deleteSgUser$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<DeleteSgUser>(DELETE_SG_USER),
    switchMap((action) => this.authService
      .deleteSgUser(action.payload).pipe(map(() =>
        new DeleteSgUserSuccess(action.payload)
      ),
        catchError((error) =>
          of(new DeleteSgUserFail(error))
        )
      ))
  ));

  updateRcSettings$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<UpdateRcSettings>(UPDATE_SG_SETTINGS),
    switchMap((action) => this.authService
      .updateRcSettings(action.payload).pipe(map(() =>
        new UpdateRcSettingsSuccess(action.payload)
      ),
        catchError((error) =>
          of(new UpdateSgUserFail(error))
        )
      ))
  ));

  // @Effect()
  // sendRecaptchaToken$: Observable<Action> = this.actions$.pipe(
  //   ofType<SendRecaptchaToken>(SEND_RECAPTCHA_TOKEN),
  //   switchMap((action) => this.authService
  //     .sendRecaptchaToken(action.payload).pipe(map((res: RecaptchaResponse) => {
  //       if (res.success) {
  //         return new SendRecaptchaTokenSuccess()
  //       }
  //     }
  //     ),
  //       catchError((error) =>
  //      //    console.log('MS SendRecaptchaToken error = ', error);
  //          of(new SendRecaptchaTokenFail(error))

  //       )
  //     ))
  // );


  // @Effect()
  // emailCustomerContactInfo$: Observable<Action> = this.actions$.pipe(
  //   ofType<EmailNewClientContact>(EMAIL_NEW_CLIENT_CONTACT_INFO),
  //   switchMap((action) => this.emailService
  //     .sendMessage(action.payload.rcEmailRequest).pipe(map(() =>
  //     new EmailNewClientContactSuccess(action.payload)
  //     ),
  //       catchError((error) =>
  //         of(new EmailNewClientContactFail(error))
  //       )
  //     ))
  // );
  emailNewClientContact$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<EmailNewClientContact>(EMAIL_NEW_CLIENT_CONTACT_INFO),
    switchMap((action) => this.emailService
      .sendMessage(action.payload.rcEmailRequest).pipe(map((res) => {
        this.uiStateService.stopLoading();
        return new EmailNewClientContactSuccess(action.payload);
      }),
        catchError((error) => {
          this.uiStateService.stopLoading();
          return of(new EmailNewClientContactFail(error));
        })
      ))
  ));

  // @Effect()
  // emailCustomerServiceRequest$: Observable<Action> = this.actions$.pipe(
  //   ofType<EmailCustomerServiceRequest>(EMAIL_CUSTOMER_SERVICE_REQUEST),
  //   switchMap((action) => this.emailService
  //     .sendMessage(action.payload).pipe(map((res) => new EmailCustomerServiceRequestSuccess(res)
  //     ),
  //       catchError((error) =>
  //         of(new EmailCustomerServiceRequestFail(error))
  //       )
  //     ))
  // );

  emailCustomerServiceRequestSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<EmailCustomerServiceRequestSuccess>(EMAIL_CUSTOMER_SERVICE_REQUEST_SUCCESS),
    tap((action) => {
       console.log('MS EmailCustomerServiceRequestSuccess action.payload = ',action.payload);
      this.uiService.showSnackbar(
        'Your Request has been successfully sent! We look forward to speaking with you.',
        null,
        5000
      );
      // const newSgEmailRequest: SgEmailRequest = transformSgEmailRequest(action.payload.rcEmailRequest, action.payload.newCustomer);
      //   console.log('MS EmailNewClientContactSuccess newSgEmailRequest= ',newSgEmailRequest);
      // TURNING THIS OFF FOR NOW, FOR TESTING we max at 25 per day
      // this.authStateService.sendEmailToCustomer(newSgEmailRequest);
      this.router.navigate([APP_MENU_GAME_PLANS_LINK]);
    })
  ), { dispatch: false });


  sendThankYouToCustomer$ = createEffect(() => this.actions$.pipe(
    ofType<EmailNewClientContactSuccess>(EMAIL_NEW_CLIENT_CONTACT_INFO_SUCCESS),
    tap((action) => {
      this.uiService.showSnackbar(
        'Your Request has been successfully sent! We look forward to speaking with you.',
        null,
        3000
      );
      const newSgEmailRequest: SgEmailRequest = transformSgEmailRequest(action.payload.rcEmailRequest, action.payload.newClient);
      //   console.log('MS EmailNewClientContactSuccess newSgEmailRequest= ',newSgEmailRequest);
      // TURNING THIS OFF FOR NOW, FOR TESTING we max at 25 per day
      // this.authStateService.sendEmailToCustomer(newSgEmailRequest);
      this.router.navigate(['/home']);
    })
  ), { dispatch: false });

  sendEmailToCustomer$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<SendEmailToCustomer>(SEND_EMAIL_TO_CUSTOMER),
    switchMap((action) => this.emailService
      .sendMessage(action.payload).pipe(map((res) => {
        this.uiStateService.stopLoading();
        return new SendEmailToCustomerSuccess();
      }),
        catchError((error) => {
          this.uiStateService.stopLoading();
          return of(new SendEmailToCustomerFail(error));
        })
      ))
  ));

  setSgUserEditingStatus$ = createEffect(() => this.actions$.pipe(
    ofType<SetSgUserEditingStatus>(
      SET_SG_USER_EDITING_STATUS),
    tap((action) => {
      console.log('MS setWorkOrderEditingStatus action.payload= ', action.payload);
      this.uiStateService.setIsEditingEntity(action.payload !== SgEditingStatus.NOT_EDITING);
    })
  ), { dispatch: false });


  constructor(
    private actions$: Actions,
    private uiService: UiService,
    private uiStateService: UiStateService,
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private authStateService: AuthStateService,
    private router: Router,
    private emailService: EmailService
  ) { }
}
