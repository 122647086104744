import { environment } from 'environments/environment';

const BACKEND_URL = environment.apiUrl;

// eslint-disable-next-line @typescript-eslint/naming-convention
export const AppServiceApiConfig = {
    // sgUsers: BACKEND_URL + 'rcusers',
    // sgUsersSignUp:  BACKEND_URL + 'rcusers/signup',
    // sgUsersLogin:  BACKEND_URL + 'rcusers/login',
    // customers:  BACKEND_URL + 'customers',
    // products:  BACKEND_URL + 'products',
    //  servicePointPackages: BACKEND_URL + 'servicepointpackages',
    // touchPointProjectListItems: BACKEND_URL + 'servicepointlocations',
    // projects: BACKEND_URL + 'customerlocations',
    // gamePlanListItems: BACKEND_URL + 'serviceevents',
    email: BACKEND_URL + 'sendMail',
    // recaptcha: BACKEND_URL + 'recaptcha',
}
