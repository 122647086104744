import { FirebaseUser } from 'app/auth/models/auth-data.model';
import { SgEditingStatus } from 'app/core/models/common-types';
import { RCSettings } from 'app/core/models/Settings';
import { SgUserListItem } from '../../../core/models/SgUser';


export interface RcAuthState {
  firebaseUser: FirebaseUser;
  isFirebaseUserAuthenticated: boolean;
  isRecaptchaTokenAuthenticated: boolean;
  isSgUserAuthenticated: boolean;
  loggedInSGUser: SgUserListItem;
  sgUserEditingStatus: SgEditingStatus;
  sgUserListItems: SgUserListItem[];
  // sgUserListItems: SgUserListItem[];
  rcSettings: RCSettings;
  // isRecaptchaPassed: boolean;
}

export const INITIAL_SG_SETTINGS: RCSettings = {
  isSignUpActive: false
}

export const INITIAL_AUTH_STATE: RcAuthState = {
  firebaseUser: undefined,
  isFirebaseUserAuthenticated: false,
  isRecaptchaTokenAuthenticated: false,
  isSgUserAuthenticated: false,
  loggedInSGUser: undefined,
  sgUserEditingStatus: SgEditingStatus.EDITING,
  sgUserListItems: [],
  rcSettings: INITIAL_SG_SETTINGS
};
