import { createSelector } from '@ngrx/store';
import { RcAuthState } from '../state/auth.state.interface';
import { AppState } from 'app/core/core.state';
import { SgEditingStatus, SgUserType } from 'app/core/models/common-types';

export const authStateSelector = (state: AppState): RcAuthState =>
  state.authState;

export const isFirebaseUserAuthenticatedSelector = createSelector(
  authStateSelector,
  (state: RcAuthState) => {
    const retval = state.isFirebaseUserAuthenticated ? state.isFirebaseUserAuthenticated : false;
    return retval;
  }
);
export const isSgUserAuthenticatedSelector = createSelector(
  authStateSelector,
  (state: RcAuthState) => state.isSgUserAuthenticated ? state.isSgUserAuthenticated : false
);

export const fireBaseUserSelector = createSelector(
  authStateSelector,
  (state: RcAuthState) => state.firebaseUser ? state.firebaseUser : undefined
);

export const rcSettingsSelector = createSelector(
  authStateSelector,
  (state: RcAuthState) => state.rcSettings ? state.rcSettings : undefined
);

export const isRecaptchaTokenAuthenticatedSelector = createSelector(
  authStateSelector,
  (state: RcAuthState) => state.isRecaptchaTokenAuthenticated ? state.isRecaptchaTokenAuthenticated : false
);

// export const sgUsersSelector = createSelector(
//   authStateSelector,
//   (state: RcAuthState) => state.sgUsers ? state.sgUsers : []
// );
export const sgUserListItemsSelector = createSelector(
  authStateSelector,
  (state: RcAuthState) => state.sgUserListItems ? state.sgUserListItems : []
);

export const sgUserEditingStatusSelector = createSelector(
  authStateSelector,
  (state: RcAuthState) => state.sgUserEditingStatus ? state.sgUserEditingStatus : SgEditingStatus.NOT_EDITING
  );

// export const allSgUsersSelector = createSelector(
//   authStateSelector,
//   (state: RcAuthState) => state.sgUsers ? state.sgUsers : []
// );

// export const techniciansSelector = createSelector(
//   authStateSelector,
//   (state: RcAuthState) => {
//    // console.log('MS techniciansSelector 1 state.sgUsers= ', state?.sgUsers);
//     const techs = state.sgUsers ? state.sgUsers.filter(user => user.userType !== SgUserType.SG_CLIENT_CUSTOMER) : [];
//  //  console.log('MS techniciansSelector 1 techs= ', techs);
//     return state? techs : undefined;
//   }

// );
//   {
//     // const loggedInSGUser = state.loggedInSGUser ? state.loggedInSGUser : undefined;
//    // console.log('MS techniciansSelector 1 retVal= ', loggedInSGUser);
//     // if (!isNil(loggedInSGUser)) {
//     //   return state.sgUsers ? state.sgUsers.filter(user => user.userType === SgUserType.TECHNICIAN && user.isQaUser) : [];
//     // }
//     state.sgUsers ? state.sgUsers.filter(user => user.userType === SgUserType.TECHNICIAN) : [];
//   }
//  );
// export const techniciansSelector = createSelector(
//   authStateSelector,
//   (state: RcAuthState) => state.loggedInSGUser ? isTechnicianUser(state.loggedInSGUser.userType) ? true : false : false
// );


export const loggedInSGUserSelector = createSelector(
  authStateSelector,
  (state: RcAuthState) => state.loggedInSGUser ? state.loggedInSGUser : undefined
);
// export const infoAdminSGUserSelector = createSelector(
//   authStateSelector,
//   (state: RcAuthState) => state.sgUsers.find(user => user.userType === SgUserType.SG_ADMIN && user.email === 'info@servicegambit.com')
// );

export const isSgClientUserSelector = createSelector(
  authStateSelector,
  (state: RcAuthState) => state.loggedInSGUser && state.loggedInSGUser.userType === SgUserType.SG_CLIENT
);

// export const isClientCustomerUserSelector = createSelector(
//   authStateSelector,
//   (state: RcAuthState) => state.loggedInSGUser ? isClientCustomerUser(state.loggedInSGUser.userType) ? true : false : false
// );

export const isSgAdminUserSelector = createSelector(
  authStateSelector,
  (state: RcAuthState) => state.loggedInSGUser && (state.loggedInSGUser.userType === SgUserType.SG_ADMIN ||
  state.loggedInSGUser.userType === SgUserType.SG_SUPERUSER)
);
export const isDadoUserSelector = createSelector(
  authStateSelector,
  (state: RcAuthState) => state.loggedInSGUser && (state.loggedInSGUser.userType === SgUserType.SG_ADMIN &&
  state.loggedInSGUser.email === 'dgflorida5@gmail.com')
);


export const isSgSuperUserSelector = createSelector(
  authStateSelector,
  (state: RcAuthState) => state.loggedInSGUser && state.loggedInSGUser.userType === SgUserType.SG_SUPERUSER
);
// export const isSgSuperUserSelector = createSelector(
//   authStateSelector,
//   (state: RcAuthState) => state.loggedInSGUser ? (state.loggedInSGUser.userType === SgUserType.SG_SUPERUSER) ? true : false : false
// );
// export const isSgAdminUserSelector = createSelector(
//   authStateSelector,
//   (state: RcAuthState) => state.loggedInSGUser ? (state.loggedInSGUser.userType === SgUserType.SUPER_USER || state.loggedInSGUser.userType === SgUserType.SG_ADMIN) ? true : false : false
// );


// export const isMasterUserSelector = createSelector(
//   authStateSelector,
//   (state: RcAuthState) => state.loggedInSGUser ? (state.loggedInSGUser.userType === SgUserType.SG_ADMIN) ? true : false : false
// );
// export const getSgUserTypeSelector = createSelector(
//   authStateSelector,
//   (state: RcAuthState) => state.loggedInSGUser ? state.loggedInSGUser.userType : undefined
// );

