import { createSelector } from '@ngrx/store';
import { AppState } from 'app/core/core.state';
import { SgEditingStatus } from 'app/core/models/common-types';
import { SportBetState } from '../state/sport-bets.state.interface';

export const sportBetListStateSelector = (state: AppState): SportBetState =>
  state.sportBetState;

export const sportBetListSelector = createSelector(
  sportBetListStateSelector,
  (state: SportBetState) => state.sportBetListItems ? state.sportBetListItems : []
  // (state: RcAdminState) => state.customers? state.customers.sort((a, b) => a.name.localeCompare(b.name)) : []
);
export const selectedSportBetListItemSelector = createSelector(
  sportBetListStateSelector,
  (state: SportBetState) => state.selectedSportBetListItem ? state.selectedSportBetListItem : undefined
);
export const isSportBetLoadedSourceSelector = createSelector(
  sportBetListStateSelector,
  (state: SportBetState) => state.isLoaded ?? false
);

export const sportBetditingStatusSelector = createSelector(
  sportBetListStateSelector,
  (state: SportBetState) => state.editingStatus ? state.editingStatus : SgEditingStatus.NOT_EDITING
);
