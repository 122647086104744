import {
  WAGER_SOURCES_LOAD, WAGER_SOURCES_LOAD_FAIL, WAGER_SOURCES_LOAD_SUCCESS,
  WAGER_SOURCE_LIST_SET_SELECTED_WAGER_SOURCE, SET_WAGER_SOURCE_EDITING_STATUS
} from '../action-types';
import { WagerSourcesActions } from '../actions';
import { INITIAL_WAGER_SOURCE_LIST_STATE, WagerSourcesState } from '../state/wager-source.state.interface';

export function wagerSourceReducer(
  state = INITIAL_WAGER_SOURCE_LIST_STATE,
  action: WagerSourcesActions
): WagerSourcesState {
  switch (action.type) {
    case WAGER_SOURCES_LOAD:
    case WAGER_SOURCES_LOAD_FAIL:
      return {
        ...state,
        wagerSources: [],
        selectedWagerSource: undefined,
        isLoaded: false
      };
    case WAGER_SOURCES_LOAD_SUCCESS: {
      const newWagerSources = [...action.payload];
      return {
        ...state,
        isLoaded: true,
        wagerSources: newWagerSources.sort((a, b) => a.displayName.localeCompare(b.displayName))
      };
    }
    case SET_WAGER_SOURCE_EDITING_STATUS:
      return {
        ...state,
        editingStatus: action.payload,
      };
    case WAGER_SOURCE_LIST_SET_SELECTED_WAGER_SOURCE:
      return {
        ...state,
        selectedWagerSource: action.payload,
      };
    default: {
      return state;
    }
  }
}
