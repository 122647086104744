import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/core/core.state';
import { WagerSource } from 'app/core/models/WagerSource';
import { SgEditingStatus, Guid } from 'app/core/models/common-types';
import {
  WagerSourcesLoadWagerSources,
  WagerSourcesSetSelectedWagerSource, SetWagerSourceEditingStatus, WagerSourcesAddWagerSource, WagerSourcesDeleteWagerSource, WagerSourcesUpdateWagerSource
} from '../actions';
import { wagerSourceSelector, selectedWagerSourceSelector, isWagerSourceSelectedSelector, wagerSourceEditingStatusSelector, isLoadedWagerSourceSelector } from '../selectors';

@Injectable()
export class WagerSourceStateService {
  wagerSources$ = this.store.select(wagerSourceSelector);
  selectedWagerSource$ = this.store.select(selectedWagerSourceSelector);
  isWagerSourceSelected$ = this.store.select(isWagerSourceSelectedSelector);
  editingStatus$ = this.store.select(wagerSourceEditingStatusSelector);
  isLoaded$ = this.store.select(isLoadedWagerSourceSelector);

  constructor(
    private store: Store<AppState>
  ) { }


  loadWagerSources() {
    this.store.dispatch(new WagerSourcesLoadWagerSources());
  }
  setSelectedWagerSource(wagerSource: WagerSource) {
    this.store.dispatch(new WagerSourcesSetSelectedWagerSource(wagerSource));
  }
  setWagerSourceEditingStatus(editingStatus: SgEditingStatus) {
    this.store.dispatch(new SetWagerSourceEditingStatus(editingStatus));
  }
  addWagerSource(wagerSource: WagerSource) {
    this.store.dispatch(new WagerSourcesAddWagerSource(wagerSource));
  }

  deleteWagerSource(wagerSourceId: Guid) {
    this.store.dispatch(new WagerSourcesDeleteWagerSource(wagerSourceId));
  }
  updateWagerSource(wagerSource: WagerSource) {
    this.store.dispatch(new WagerSourcesUpdateWagerSource(wagerSource));
  }
}
