import { SET_IS_ADMIN_ACTIVE, SET_IS_EDITING_ENTITY, SET_IS_MOBILE_DEVICE, SET_SELECTED_ADMIN_TAB_INDEX, START_LOADING, STOP_LOADING } from '../action-types';
import { UIActions } from '../actions';
import { INITIAL_UI_STATE, UiState } from '../state/ui.state.interface';

export function uiReducer(state = INITIAL_UI_STATE, action: UIActions): UiState {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case STOP_LOADING:
      return {
        ...state,
        isLoading: false
      };
    case SET_IS_MOBILE_DEVICE:
      return {
        ...state,
        isMobileDevice: action.payload,
      };
    case SET_IS_ADMIN_ACTIVE:
      return {
        ...state,
        isAdminActive: action.payload,
      };
      case SET_SELECTED_ADMIN_TAB_INDEX:
        return {
          ...state,
          selectedAdminTabIndex: action.payload,
        };
      case SET_IS_EDITING_ENTITY:
        return {
          ...state,
          isEditingEntity: action.payload,
        };
    default: {
      return state;
    }
  }
}
