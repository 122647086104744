import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { SportBetStatusType } from 'app/core/models/common-types';


@Pipe({
  name: 'betStatusColor'
})
export class BetStatusColorPipe implements PipeTransform {

  transform(sportBetStatusType: SportBetStatusType): string {
    if (sportBetStatusType === SportBetStatusType.WINNER) {
      return 'green';
    } else if (sportBetStatusType === SportBetStatusType.LOSER) {
      return 'red';
    }
    return 'blue';
  }
}
@NgModule({
    declarations: [BetStatusColorPipe],
    exports: [BetStatusColorPipe],
})
export class BetStatusColorPipeModule {}
