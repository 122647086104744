import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import {
  StoreRouterConnectingModule,
  RouterStateSerializer
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// import {
//   FaIconLibrary,
//   FontAwesomeModule
// } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
// import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
// import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
// import { provideAuth, getAuth } from '@angular/fire/auth';
// import { provideDatabase, getDatabase } from '@angular/fire/database';
// import { provideFirestore, getFirestore } from '@angular/fire/firestore';
// import {
//   AngularFireStorageModule,
//   AngularFireStorageReference,
//   AngularFireUploadTask,
//   StorageBucket
// } from "@angular/fire/storage";

import { environment } from '../../environments/environment';

import {
  AppState,
  reducers,
  metaReducers,
  selectRouterState
} from './core.state';
import {
  ROUTE_ANIMATIONS_ELEMENTS,
  routeAnimations
} from './animations/route.animations';
import { AnimationsService } from './animations/animations.service';
import { AppErrorHandler } from './error-handler/app-error-handler.service';
import { CustomSerializer } from './router/custom-serializer';
import { LocalStorageService } from './local-storage/local-storage.service';
import { HttpErrorInterceptor } from './http-interceptors/http-error.interceptor';
import { NotificationService } from './notifications/notification.service';
import {
  selectSettingsLanguage,
  selectEffectiveTheme,
  selectSettingsStickyHeader
} from './settings/settings.selectors';
import { SG_EFFECTS, SG_STATE_SERVICES, SG_WEB_SERVICES } from './core-deps';
import { UiService } from './services/ui/ui.service';
// import { AuthModule } from 'app/auth/auth.module';
import { EmailService } from './services/email/email.service';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AuthService } from '../auth/service/auth.service';
import { ConfirmationService, MessageService } from 'primeng/api';

export {
  AuthService,
  UiService,
  EmailService,
   routeAnimations,
  AppState,
  LocalStorageService,
  //  selectIsAuthenticated,
  ROUTE_ANIMATIONS_ELEMENTS,
  AnimationsService,
  selectRouterState,
  NotificationService,
  selectEffectiveTheme,
  selectSettingsLanguage,
  selectSettingsStickyHeader
};

@NgModule({
  imports: [
    // angular
    CommonModule,
    HttpClientModule,
    FormsModule,
    // NgbModule,
    // FlexLayoutModule,
    // FlexLayoutModule.withConfig({}, CP_BREAKPOINTS),
    // material
    // MaterialModule,
    // firebase
    // AngularFireModule.initializeApp(environment.firebase),
    // provideFirebaseApp(() => initializeApp(environment.firebase)),
    // provideAuth(() => getAuth()),
    // provideDatabase(() => getDatabase()),
    // provideFirestore(() => getFirestore()),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,

    // ngrx
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot(SG_EFFECTS),
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({
        name: 'AGPicks'
      }),
    environment.useReduxDevTools ? StoreDevtoolsModule.instrument() : [],
   //  AuthModule,
    // 3rd party
    // FontAwesomeModule,
  ],
  declarations: [],
  providers: [
    MessageService,
    ConfirmationService,
    //  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    ...SG_STATE_SERVICES,
    ...SG_WEB_SERVICES
  ],
  exports: [
    // angular
    FormsModule,
    // material
    //  MaterialModule,
    // 3rd party
    //  FontAwesomeModule,
    //  FlexLayoutModule
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule,
    //  faIconLibrary: FaIconLibrary
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
    // faIconLibrary.addIcons(
    //   faCog,
    //   faBars,
    //   faRocket,
    //   faPowerOff,
    //   faUserCircle,
    //   faPlayCircle,
    //   // faGithub,
    //   // faMediumM,
    //   // faTwitter,
    //   // faInstagram,
    //   // faYoutube
    // );
  }
}
