import { isSgAdminUser, SgUserListItem, toSGUserListItem } from 'app/core/models/SgUser';
import {
  SET_SG_USER_UNAUTHENTICATED,
  SET_SG_USER_AUTHENTICATED,
  LOAD_SG_USERS,
  LOAD_SG_USERS_FAIL,
  LOAD_SG_USERS_SUCCESS,
  SEND_RECAPTCHA_TOKEN,
  SEND_RECAPTCHA_TOKEN_FAIL,
  SEND_RECAPTCHA_TOKEN_SUCCESS,
  SET_FIREBASE_USER_AUTHENTICATED,
  SET_FIREBASE_USER_UNAUTHENTICATED,
  LOAD_SG_SETTINGS_SUCCESS,
  UPDATE_SG_SETTINGS_SUCCESS,
  ADD_SG_USER_SUCCESS,
  DELETE_SG_USER_SUCCESS,
  UPDATE_SG_USER_SUCCESS,
  SET_SG_USER_EDITING_STATUS
} from '../action-types';
import {
  AuthActions,
} from '../actions/auth.action';
import { INITIAL_AUTH_STATE, RcAuthState } from '../state/auth.state.interface';

export function authReducer(state: RcAuthState = INITIAL_AUTH_STATE, action: AuthActions): RcAuthState {
  switch (action.type) {

    case SET_FIREBASE_USER_AUTHENTICATED:
      return {
        ...state,
        isFirebaseUserAuthenticated: true,
        firebaseUser: action.payload,
      };
    case SET_SG_USER_UNAUTHENTICATED:
    case SET_FIREBASE_USER_UNAUTHENTICATED:
      return {
        ...INITIAL_AUTH_STATE,
        rcSettings: state.rcSettings
      };
    case SET_SG_USER_AUTHENTICATED:
      //  const sgUsers = [...state.sgUsers];
      // if (!isSgAdminUser(action.payload.userType)) {
      //   sgUsers = state.sgUsers.filter(item => item.clientId === action.payload.clientId);
      // }

      return {
        ...state,
        isSgUserAuthenticated: true,
        loggedInSGUser: action.payload,
      };
    case LOAD_SG_USERS:
    case LOAD_SG_USERS_FAIL:
      return {
        ...state,
        sgUserListItems: [],
      };
    case LOAD_SG_SETTINGS_SUCCESS:
    case UPDATE_SG_SETTINGS_SUCCESS:
      return {
        ...state,
        rcSettings: action.payload
      };
    case SEND_RECAPTCHA_TOKEN:
    case SEND_RECAPTCHA_TOKEN_FAIL: {
      return {
        ...state,
        isRecaptchaTokenAuthenticated: false,
      };
    }
    case SEND_RECAPTCHA_TOKEN_SUCCESS: {
      return {
        ...state,
        isRecaptchaTokenAuthenticated: true,
      };
    }
    case LOAD_SG_USERS_SUCCESS:
      const sgUserListItems: SgUserListItem[] = [];
      action.payload.forEach(user => {
        sgUserListItems.push(toSGUserListItem(user));
      })
      return {
        ...state,
        sgUserListItems: action.payload
      };
    case ADD_SG_USER_SUCCESS:
      console.log('MS ADD_SG_USER_SUCCESS action.payload = ', action.payload);
      return {
        ...state,
        sgUserListItems: [...state.sgUserListItems, toSGUserListItem(action.payload)]
      };
    case UPDATE_SG_USER_SUCCESS: {
      const newSgUsers = [...state.sgUserListItems];

      const newsgUserListItems = [...state.sgUserListItems];
      const idx2 = newSgUsers.findIndex(
        item => item.id === action.payload.id
      );
      newsgUserListItems.splice(idx2, 1, toSGUserListItem(action.payload));
      return {
        ...state,
        sgUserListItems: newsgUserListItems
      };
    }

    case DELETE_SG_USER_SUCCESS: {
      const newSgUsers = [...state.sgUserListItems];
      const newsgUserListItems = [...state.sgUserListItems];
      const idx2 = newSgUsers.findIndex(
        item => item.id === action.payload
      );
      newsgUserListItems.splice(idx2, 1);
      return {
        ...state,
        sgUserListItems: newsgUserListItems
      };
    }

    case SET_SG_USER_EDITING_STATUS:
      return {
        ...state,
        sgUserEditingStatus: action.payload,
      };
    // case EMAIL_NEW_CLIENT_CONTACT_INFO:
    //   return {
    //     ...state
    //   };
    default: {
      return state;
    }
  }
}
