import * as moment from 'moment-mini-ts';
import firebase from 'firebase/compat/app';
import { DatePayload } from 'app/core/models/common-ux-types';
import { Time } from '@angular/common';
import { DISPLAY_DATE_FORMAT, LIST_DATE_TIME_FORMAT, REPORT_DATE_FORMAT } from 'app/core/models/common-types';


export function getDatePayloadFromTimeStamp(timestamp: firebase.firestore.Timestamp, format: string= DISPLAY_DATE_FORMAT, gameTime?: Time): DatePayload {
  let sortDateStr = '';
  let dateStr = '';
  const date: Date = timestamp ? new Date(timestamp.toDate()) : null;
  let gameDateTime = null;
  if (date) {
    if (gameTime) {
      gameDateTime = getGameDateWithTime(date, gameTime);
      dateStr = `${moment(gameDateTime).format(format).toUpperCase()}`;
      sortDateStr = `${moment(gameDateTime).format(REPORT_DATE_FORMAT).toUpperCase()}`
      } else {
        gameDateTime = getGameDate(date);
        dateStr = moment(date).format(format).toUpperCase();
        sortDateStr =moment(date).format(REPORT_DATE_FORMAT).toUpperCase();
      }

   // console.log('MS dateStr = ', dateStr);
    // const split = new Date(date).toString().split(' ');
    // dateStr = split[split.length - 2] + ' ' + split[split.length - 1];
    // console.log('MS dateStr2 = ', dateStr);
    // console.log('MS getGameTimeDate 1.1 est = ', est);

  }
  const datePayload: DatePayload ={ date, gameDateTime, dateStr, gameTime, sortDateStr }
//  console.log('MS getDatePayloadFromTimeStamp datePayload = ', datePayload);
  return datePayload;
}
// function convertTZ(date: Date) {
//   return new Date(date).toLocaleString('en-US', { timeZone: 'America/New_York' });
// }

export function getDatePayloadFromDate(date: Date,  format: string, gameTime?: Time): DatePayload {
  let dateStr = '';
  let sortDateStr = '';
  // console.log('MS getDatePayloadFromDate 1 date = ', date);
  //  const date: Date = timestamp ? new Date(timestamp.toDate()) : null;
  let gameDateTime = null;
  if (date) {
    if (gameTime) {
      gameDateTime = getGameDateWithTime(date, gameTime);
     // console.log('MS getDatePayloadFromDate 1 gameDateTime = ', gameDateTime);
    dateStr = `${moment(gameDateTime).format(format).toUpperCase()}`;
    sortDateStr = `${moment(gameDateTime).format(REPORT_DATE_FORMAT).toUpperCase()}`;
    } else {
      dateStr = moment(getGameDate(date)).format(format).toUpperCase();
      sortDateStr = moment(getGameDate(date)).format(REPORT_DATE_FORMAT).toUpperCase();
    }
  }
  return { date: getGameDate(date), gameDateTime, dateStr, gameTime, sortDateStr };
}
  // Example: 17:30
export function toGameTime(inputTime: string): Time {
  const gameTime = inputTime?.split(':');
  if (gameTime && gameTime.length === 2 ) {
    return {
      hours: parseInt(gameTime[0],10),
      minutes: parseInt(gameTime[1],10),
    }
  }
  return  {
    hours:0,
    minutes:0,
  }
}

  // Example: 17:30
  export function toInputTime(time: Time): string {
    //  itemNumber: String(row['Item #']).padStart(7, '0'), // '0010',
    if (time) {
      const mins = time.minutes < 10 ? `${time.minutes}0`: `${time.minutes}`
      const hours = time.hours < 10 ? `0${time.hours}`: `${time.hours}`
      return `${hours}:${mins}`;
    } else {
      return '13:00'
    }
  }


  // function convertTZ(date, tzString) {
  //   const formatedDate = moment(date).ti(tzString).format()
  //   return formatedDate
  // }


// export function getGameTimeDate(year: number,month: number,day: number,hour: number,minute: number) {
//   const result = new Date(year, month, day, hour, minute, null);
//   console.log('MS getGameTimeDate result = ', result);
//   return result;
// }
export function getGameDate(date: Date) {
  const result = new Date(date.getFullYear(), date.getMonth(), date.getDate(), null, null, null);
  // console.log('MS getGameDate result = ', result);
  return result;
}
export function getGameDateWithTime(date: Date, time: Time) {
  // console.log('MS getGameDateWithTime date = ', date);
  // console.log('MS getGameDateWithTime time = ', time);
  const result = new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.hours, time.minutes, null);
  // console.log('MS getGameDateWithTime result = ', result);
  return result;
}

// function changeTimezone(date: Date) {

//   const date = new Date(Date.UTC(2021, 5, 28, 3, 0, 0));
//   console.log('Date in India: ' + date);
//   const usDate = date.toLocaleString('en-US', {timeZone: 'America/New_York'});
//   console.log('Date in USA: ' + usDate);
// }

export function getGameTimeDate(date: Date, inputTime: string):Date {
 //  console.log('MS getGameTimeDate 1 date = ', date);
  // var newYork    = moment.tz("2014-06-01 12:00", "America/New_York");
  const getTimezoneOffset = date.getTimezoneOffset();
  // console.log('MS getGameTimeDate getTimezoneOffset = ', getTimezoneOffset);

  const getUTCFullYear = date.getFullYear();
  const getUTCMonth = date.getMonth();
  const getUTCDate = date.getDate();

  const time = toGameTime(inputTime);
  const result = new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.hours, time.minutes, null);

  // const utcDate = new Date(date.getFullYear(), date.getMonth() +1, date.getDate(), 13, 15, 0);
  // console.log('MS getGameTimeDate utcDate= ', utcDate);
  // const usDate = utcDate.toLocaleString('en-US', {timeZone: 'America/New_York'});
  // console.log('MS Date in USA: ' + usDate);

  // const dateNoHours = new Date(date).setHours(0, 0, 0, 0) <
  //             new Date().setHours(0, 0, 0, 0)
  //             console.log('MS dateNoHours = ' + dateNoHours);


  // const est =   date.toLocaleString('en-US', { timeZone: 'America/New_York' });
  // console.log('MS getGameTimeDate 1.1 est = ', est);

  // const time = toTime(inputTime);
  // const result = new Date(date.getFullYear(), date.getMonth()+1, date.getDate(), time.hours-3, time.minutes, null);
  // console.log('MS getGameTimeDate 2 result = ', result);
  // const deliveryDate = moment(new Date(date)).format('MM/DD/YYYY');
  // console.log('MS deliveryDate = ' + deliveryDate);
  // const result2 = new Date(date.getFullYear(), date.getMonth()+1, date.getDate(), time.hours, time.minutes-getTimezoneOffset, null);
  // console.log('MS getGameTimeDate 2 result2 = ', result2);
  const deliveryDate2 = moment(new Date(result)).format(LIST_DATE_TIME_FORMAT);
  // console.log('MS deliveryDate = ' + deliveryDate2);
  return result;
}


export function getTimeStrFromNumber(num: number) {
  if (num === 0) {
    return '';
  }
  const totalTimeInSeconds = num * 60;
  const result = new Date(null, null, null, null, null, totalTimeInSeconds);
  return result.toTimeString().split(' ')[0].substring(3);
 }

// Example: 17:30
export function parseDateStr(inputStr: string): Date {
  let theDate = new Date();
  const thisYear = theDate.getFullYear();
  let dateStr = inputStr.replace(/-/g, '/') ; // replace('-','/');



  console.log('MS parseDateStr inputStr= ' + inputStr + ' dateStr= '+ dateStr);
  if (dateStr) {
    const items = dateStr?.split('/');
    if (items) {
      if (items.length === 2) { // 02/09 case
        dateStr = `${dateStr}/${thisYear}`;
        theDate =  new Date(dateStr);
      } else if (items.length === 3) {
        theDate =  new Date(dateStr);
      }
    }
  }
  console.log('MS parseDateStr inputStr= ' + inputStr + ' dateStr= '+ dateStr + ' theDate= '+ theDate);
  return theDate;
}

//   export function getStdTimezoneOffset(date) {
//     const jan = new Date(date.getFullYear(), 0, 1);
//     const jul = new Date(date.getFullYear(), 6, 1);
//   return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
// }

// Date.prototype.isDstObserved = function () {
//   return this.getTimezoneOffset() < this.stdTimezoneOffset();
// }

// var today = new Date();
// if (today.isDstObserved()) {
//   alert ("Daylight saving time!");
// }
