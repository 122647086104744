import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { CoreModule } from './core/core.module';

import { AppRoutingModule } from './app.routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
// import { RecaptchaModule } from 'ng-recaptcha';

@NgModule({
  imports: [
    // angular
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
   //  RecaptchaModule, // this is the module for form incase form validation
    // RecaptchaFormsModule, // this is the module for form incase form validation

    // core
    CoreModule,
    SharedModule,

    // app
    AppRoutingModule,
    // FlexLayoutModule,
  ],
  // providers: [
  //   { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  //   { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
  // ],
  // providers: [{
  //   provide: RouteReuseStrategy,
  //   useClass: CacheRouteReuseStrategy
  // }],
  declarations: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
