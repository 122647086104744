import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/core/core.state';
import { ResetOnUserLogout, SetGamePlanEditingStatus, SetSelectedGamePlanListItem, AddGamePlan, DeleteGamePlan, UpdateGamePlan, LoadGamePlans, ResetOnAdminActive, SetGamePlanSportBetEditingStatus } from '../actions';
import { gamePlanListItemsSelector, selectedGamePlanListItemSelector, isGamePlanListItemSelectedSelector, gamePlansCountSelector, gamePlanEditSelector, gamePlanSportBetEditSelector } from '../selectors';
import { SgEditingStatus, Guid } from 'app/core/models/common-types';
import { GamePlan, GamePlanListItem } from 'app/core/models/GamePlan';

@Injectable()
export class GamePlanStateService {
  gamePlanListItems$ = this.store.select(gamePlanListItemsSelector);
  selectedGamePlanListItem$ = this.store.select(selectedGamePlanListItemSelector);
  isGamePlanListItemSelected$ = this.store.select(isGamePlanListItemSelectedSelector);
  gamePlansCount$ = this.store.select(gamePlansCountSelector);
  gamePlanEditStatus$ = this.store.select(gamePlanEditSelector);
  sportBetEditingStatus$ = this.store.select(gamePlanSportBetEditSelector);

  constructor(
    private store: Store<AppState>,

  ) { }

  resetOnUserLogout() {
    this.store.dispatch(new ResetOnUserLogout());
  }
  resetOnAdminActive() {
    this.store.dispatch(new ResetOnAdminActive());
  }

  setGamePlanEditingStatus(editingStatus: SgEditingStatus) {
   //  this.store.dispatch(new this.store.dispatch(setIsEditingEntity(editingStatus!== SgEditingStatus.NOT_EDITING));

    this.store.dispatch(new SetGamePlanEditingStatus(editingStatus));
  }

  setGamePlanSportBetEditingStatus(editingStatus: SgEditingStatus) {
    this.store.dispatch(new SetGamePlanSportBetEditingStatus(editingStatus));
  }

  setSelectedGamePlan(item: GamePlanListItem) {
    this.store.dispatch(new SetSelectedGamePlanListItem(item));
  }

  addGamePlan(gamePlanListItem: GamePlanListItem) {
    this.store.dispatch(new AddGamePlan(gamePlanListItem));
  }

  deleteGamePlan(id: Guid) {
    this.store.dispatch(new DeleteGamePlan(id));
  }

  updateGamePlan(gamePlanListItem: GamePlanListItem) {
    this.store.dispatch(new UpdateGamePlan(gamePlanListItem));
  }

  loadGamePlans(loadAll: boolean) {
    this.store.dispatch(new LoadGamePlans(loadAll));
  }

  // loadSportBetsForGamePlan(gamePlanId: Guid) {
  //   this.store.dispatch(new LoadSportBetsForGamePlan(gamePlanId));
  // }

}

