import { Action } from '@ngrx/store';
import { SgEditingStatus, Guid } from 'app/core/models/common-types';
import { GamePlan, GamePlanListItem } from 'app/core/models/GamePlan';
import * as fromActionTypes from '../action-types';

export class ResetOnUserLogout implements Action {
  readonly type = fromActionTypes.RESET_ON_USER_LOGOUT;
  constructor() { }
}

export class ResetOnAdminActive implements Action {
  readonly type = fromActionTypes.RESET_ON_ADMIN_ACTIVE;
  constructor() { }
}

export class AddGamePlan implements Action {
  readonly type = fromActionTypes.ADD_NEW_GAME_PLAN;
  constructor(public payload: GamePlanListItem) { }
}

export class AddGamePlanSuccess implements Action {
  readonly type = fromActionTypes.ADD_NEW_GAME_PLAN_SUCCESS;
  constructor(public payload: GamePlanListItem) { }
}
export class AddGamePlanFail implements Action {
  readonly type = fromActionTypes.ADD_NEW_GAME_PLAN_FAIL;
  constructor(public payload: any) { }
}

export class LoadGamePlans implements Action {
  readonly type = fromActionTypes.LOAD_GAME_PLANS;
  constructor(public loadAll: boolean) { }
}
export class LoadGamePlansSuccess implements Action {
  readonly type = fromActionTypes.LOAD_GAME_PLANS_SUCCESS;
  constructor(public payload: GamePlanListItem[]) { }
}
export class LoadGamePlansFail implements Action {
  readonly type = fromActionTypes.LOAD_GAME_PLANS_FAIL;
  constructor(public payload: any) { }
}
export class SetSelectedGamePlanListItem implements Action {
  readonly type = fromActionTypes.SET_SELECTED_GAME_PLAN_LIST_ITEM;
  constructor(public payload: GamePlanListItem) { }
 }

export class DeleteGamePlan implements Action {
  readonly type = fromActionTypes.DELETE_GAME_PLAN;
  constructor(public payload: Guid) { }
}
export class DeleteGamePlanSuccess implements Action {
  readonly type = fromActionTypes.DELETE_GAME_PLAN_SUCCESS;
  constructor(public payload: Guid) { }
}
export class DeleteGamePlanFail implements Action {
  readonly type = fromActionTypes.DELETE_GAME_PLAN_FAIL;
  constructor(public payload: any) { }
}
export class UpdateGamePlan implements Action {
  readonly type = fromActionTypes.UPDATE_GAME_PLAN;
  constructor(public payload: GamePlanListItem) { }
}
export class UpdateGamePlanSuccess implements Action {
  readonly type = fromActionTypes.UPDATE_GAME_PLAN_SUCCESS;
  constructor(public payload: GamePlanListItem) { }
}
export class UpdateGamePlanFail implements Action {
  readonly type = fromActionTypes.UPDATE_GAME_PLAN_FAIL;
  constructor(public payload: any) { }
}

export class SetGamePlanEditingStatus implements Action {
  readonly type = fromActionTypes.SET_GAME_PLAN_EDITING_STATUS;
  constructor(public payload: SgEditingStatus) { }
}
export class SetGamePlanSportBetEditingStatus implements Action {
  readonly type = fromActionTypes.SET_GAME_PLAN_SPORT_BET_EDITING_STATUS;
  constructor(public payload: SgEditingStatus) { }
}

// action types
export type GamePlanActions =
  | SetGamePlanEditingStatus
  | SetGamePlanSportBetEditingStatus
  | AddGamePlan
  | AddGamePlanSuccess
  | AddGamePlanFail
  // | LoadSportBetsForGamePlan
  | LoadGamePlans
  | LoadGamePlansSuccess
  | LoadGamePlansFail
  | SetSelectedGamePlanListItem
  | DeleteGamePlan
  | DeleteGamePlanSuccess
  | DeleteGamePlanFail
  | UpdateGamePlan
  | UpdateGamePlanSuccess
  | UpdateGamePlanFail
  | ResetOnUserLogout
  | ResetOnAdminActive
  ;
