import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { switchMap, catchError, map, withLatestFrom, combineLatestWith } from 'rxjs/operators';
import { SPORT_BET_ADD_BET_SUCCESS, SPORT_BET_DELETE_BET_SUCCESS, SPORT_BET_UPDATE_BET_SUCCESS, SPORT_BET_ADD_BET, SPORT_BET_LOAD_BETS, SPORT_BET_UPDATE_BET, SPORT_BET_DELETE_BET } from '../action-types';
import { SportBetDeleteBetSuccess, SportBetAddBet, SportBetAddBetFail, SportBetAddBetSuccess, SportBetLoadBets, SportBetUpdateBet, SportBetUpdateBetFail, SportBetUpdateBetSuccess, SportBetDeleteBet, SportBetDeleteBetFail, SportBetLoadBetsFail, SportBetLoadBetsSuccess } from '../actions';
import { SportBet, SportBetListItem, toSportBetListItem } from 'app/core/models/SportBet';
import { UiStateService } from '../../ui/state-service';
import { UiService } from 'app/core/services/ui/ui.service';
import { SportBetListService } from 'app/core/services/sport-bets/sport-bets.service';
import { SportBetStateService } from '../state-service/sport-bets.state-service';
import { WagerSourceStateService } from '../../wager-source/state-service';
import { WagerSource } from 'app/core/models/WagerSource';

@Injectable()
export class SportBetEffects {


  sportBetListLoadBets$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<SportBetLoadBets>(SPORT_BET_LOAD_BETS),
    combineLatestWith(
      this.wagerSourceStateService.wagerSources$,
    ),
    // switchMap(() => this.sportBetListService
    switchMap(([_, wagerSources]: [SportBetLoadBets, WagerSource[]]) => this.sportBetListService
      .loadSportBets('gameDateTimestamp', 'desc')
      .pipe(
        map((docArray) => {
         //  console.log('MS SportBetLoadBets wagerSources = ',wagerSources);
          const sportBetListItems: SportBetListItem[] = docArray.map(a => {
            const data = a.payload.doc.data() as SportBet;
            // console.log('MS SportBetLoadBets data = ',data);
            const id = a.payload.doc['id'];
            const wagerSource = wagerSources.find(item => item.id === data.wagerSourceId);
           // console.log('MS SportBetLoadBets wagerSource = ',wagerSource);
            const sportBetListItem: SportBetListItem = {
              ...toSportBetListItem(data, wagerSource),
              id
            }
            return sportBetListItem;
          });

          this.uiStateService.stopLoading();
          return new SportBetLoadBetsSuccess(sportBetListItems)
        }),
        catchError((error) => {
          this.uiStateService.stopLoading();
          if (error.code !== 'permission-denied') {
            this.uiService.showSnackbar(
              'Fetching Bets failed',
              null,
              3000
            );
          }
          return of(new SportBetLoadBetsFail(error))
        })
      ))
  ));

  sportBetListAddBet$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<SportBetAddBet>(
      SPORT_BET_ADD_BET
    ),
    switchMap((action) => this.sportBetListService
      .addSportBet(action.payload)
      .pipe(
        map(() =>
          new SportBetAddBetSuccess(action.payload)
        ),
        catchError((error) =>
          of(new SportBetAddBetFail(error))
        )
      ))
  ));


  sportBetListUpdateBet$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<SportBetUpdateBet>(SPORT_BET_UPDATE_BET),
    switchMap((action) => this.sportBetListService
      .updateSportBet(action.payload)
      .pipe(
        map(() => new SportBetUpdateBetSuccess(action.payload)),
        catchError((error) => of(new SportBetUpdateBetFail(error)))
      ))
  ));

  sportBetListDeleteBet$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<SportBetDeleteBet>(SPORT_BET_DELETE_BET),
    switchMap((action) => this.sportBetListService
      .deleteSportBet(action.payload.id)
      .pipe(
        map(() => new SportBetDeleteBetSuccess(action.payload)),
        catchError((error) => of(new SportBetDeleteBetFail(error)))
      ))
  ));
  //
  // sportBetListDeleteAllBets$: Observable<Action> = createEffect(() => this.actions$.pipe(
  //   ofType<SportBetDeleteAllBets>(SPORT_BET_DELETE_ALL_BETS),
  //   switchMap((action) => this.sportBetListService
  //     .deleteMultipleBets(action.payload)
  //       .pipe(() => {
  //         new SportBetDeleteBetSuccess(defaultBet)
  //       }),
  //       catchError((error) => of(new SportBetDeleteBetFail(error)))
  //     )
  // );
  //   @Effect({ dispatch: false })
  //  sportBetListDeleteAllBets2$ = createEffect(() => this.actions$.pipe(
  //     ofType<SportBetDeleteAllBets>(SPORT_BET_DELETE_ALL_BETS),
  //     map((action) => {
  //       this.sportBetListService.deleteMultipleBets(action.payload);
  //     })
  //   );

  deleteBetSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<SportBetDeleteBetSuccess>(
      SPORT_BET_DELETE_BET_SUCCESS,
      SPORT_BET_UPDATE_BET_SUCCESS,
      SPORT_BET_ADD_BET_SUCCESS),
    map(() => {
      this.sportBetStateService.loadSportBets();
    })
    ),{ dispatch: false });


  constructor(
    private actions$: Actions,
    private uiService: UiService,
    private uiStateService: UiStateService,
    private sportBetStateService: SportBetStateService,
    private sportBetListService: SportBetListService,
    private wagerSourceStateService: WagerSourceStateService
  ) { }
}
