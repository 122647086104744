import { Action } from '@ngrx/store';
import { FirebaseUser } from 'app/auth/models/auth-data.model';
import { SgEmailRequest } from 'app/core/models/SgEmailRequest';
import { SGUser, SgUserListItem } from 'app/core/models/SgUser';
import { RCSettings } from 'app/core/models/Settings';
import * as fromAuthActionTypes from '../action-types';

import { Guid, SgEditingStatus } from 'app/core/models/common-types';
import { Client } from 'app/core/models/Client';

export class SetSgUserEditingStatus implements Action {
  readonly type = fromAuthActionTypes.SET_SG_USER_EDITING_STATUS;
  constructor(public payload: SgEditingStatus) {}
}
export class SetFirebaseUserAuthenticated implements Action {
  readonly type = fromAuthActionTypes.SET_FIREBASE_USER_AUTHENTICATED;
  constructor(public payload: FirebaseUser) { }
}

export class SetFirebaseUserUnAuthenticated implements Action {
  readonly type = fromAuthActionTypes.SET_FIREBASE_USER_UNAUTHENTICATED;
}

export class LoadSgUserForAuth implements Action {
  readonly type = fromAuthActionTypes.LOAD_SG_USER_FOR_AUTH;
  constructor(public payload: FirebaseUser) { }
}
export class LoadSgUserForAuthSuccess implements Action {
  readonly type = fromAuthActionTypes.LOAD_SG_USER_FOR_AUTH_SUCCESS;
  constructor(public payload: SGUser) { }
}
export class LoadSgUserForAuthFail implements Action {
  readonly type = fromAuthActionTypes.LOAD_SG_USER_FOR_AUTH_FAIL;
  constructor(public payload: any) { }
}
export class LoadSgUsers implements Action {
  readonly type = fromAuthActionTypes.LOAD_SG_USERS;
  constructor() { }
}
export class LoadSgUsersSuccess implements Action {
  readonly type = fromAuthActionTypes.LOAD_SG_USERS_SUCCESS;
  constructor(public payload: SgUserListItem[]) { }
}

export class LoadSgUsersFail implements Action {
  readonly type = fromAuthActionTypes.LOAD_SG_USERS_FAIL;
  constructor(public payload: any) { }
}

export class SetSgUserAuthenticated implements Action {
  readonly type = fromAuthActionTypes.SET_SG_USER_AUTHENTICATED;
  constructor(public payload: SgUserListItem) { }
}

export class SetSgUserUnAuthenticated implements Action {
  readonly type = fromAuthActionTypes.SET_SG_USER_UNAUTHENTICATED;
}

export class AddSgUser implements Action {
  readonly type = fromAuthActionTypes.ADD_SG_USER;
  constructor(public payload: SGUser) { }
}
export class AddSgUserSuccess implements Action {
  readonly type = fromAuthActionTypes.ADD_SG_USER_SUCCESS;
  constructor(public payload: SgUserListItem) { }
}
export class AddSgUserFail implements Action {
  readonly type = fromAuthActionTypes.ADD_SG_USER_FAIL;
  constructor(public payload: any) { }
}

export class UpdateSgUser implements Action {
  readonly type = fromAuthActionTypes.UPDATE_SG_USER;
  constructor(public payload: SGUser) { }
}
export class UpdateSgUserSuccess implements Action {
  readonly type = fromAuthActionTypes.UPDATE_SG_USER_SUCCESS;
  constructor(public payload: SGUser) { }
}
export class UpdateSgUserFail implements Action {
  readonly type = fromAuthActionTypes.UPDATE_SG_USER_FAIL;
  constructor(public payload: any) { }
}
export class DeleteSgUser implements Action {
  readonly type = fromAuthActionTypes.DELETE_SG_USER;
  constructor(public payload: Guid) { }
}
export class DeleteSgUserSuccess implements Action {
  readonly type = fromAuthActionTypes.DELETE_SG_USER_SUCCESS;
  constructor(public payload: Guid) { }
}
export class DeleteSgUserFail implements Action {
  readonly type = fromAuthActionTypes.DELETE_SG_USER_FAIL;
  constructor(public payload: any) { }
}
export class EmailNewClientContact implements Action {
  readonly type = fromAuthActionTypes.EMAIL_NEW_CLIENT_CONTACT_INFO;
  constructor(
    public payload: {
      rcEmailRequest: SgEmailRequest;
      newClient: Client;
    }
  ) { }

  // constructor(public payload: SgEmailRequest) {}
}
export class EmailNewClientContactSuccess implements Action {
  readonly type = fromAuthActionTypes.EMAIL_NEW_CLIENT_CONTACT_INFO_SUCCESS;
  constructor(
    public payload: {
      rcEmailRequest: SgEmailRequest;
      newClient: Client;
    }
  ) { }
}
export class EmailNewClientContactFail implements Action {
  readonly type = fromAuthActionTypes.EMAIL_NEW_CLIENT_CONTACT_INFO_FAIL;
  constructor(public payload: any) { }
}

export class EmailCustomerServiceRequest implements Action {
  readonly type = fromAuthActionTypes.EMAIL_CUSTOMER_SERVICE_REQUEST;
  constructor(public payload: SgEmailRequest) { }
}
export class EmailCustomerServiceRequestSuccess implements Action {
  readonly type = fromAuthActionTypes.EMAIL_CUSTOMER_SERVICE_REQUEST_SUCCESS;
  constructor(public payload: any) { }
}
export class EmailCustomerServiceRequestFail implements Action {
  readonly type = fromAuthActionTypes.EMAIL_CUSTOMER_SERVICE_REQUEST_FAIL;
  constructor(public payload: any) { }
}

export class SendEmailToCustomer implements Action {
  readonly type = fromAuthActionTypes.SEND_EMAIL_TO_CUSTOMER;
  constructor(public payload: SgEmailRequest) { }
}
export class SendEmailToCustomerSuccess implements Action {
  readonly type = fromAuthActionTypes.SEND_EMAIL_TO_CUSTOMER_SUCCESS;
  constructor() { }
}
export class SendEmailToCustomerFail implements Action {
  readonly type = fromAuthActionTypes.SEND_EMAIL_TO_CUSTOMER_FAIL;
  constructor(public payload: any) { }
}
export class SendRecaptchaToken implements Action {
  readonly type = fromAuthActionTypes.SEND_RECAPTCHA_TOKEN;
  constructor(public payload: string) { }
}
export class SendRecaptchaTokenSuccess implements Action {
  readonly type = fromAuthActionTypes.SEND_RECAPTCHA_TOKEN_SUCCESS;
  constructor() { }
}
export class SendRecaptchaTokenFail implements Action {
  readonly type = fromAuthActionTypes.SEND_RECAPTCHA_TOKEN_FAIL;
  constructor(public payload: any) { }
}
// RcSettings
export class LoadRcSettings implements Action {
  readonly type = fromAuthActionTypes.LOAD_SG_SETTINGS;
  constructor() { }
}
export class LoadRcSettingsSuccess implements Action {
  readonly type = fromAuthActionTypes.LOAD_SG_SETTINGS_SUCCESS;
  constructor(public payload: RCSettings) { }
}
export class LoadRcSettingsFail implements Action {
  readonly type = fromAuthActionTypes.LOAD_SG_SETTINGS_FAIL;
  constructor(public payload: any) { }
}
export class UpdateRcSettings implements Action {
  readonly type = fromAuthActionTypes.UPDATE_SG_SETTINGS;
  constructor(public payload: RCSettings) { }
}
export class UpdateRcSettingsSuccess implements Action {
  readonly type = fromAuthActionTypes.UPDATE_SG_SETTINGS_SUCCESS;
  constructor(public payload: RCSettings) { }
}
export class UpdateRcSettingsFail implements Action {
  readonly type = fromAuthActionTypes.UPDATE_SG_SETTINGS_FAIL;
  constructor(public payload: any) { }
}

export type AuthActions =
  | SetFirebaseUserAuthenticated
  | SetFirebaseUserUnAuthenticated
  | SetSgUserEditingStatus
  | SetSgUserAuthenticated
  | SetSgUserUnAuthenticated
  | LoadSgUserForAuth
  | LoadSgUserForAuthFail
  | LoadSgUserForAuthSuccess
  | LoadSgUsers
  | LoadSgUsersFail
  | LoadSgUsersSuccess
  | AddSgUser
  | AddSgUserSuccess
  | AddSgUserFail
  | UpdateSgUser
  | UpdateSgUserSuccess
  | UpdateSgUserFail
  | LoadRcSettings
  | LoadRcSettingsSuccess
  | LoadRcSettingsFail
  | UpdateRcSettings
  | UpdateRcSettingsSuccess
  | UpdateRcSettingsFail
  | DeleteSgUser
  | DeleteSgUserSuccess
  | DeleteSgUserFail
  | EmailNewClientContact
  | EmailNewClientContactSuccess
  | EmailNewClientContactFail
  | SendEmailToCustomer
  | SendEmailToCustomerSuccess
  | SendEmailToCustomerFail
  | SendRecaptchaToken
  | SendRecaptchaTokenSuccess
  | SendRecaptchaTokenFail
  | EmailCustomerServiceRequest
  | EmailCustomerServiceRequestSuccess
  | EmailCustomerServiceRequestFail
  ;
