import { SgEditingStatus } from 'app/core/models/common-types';
import { RESET_ON_USER_LOGOUT, SET_GAME_PLAN_EDITING_STATUS, UPDATE_GAME_PLAN_SUCCESS, LOAD_GAME_PLANS, LOAD_GAME_PLANS_FAIL, LOAD_GAME_PLANS_SUCCESS, DELETE_GAME_PLAN_SUCCESS, SET_SELECTED_GAME_PLAN_LIST_ITEM, ADD_NEW_GAME_PLAN_SUCCESS, SET_GAME_PLAN_SPORT_BET_EDITING_STATUS, } from '../action-types';

import { GamePlanActions } from '../actions';
import { INITIAL_GAME_PLAN_STATE, GamePlanState } from '../state/game-plan.state.interface';

export function gamePlanReducer(
  state = INITIAL_GAME_PLAN_STATE,
  action: GamePlanActions
): GamePlanState {
  switch (action.type) {
    case RESET_ON_USER_LOGOUT:
      //  case RESET_ON_ADMIN_ACTIVE:
      return {
        ...INITIAL_GAME_PLAN_STATE
      };
    case SET_GAME_PLAN_EDITING_STATUS:
      return {
        ...state,
        gamePlanEditStatus: action.payload,
        selectedGamePlanListItem: action.payload === SgEditingStatus.NOT_EDITING ? undefined : state.selectedGamePlanListItem,
      };
    case SET_GAME_PLAN_SPORT_BET_EDITING_STATUS:
      return {
        ...state,
        sportBetEditingStatus: action.payload
      };
    case ADD_NEW_GAME_PLAN_SUCCESS:
      return {
        ...state,
        gamePlanListItems: [...state.gamePlanListItems, action.payload],
        selectedGamePlanListItem:  action.payload,
       //  gamePlanEditStatus: SgEditingStatus.EDITING
      };
    case UPDATE_GAME_PLAN_SUCCESS: {
      // console.log('MS action.payload', action.payload);
      const newGamePlans = [...state.gamePlanListItems];
      const idx = newGamePlans.findIndex(
        item => item.id === action.payload.id
      );
      newGamePlans.splice(idx, 1, action.payload);
      return {
        ...state,
        gamePlanListItems: newGamePlans,
        selectedGamePlanListItem: action.payload,
      };
    }
    case LOAD_GAME_PLANS:
    case LOAD_GAME_PLANS_FAIL:
      return {
        ...state,
        gamePlanListItems: [],
        selectedGamePlanListItem: undefined,
      };
    case LOAD_GAME_PLANS_SUCCESS: {
      // const newGamePlans = [...action.payload];
      // newGamePlans.sort((a, b) => (a.scheduledTimestamp > b.scheduledTimestamp) ? 1 : -1);
      return {
        ...state,
        gamePlanListItems: [...action.payload],
        // already sorted from load
        //  gamePlanListItems: newGamePlans.sort((a, b) => b.startBetDateStr?.localeCompare(a.startBetDateStr))
        //   wagerEventNotes: getGamePlanNotes(action.payload)
      };
    }
    case DELETE_GAME_PLAN_SUCCESS: {
      const newGamePlans = [...state.gamePlanListItems];
      const idx = newGamePlans.findIndex(
        item => item.id === action.payload
      );
      newGamePlans.splice(idx, 1);
      return {
        ...state,
        gamePlanListItems: newGamePlans,
        selectedGamePlanListItem: undefined
      };
    }
    case SET_SELECTED_GAME_PLAN_LIST_ITEM:
      return {
        ...state,
        selectedGamePlanListItem: action.payload,
      };

    default: {
      return state;
    }
  }
}
