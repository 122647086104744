import { WagerSource } from 'app/core/models/WagerSource';
import { SgEditingStatus } from 'app/core/models/common-types';

export interface WagerSourcesState {
  editingStatus: SgEditingStatus;
  isLoaded: boolean;
  selectedWagerSource: WagerSource;
  wagerSources: WagerSource[];
}

export const INITIAL_WAGER_SOURCE_LIST_STATE: WagerSourcesState = {
  editingStatus: SgEditingStatus.NOT_EDITING,
  isLoaded: false,
  selectedWagerSource: undefined,
  wagerSources: [],
};
