
const packageJson = require('../../package.json');

export const environment = {
  appName: 'agpicks',
  // apiUrl: 'http://service-gambit-env.eba-jtqyf9ba.us-west-2.elasticbeanstalk.com/api/',
  apiUrl: 'https://us-central1-agsportsbet.cloudfunctions.net/',
  // https://us-central1-service-gambit.cloudfunctions.net/sendMail

  envName: 'PROD',
  production: true,
  // almlypm: false,   // FOR CM
  // almlycm: true, // FOR CM
  almlypm: true, // FOR PM
  almlycm: true, // FOR PM
  test: false,
  i18nPrefix: 'app',
  versions: {
    app: packageJson.version,
    // angular: packageJson.dependencies['@angular/core'],
    // ngrx: packageJson.dependencies['@ngrx/store'],
    // material: packageJson.dependencies['@angular/material'],
    // bootstrap: packageJson.dependencies.bootstrap,
    // rxjs: packageJson.dependencies.rxjs,
    // fontAwesome:
    //   packageJson.dependencies['@fortawesome/fontawesome-free-webfonts'],
    // angularCli: packageJson.devDependencies['@angular/cli'],
    // typescript: packageJson.devDependencies['typescript'],
    // cypress: packageJson.devDependencies['cypress'],
    // eslint: packageJson.devDependencies['eslint']
  },
  // Production
  firebase: {
    apiKey: 'AIzaSyB956PYKipqvNGpuNU4vPjvlcPAIhrMmzw',
    authDomain: 'agpicks.firebaseapp.com',
    projectId: 'agsportsbet',
    storageBucket: 'agpicks.appspot.com',
    messagingSenderId: '49319506661',
    appId: '1:49319506661:web:68c9d48e1e010d57055a0c',
    // measurementId: 'G-62HTM8VPXS'

    // apiKey: 'AIzaSyCoMFYmJ8BlC0a-2VONmkkQWCX2uqcs2o8',
    // authDomain: 'service-gambit.firebaseapp.com',
    // projectId: 'service-gambit',
    // storageBucket: 'service-gambit.appspot.com',
    // messagingSenderId: '806689445699',
    // appId: '1:806689445699:web:4070dc1730bd5870b681f6'
  },
  // Dev
  // firebase: {
  //   apiKey: 'AIzaSyDxrq_Krr0V4_IOtQSKlG1H5WFclDxrJOA',
  //   authDomain: 'service-gambit-dev.firebaseapp.com',
  //   projectId: 'service-gambit-dev',
  //   storageBucket: 'service-gambit-dev.appspot.com',
  //   messagingSenderId: '575272106677',
  //   appId: '1:575272106677:web:01521ba32002c2a0001cfc'
  // },

  useReduxDevTools: false
};
