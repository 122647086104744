import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { GamePlanStatusType } from 'app/core/models/common-types';


@Pipe({
  name: 'gamePlanStatusColor'
})
export class GamePlanStatusColorPipe implements PipeTransform {

  transform(gamePlanStatusType: GamePlanStatusType): string {
    if (gamePlanStatusType === GamePlanStatusType.COMPLETED) {
      return 'green';
    }
    // else if (gamePlanStatusType === GamePlanStatusType.LOSER) {
    //   return 'red';
    // }
    return 'white';
  }
}
@NgModule({
    declarations: [GamePlanStatusColorPipe],
    exports: [GamePlanStatusColorPipe],
})
export class GamePlanStatusColorPipeModule {}
