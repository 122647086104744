import { createSelector } from '@ngrx/store';
import { AppState } from 'app/core/core.state';
import { SgEditingStatus } from 'app/core/models/common-types';
import { GamePlanState } from '../state/game-plan.state.interface';

export const gamePlanStateSelector = (state: AppState): GamePlanState =>
  state.gamePlanState;

export const selectedGamePlanListItemSelector = createSelector(
  gamePlanStateSelector,
  (state: GamePlanState) => state.selectedGamePlanListItem ? state.selectedGamePlanListItem : undefined
);
// export const reportGamePlanListItemSelector = createSelector(
//   gamePlanStateSelector,
//   (state: GamePlanState) => state.reportGamePlanListItem ? state.reportGamePlanListItem : undefined
// );
export const gamePlanEditSelector = createSelector(
  gamePlanStateSelector,
  (state: GamePlanState) => state.gamePlanEditStatus ? state.gamePlanEditStatus : SgEditingStatus.NOT_EDITING
);
export const gamePlanSportBetEditSelector = createSelector(
  gamePlanStateSelector,
  (state: GamePlanState) => state.sportBetEditingStatus ? state.sportBetEditingStatus : SgEditingStatus.NOT_EDITING
);
export const isGamePlanListItemSelectedSelector = createSelector(
  gamePlanStateSelector,
  (state: GamePlanState) =>
    //  return true;
    state.selectedGamePlanListItem ? true : false

);
export const gamePlanListItemsSelector = createSelector(
  gamePlanStateSelector,
  (state: GamePlanState) => state.gamePlanListItems ? state.gamePlanListItems : []
);
export const gamePlansCountSelector = createSelector(
  gamePlanStateSelector,
  (state: GamePlanState) => state.gamePlanListItems ? state.gamePlanListItems.length : 0
);
// export const servicePointPackagesSelector = createSelector(
//   gamePlanStateSelector,
//   (state: GamePlanState) => state.servicePointPackages ? state.servicePointPackages : []
// );

// export const touchPointProjectListItemsSelector = createSelector(
//   gamePlanStateSelector,
//   (state: GamePlanState) => state.touchPointProjectListItems ? state.touchPointProjectListItems : []
// );

// export const wagerEventNotesSelector = createSelector(
//   gamePlanStateSelector,
//   (state: GamePlanState) => state.wagerEventNotes ? state.wagerEventNotes : []
// );

