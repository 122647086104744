import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { switchMap, catchError, map, tap } from 'rxjs/operators';
import { CLIENT_LIST_ADD_CLIENT_SUCCESS, CLIENT_LIST_DELETE_CLIENT_SUCCESS, CLIENT_LIST_UPDATE_CLIENT_SUCCESS, CLIENT_LIST_ADD_CLIENT, CLIENT_LIST_LOAD_CLIENTS, CLIENT_LIST_UPDATE_CLIENT, CLIENT_LIST_DELETE_CLIENT, CLIENT_LIST_ADD_MULTIPLE_CLIENTS_SUCCESS, CLIENT_LIST_ADD_MULTIPLE_CLIENTS, SET_CLIENT_EDITING_STATUS } from '../action-types';
import { ClientListDeleteClientSuccess, ClientListAddClient, ClientListAddClientFail, ClientListAddClientSuccess, ClientListLoadClients, ClientListUpdateClient, ClientListUpdateClientFail, ClientListUpdateClientSuccess, ClientListDeleteClient, ClientListDeleteClientFail, ClientListLoadClientsSuccess, ClientListLoadClientsFail, ClientListAddMultipleClients, ClientListAddMultipleClientsSuccess, ClientListAddMultipleClientsFail, SetClientEditingStatus } from '../actions';
import { ClientService } from 'app/core/services/clients/clients.service';
import { ClientStateService } from '../state-service';
import { Router } from '@angular/router';
import { Client } from 'app/core/models/Client';
import { UiStateService } from '../../ui/state-service';
import { UiService } from 'app/core/services/ui/ui.service';
import { SgEditingStatus } from 'app/core/models/common-types';

@Injectable()
export class ClientListEffects {

  // @Effect({ dispatch: false })
  // clientListLoadClients2$ = createEffect(() => this.actions$.pipe(
  //   ofType<ClientListLoadClients>(CLIENT_LIST_LOAD_CLIENTS),
  //   map(() => {
  //     this.clientsService.loadClients('name', 'asc');
  //     })
  // );


  clientListLoadClients$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ClientListLoadClients>(CLIENT_LIST_LOAD_CLIENTS),
    switchMap(() => this.clientsService
      .loadClients('last', 'asc')
      .pipe(
        map((docArray) => {
          const clients: Client[] = docArray.map(a => {
            const data = a.payload.doc.data() as Client;
            const id = a.payload.doc['id'];
            return {
              ...data,
              id,
              //  reportHeaderColor: data.reportHeaderColor ?? DEFAULT_REPORT_HEADER_COLOR
            };
          });
          this.uiStateService.stopLoading();
          return new ClientListLoadClientsSuccess(clients)
        }
        ),
        catchError((error) => {
          this.uiStateService.stopLoading();
          if (error.code !== 'permission-denied') {
            this.uiService.showSnackbar(
              'Fetching Clients failed',
              null,
              3000
            );
          }
          return of(new ClientListLoadClientsFail(error))
        })
      ))
  ));

  //
  // clientListLoadClientForUser$: Observable<Action> = createEffect(() => this.actions$.pipe(
  //   ofType<LoadClientForUser>(
  //     LOAD_CLIENT_FOR_USER
  //   ),
  //   switchMap((action) => this.clientsService
  //     .loadClient(action.payload)
  //     .pipe(
  //       map((client: Client) => {
  //         console.log('MS LOAD_CLIENT_FOR_USER client= ', client);
  //         this.uiStateService.stopLoading();
  //         return new LoadClientForUserSuccess(client)
  //       }),
  //       catchError((error) =>{
  //         this.uiStateService.stopLoading();
  //         this.uiService.showSnackbar(
  //           'Fetching Clients failed',
  //           null,
  //           3000
  //         );
  //         return of(new ClientListLoadClientsFail(error))
  //       })
  //     ))
  // );

  //
  // loadClientForUser$: Observable<Action> = createEffect(() => this.actions$.pipe(
  //   ofType<LoadClientForUser>(
  //     LOAD_CLIENT_FOR_USER
  //   ),
  //   withLatestFrom(this.authStateService.loggedInSGUser$,
  //    //  this.authStateService.isClientUser$
  //     ),
  //     switchMap(([_, sgUser]: [
  //           LoadClientForUser,
  //           SGUser,
  //         ]) => this.clientsService
  //     .loadClient(sgUser.clientId)
  //     .pipe(
  //       map((client: Client) => {
  //         console.log('MS LOAD_CLIENT_FOR_USER client = ', client);
  //         this.uiStateService.stopLoading();
  //         return new LoadClientForUserSuccess(
  //           {
  //             ...client,
  //             id: sgUser.clientId,
  //             reportHeaderColor: client.reportHeaderColor ?? DEFAULT_REPORT_HEADER_COLOR
  //           })
  //       }),
  //       catchError((error) =>{
  //         this.uiStateService.stopLoading();
  //         if (error.code !== 'permission-denied') {
  //         this.uiService.showSnackbar(
  //           'Fetching Clients failed',
  //           null,
  //           3000
  //         );
  //         }
  //         return of(new ClientListLoadClientsFail(error))
  //       })
  //     ))
  // );

  // @Effect({ dispatch: false })
  // loadClientForUserSuccess$ = createEffect(() => this.actions$.pipe(
  //   ofType<LoadClientForUserSuccess>(
  //     LOAD_CLIENT_FOR_USER_SUCCESS,
  //     CLIENT_LIST_SET_SELECTED_CLIENT
  //   ),
  //   withLatestFrom(this.authStateService.isSgUserAuthenticated$,
  //     ),
  //   map(([action, isSgUserAuthenticated] : [LoadClientForUserSuccess, boolean]) => {
  //     console.log('MS HERE isSgUserAuthenticated = ', isSgUserAuthenticated);
  //     if (isSgUserAuthenticated) {
  //       this.propertyStateService.loadProperties();
  //       const fetchPayloadData: FetchPayloadData = {
  //         entityId: action.payload.id,
  //         documentField: 'clientId'
  //       }
  //       this.customerStateService.loadCustomers(fetchPayloadData);
  //       this.customerStateService.loadTenants(fetchPayloadData);
  //     }
  //     // this.serviceEventStateService.loadServicePointPackages();
  //     // this.router.navigate(['/admin']);
  //   })
  // );


  //   map(([action, sgUser, isClientUser]: [
  //     LoadClientForUser,
  //     SGUser,
  //     boolean
  //   ]) => {
  //     if (isClientUser) {
  //       const selectedClient = findClientForUser(action.payload, sgUser);
  //       if (!isNil(selectedClient)) {
  //         this.clientStateService.setSelectedClient(selectedClient);
  //       }
  //     }
  //   })
  // );

  clientListAddClient$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ClientListAddClient>(
      CLIENT_LIST_ADD_CLIENT
    ),
    switchMap((action) => this.clientsService
      .addClient(action.payload)
      .pipe(
        map(() =>
          new ClientListAddClientSuccess(action.payload)
        ),
        catchError((error) =>
          of(new ClientListAddClientFail(error))
        )
      ))
  ));

  clientListAddMultipleClients$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ClientListAddMultipleClients>(
      CLIENT_LIST_ADD_MULTIPLE_CLIENTS
    ),
    switchMap((action) => this.clientsService
      .addMultipleClients(action.payload)
      .pipe(
        map(() =>
          new ClientListAddMultipleClientsSuccess(action.payload)
        ),
        catchError((error) =>
          of(new ClientListAddMultipleClientsFail(error))
        )
      ))
  ));

  clientListUpdateClient$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ClientListUpdateClient>(CLIENT_LIST_UPDATE_CLIENT),
    switchMap((action) => this.clientsService
      .updateClient(action.payload)
      .pipe(
        map(() => new ClientListUpdateClientSuccess(action.payload)),
        catchError((error) => of(new ClientListUpdateClientFail(error)))
      ))
  ));

  clientListDeleteClient$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ClientListDeleteClient>(CLIENT_LIST_DELETE_CLIENT),
    switchMap((action) => this.clientsService
      .deleteClient(action.payload)
      .pipe(
        map(() => new ClientListDeleteClientSuccess()),
        catchError((error) => of(new ClientListDeleteClientFail(error)))
      ))
  ));

  deleteClientSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<ClientListDeleteClientSuccess>(
      CLIENT_LIST_DELETE_CLIENT_SUCCESS,
      CLIENT_LIST_UPDATE_CLIENT_SUCCESS,
      CLIENT_LIST_ADD_MULTIPLE_CLIENTS_SUCCESS,
      CLIENT_LIST_ADD_CLIENT_SUCCESS),
    map(() => {
      this.clientStateService.loadClients();
    })
    ),{ dispatch: false });

  navigateOnFinish$ = createEffect(() => this.actions$.pipe(
    ofType(CLIENT_LIST_ADD_CLIENT_SUCCESS,
      CLIENT_LIST_UPDATE_CLIENT_SUCCESS),
    map(() => {
      // console.log('MS navigateOnFinish SG_ADMIN_ADD_CUSTOMER_SUCCESS!');
      // this.serviceEventStateService.loadServicePointPackages();
      this.router.navigate(['/admin/client-leads-list']);
    })
    ),{ dispatch: false });

  setClientEditingStatus$ = createEffect(() => this.actions$.pipe(
    ofType<SetClientEditingStatus>(
      SET_CLIENT_EDITING_STATUS),
    tap((action) => {
      console.log('MS setWorkOrderEditingStatus action.payload= ', action.payload);
      this.uiStateService.setIsEditingEntity(action.payload !== SgEditingStatus.NOT_EDITING);
    })
    ),{ dispatch: false });


  constructor(
    private actions$: Actions,
    private router: Router,
    private uiService: UiService,
    private clientStateService: ClientStateService,
    private clientsService: ClientService,
    private uiStateService: UiStateService) { }

}
